import React, { PureComponent } from "react";
import I18n, { localize, getLanguages } from 'locale/I18n'
import Svg from 'components/common/Svg';

const languages = getLanguages();

class LanguagesPopup extends PureComponent {
    
    /// Returns a click handler to change language.
    /// @param tunnel The language code.
    /// @return An event handler function.
    createOnClick = code => evt => {
        evt.preventDefault();
        this.props.setLocale(code);
        this.props.closePopup();
    }

    render() {
        return <>
            <h5><I18n $="header.select_language" iFlyUpperCase /></h5>
            <ul className="body" role="menu" aria-expanded={false}>
                {
                    languages.map(lang =>
                        <li key={lang.code} className="opt" role="menuitem">
                            <a href="#top" onClick={this.createOnClick(lang.code)}>
                                <i className={`flag ${lang.code.toLowerCase()}`}></i>
                                <span>{lang.name}</span>
                                {lang.code === this.props.locale &&
                                <i className="check"><Svg id="ic-checkmark"/></i>
                                }
                            </a>
                        </li>
                    )
                }
            </ul>
        </>
    }
}

export default localize(LanguagesPopup);