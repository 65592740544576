import React from 'react'
import { Modal as CommonModal } from 'components/common/Modal';
import Svg from 'components/common/Svg';
import I18n from 'locale/I18n'

function noop(){};

/// Wrapper receives the closeModal function from the Modal component.
function PromptBody(props) {
    return (
        <div className="media-alert">
            {
                !props.hideCloseButton &&
                <button className="close" onClick={props.closeModal}>
                    <Svg id="ic-close"/>
                </button>
            }
            <div className="body">{ props.children }</div>
            {
                props.actions &&
                <div className="actions">{ props.actions }</div>
            }
        </div>
    )
}

/// Prompt
///
/// Displays the `props.children` in a modal message along with any action
/// buttons.
///
/// Pass a React element or React.Fragment as `props.actions`. If the action
/// or any immediate React.Fragment child is an instance of a CloseButton the 
/// CloseButton calls `props.onClick` when the modal is dismissed. 
///
///      <Prompt onClose={this.onCloseEmptyCartModal} actions={<>
///           <button className="btn-secondary" onClick={this.onClickOk}>
///               <I18n $="button.ok" iFlyUpperCase/>           
///           </button>           
///           <CloseButton className="btn-secondary">
///               <I18n $="button.cancel" iFlyUpperCase/>
///           </CloseButton>
///      </>}>
///          <I18n dangerousHTML $="prompt.discard_changes" />
///      </Prompt>
///
const Prompt = React.memo(function Prompt({ onClose, children, ...props }) {
    if (!onClose) onClose = noop;
    return (
        <CommonModal onClose={onClose}>
            <PromptBody {...props}>{ children }</PromptBody>
        </CommonModal>
    )
});

/// Alert
///
/// Displays the `props.children` in a modal message with an OK button. Calls
/// `props.onClose` when the modal is dismissed. Example:
///
///      <Alert onClose={this.onCloseEmptyCartModal}>
///          <I18n dangerousHTML $="media.alert.empty_cart" />
///      </Alert>
///
const Alert = React.memo(function Alert({ onClose, children }) {
    if (!onClose) onClose = noop;

    const ok =
        <button className="btn-secondary" onClick={onClose}>
            <I18n $="button.ok" iFlyUpperCase />
        </button>

    return <Prompt onClose={onClose} actions={ok}>{children}</Prompt>
});

/// Modal
///
/// Displays the `props.children` in a modal that cannot be dismissed. Example:
///
///      <Modal>
///          <I18n dangerousHTML $="media.prompt.checking_wristband" />
///      </Modal>
///
const Modal = React.memo(function Modal({ children }) {
    return (
        <div className="accpl-modal">
            <div className="overlay"></div>
            <div className="content">
                <PromptBody hideCloseButton>{ children }</PromptBody>
            </div>
        </div>
    )
});

export default Alert;
export { Prompt, Modal };