import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { createModule } from '@ifly/redux-modules'
import cartModule from './cart'
import sessionModule from './session'
import MediaApi from 'api/MediaApi'

const Module = createModule({
    name: 'order',
    
    initialState: {
        order: null,
        isLoading: false
    },
    
    actions: {
        updateOrder: { reducer:false, dispatch:true }, // reduced by saga
        loadingOrder: {},
        receiveOrder: {},
        failureOrder: {}
    },

    selector: {
        order: state => state.order.order
    }
});

export default Module;

export class OrderSaga {

    static* watcher() {
        yield all([
            takeLatest(Module.constants.updateOrder, OrderSaga.update)
        ]);
    }
    
    /**
     * Updates the items in the order.
     * @param {Object} action The action being dispatched
     */
    static * update(action) {
        let order = null;
        let isLoading = true;
        yield put(Module.actions.loadingOrder({ order, isLoading }));

        let cart = yield select(cartModule.selector.default),
            session = yield select(sessionModule.selector.default),
            tunnel = session?.tunnel?.code,
            cartItems = cart.cartItems || [];


        isLoading = false;

        if (cartItems.length) {
            let { sessionId } = cartItems[0],
                mediaSessionId = sessionId,
                itemIds = [],
                items = [];

            cartItems.forEach(item => {

                itemIds.push(item.id);
                items.push(item);
                
            });

            let orderData = { itemIds, mediaSessionId, items, tunnel },    
                response = yield call(MediaApi.post, '/api/Order/Create', orderData);

            if (response) {
                if (response.data.status === 'ERR') {

                    yield put(Module.actions.failureOrder({ order, isLoading }));
                }
                order = response.data;
            } else {
                // server error

                yield put(Module.actions.failureOrder({ order, isLoading }));
            }
        }
        
        yield put(Module.actions.receiveOrder({ order, isLoading }));
    }
}