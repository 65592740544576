import React, { Component } from 'react'

import I18n, { localize } from 'locale/I18n'
import Svg from 'components/common/Svg';
import WordpressApi from 'api/WordpressApi'
import WpLink from 'components/link/WpLink'
import 'styles/core.scss'

const i18n_site_map = <I18n $="footer.site_map"/>
const i18n_copyright = <I18n $="common.copyright"/>
const i18n_safety_standards = <I18n $="footer.safety_standards"/>

class FooterComponent extends Component {

    state = {
        isLoading: false,
        menu: null
    }

    loadMenu = () => {
        let { isLoading } = this.state;

        // load footer menu
        if (!isLoading) {
            this.setState({ isLoading: true });

            WordpressApi.getFooterMenu()
                .then(response => {
                    this.setState({
                        isLoading: false,
                        menu: WordpressApi.buildTopAndSubMenus(response.data)
                    });
                })
                .catch(error => {
                    this.setState({ isLoading: false });
                    console.log('Error fetching accpl_footer menu: ', error);
                });
        }
    }

    componentDidUpdate(prevProps) {
        let { locale } = this.props;
        if (locale !== prevProps.locale) {
            this.loadMenu();
        }
    }

    /**
     * When the footer component first starts up load its WordPress menu.
     */
    componentDidMount() {
        if (!this.state.menu) {
            this.loadMenu();
        }
    }

    render() {
        let { top, sub } = this.state.menu || {};
        
        return (
            <footer>
                <div className="max-ct">
                    <WpLink path="/" className="logo"><Svg id="ifly-logo"/></WpLink>
                    
                    <nav>
                    {
                        // Iterate the WordPress top-level menus and their sub-menus.
                        top?.map(item =>
                            <div key={item.ID}>
                                <ul>
                                    <li className="grp"><h6>{ item.title }</h6></li>
                                    {
                                        sub[item.ID] && sub[item.ID].map(sub =>
                                            <li key={sub.ID} className="opt">
                                                <a key={sub.ID} 
                                                   href={sub.url} 
                                                   dangerouslySetInnerHTML={{__html:sub.title}} />
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                        )
                    }
                    </nav>

                    <ul className="social">
                        <li>
                            <a href="https://www.twitter.com/iFLYUS" target="_blank" rel="noopener noreferrer">
                                <i className="icon twitter"><Svg id="twitter"/></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/iFLYUS" target="_blank" rel="noopener noreferrer">
                                <i className="icon facebook"><Svg id="facebook"/></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/iflyus/" target="_blank" rel="noopener noreferrer">
                                <i className="icon instagram"><Svg id="instagram"/></i>
                            </a>
                        </li>
                        <li>
                            <a href="http://www.youtube.com/user/iflytunnelvision?feature=results_main" target="_blank" rel="noopener noreferrer">
                                <i className="icon youtube"><Svg id="youtube"/></i>
                            </a>
                        </li>
                    </ul>
                </div>

                <div className="base-bar">
                    <div className="standards">
                        { i18n_safety_standards }{' '}
                        <a href="https://tunnelflight.com/" target="_blank" rel="noopener noreferrer">IBA</a>{'.'}
                    </div>
                    <div className="copyright">
                        { i18n_copyright }
                        &nbsp;&nbsp; | &nbsp;&nbsp;
                        <WpLink path="/site-map" label={i18n_site_map} />
                    </div>
                </div>
            </footer>
        )
    }
}

export default localize(FooterComponent);