import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import MediaPropTypes from 'util/MediaPropTypes'
import memoize from 'memoize-one'
import I18n, { localize, i18n } from 'locale/I18n'
import { shoppingCart } from 'cart'
import Svg from 'components/common/Svg';
import MediaUtil from 'util/MediaUtil'
import MediaGrid from 'components/grid/MediaGrid'

const BULK = 'bulk-cart-action';

const CART_FULL =
    <div className="thumb limit-reached" key={BULK}>
        <span className="cart-full">
            <I18n $="media.cart_full" />
        </span>
        <span className="no-more">
            <I18n $="media.cannot_add_more_items" />
        </span>
    </div>

class ShoppingMediaGrid extends PureComponent {
    
    selectAllVideos = evt => {
        evt.stopPropagation();
        this.props.addToCart(MediaUtil.filterVideos(this.props.media));
    }
    selectAllPhotos = evt => {
        evt.stopPropagation();
        this.props.addToCart(MediaUtil.filterPhotos(this.props.media));
    }

    deselectAllVideos = () => this.props.removeFromCart(MediaUtil.filterVideos(this.props.media))
    deselectAllPhotos = () => this.props.removeFromCart(MediaUtil.filterPhotos(this.props.media))

    getSelectedIds = memoize(
        media => media.reduce((sel, item) => { sel.push(item.id); return sel; }, [])
    )

    isMaxSelected = memoize(
        (media, selectedIds) => {
            let selectedItems = media.filter(item => selectedIds.includes(item.id));

            // return true if all media are selected or if at least one is
            // selected and the cart is at the maximum number of items.
            return selectedItems.length===media.length ||
                (selectedItems.length && selectedIds.length>=this.props.cartLimit);
        }
    )
    
    findItem = evt => {
        evt.stopPropagation();
        let id = +evt.target.closest('button').getAttribute('data-id');
        return this.props.media.find(item => item.id === id);
    }
    
    onClickAdd = evt => this.props.addToCart(this.findItem(evt))

    onClickRemove = evt => this.props.removeFromCart(this.findItem(evt))

    getVideoSelection = memoize( 
        (videos, selectedIds, cartFull) => {
            let maxVideosSelected = this.isMaxSelected(videos, selectedIds),
                selectAllMedia;
            
            if (maxVideosSelected) selectAllMedia =
                <button className="thumb select-all" onClick={this.deselectAllVideos} key={BULK}>
                    <div><I18n $="button.deselect_all_videos"/></div>
                </button>
            else if (!cartFull) selectAllMedia =
                <button className="thumb select-all" onClick={this.selectAllVideos} key={BULK}>
                    <div><I18n $="button.select_all_videos"/></div>
                </button>
    
            return [].concat(
                selectAllMedia || CART_FULL,
                videos.map(item => ({ ...item, selected:selectedIds.includes(item.id) }))
            );
        }
    )

    getPhotoSelection = memoize( 
        (photos, selectedIds, cartFull) => {
            let maxPhotosSelected = this.isMaxSelected(photos, selectedIds),
                selectAllMedia;
            
            if (maxPhotosSelected) selectAllMedia =
                <button className="thumb select-all" onClick={this.deselectAllPhotos} key={BULK}>
                    <div><I18n $="button.deselect_all_photos" /></div>
                </button>
            else if (!cartFull) selectAllMedia =
                <button className="thumb select-all" onClick={this.selectAllPhotos} key={BULK}>
                    <div><I18n $="button.select_all_photos" /></div>
                </button>
            
            return [].concat(
                selectAllMedia || CART_FULL,
                photos.map(item => ({ ...item, selected:selectedIds.includes(item.id) }))
            );
        }
    )

    renderMediaDetailAction = item => {
        let { cartItems, cartLimit, isLoading } = this.props,
            selectedIds = this.getSelectedIds(cartItems),
            cartFull = selectedIds.length >= cartLimit,
            action;


        if (!isLoading) {
            if (selectedIds && selectedIds.includes(item.id)) action =
                <div className="added">
                    <div className="confirmation">
                        <i className="icon"><Svg id="ic-checkmark" title={i18n('media.selected')} /></i>
                        <I18n $="button.added_to_cart" />
                    </div>
                    <button className="prompt" onClick={this.onClickRemove} data-id={item.id}>
                        <I18n $="button.remove_from_cart" />
                    </button>
                </div>

            else if (cartFull) action =
                <div className="limit-reached">
                    <I18n $="media.cart_full" />
                    <br />
                    <I18n $="media.cannot_add_more_items" />
                </div>

            else action =
                <button onClick={this.onClickAdd} className="cart btn-primary" data-id={item.id}>
                    <I18n $="button.add_to_cart" />
                </button>
        } else {
            action = null;
        }
            
        return action;
    }


    
    render() {

        let { media, showPrice, isLoading } = this.props,
            videos = MediaUtil.filterVideos(media),
            photos = MediaUtil.filterPhotos(media);

        if (!this.props.checkoutMode) {
            let selectedIds = this.getSelectedIds(this.props.cartItems),
                cartFull = selectedIds.length >= this.props.cartLimit;
            videos = this.getVideoSelection(videos, selectedIds, cartFull);
            photos = this.getPhotoSelection(photos, selectedIds, cartFull);
        }
        
        return <MediaGrid videos={videos} photos={photos} showPrice={showPrice}
                          mediaDetailActions={this.renderMediaDetailAction} onRemove={this.onClickRemove} checkoutMode={this.props.checkoutMode} isLoading={isLoading}/>
    }
    
    static defaultProps = {
        showPrice: false
    }
    
    static propTypes = {
        // passed props
        media: MediaPropTypes.media,
        showPrice: PropTypes.bool,
        
        // shoppingCart
        addToCart: PropTypes.func.isRequired,
        removeFromCart: PropTypes.func.isRequired,
        cartItems: MediaPropTypes.media,
        isLoading: PropTypes.bool,
    }
}

export default localize(shoppingCart(ShoppingMediaGrid));