import React from 'react'
import { localize } from 'locale/I18n'
import Svg from 'components/common/Svg';

const LanguagesToggle = React.memo(function LanguagesToggle(props) {
    return <>
        <i className={`rflag ${props.locale.toLowerCase()}`}></i>
        <i className="arrow if-shut">
            <Svg id="ic-arrow-down-small"/>
        </i>
        <i className="arrow if-open">
            <Svg id="ic-arrow-up-small"/>
        </i>
    </>
});

export default localize(LanguagesToggle);