import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import MediaPropTypes from 'util/MediaPropTypes'
import { Link, navigate } from '@reach/router'
import I18n from 'locale/I18n'
import Svg from 'components/common/Svg';
import { shoppingCart } from 'cart'
import { Prompt } from 'components/misc/Prompt'
import Path from 'util/PathUtil'

class KioskNavigation extends PureComponent {
    
    state = {
        showCartWarningModal: false
    }
    
    onClickBack = evt => {
        window.history.back();
    }

    onStartOver = evt => {
        let { cartItems } = this.props;
        if (cartItems?.length) {
            evt.preventDefault();
            evt.stopPropagation();
            this.setState({ showCartWarningModal:true });
            
        } else {
            this.props.clearCart();
        }
    }
    
    closeCartWarning = () => this.setState({ showCartWarningModal:false })
    
    doStartOver = () => {
        navigate('/');
        this.props.clearCart();
    }
    
    renderVouchers = () => {
        let { vouchers, voucherCode } = this.props,
            photos = vouchers.filter(v => v.mediaType==='PHOTO'),    
            videos = vouchers.filter(v => v.mediaType==='VIDEO');
        
        if (!photos.length && !videos.length) return null;
        
        let photoQty = 0,
            videoQty = 0;
        photos.forEach(v => photoQty += v.qty);
        videos.forEach(v => videoQty += v.qty);
        
        let photoCls = photos.length===1 && voucherCode===photos[0].voucherCode ? ' slide-in' : ''; 
        let videoCls = videos.length===1 && voucherCode===videos[0].voucherCode ? ' slide-in' : ''; 
        
        return (
            <div className="vouchers">
                {
                    photos.length>0 &&
                    <div className={`voucher${photoCls}`}>
                        <div className="voucher-bg"></div>
                        <div className="label">
                            {
                                photoQty===1
                                    ?
                                    <I18n $="order.one_photo"/>
                                    :
                                    <I18n $="order.n_photos" qty={photoQty}/> 
                            }
                        </div>
                    </div>
                }
                {
                    videos.length>0 &&
                    <div className={`voucher${videoCls}`}>
                        <div className="voucher-bg"></div>
                        <div className="label">
                            {
                                videoQty===1
                                    ?
                                    <I18n $="order.one_video"/>
                                    :
                                    <I18n $="order.n_videos" qty={videoQty}/>
                            }
                        </div>
                    </div>
                }
            </div>
        )
    }
    
    render() {
        let { back, cartItems, headerButton } = this.props;
        let cartQty = cartItems ? cartItems.length : 0;

        let showApplyMoreVouchersButton = !Path.KIOSK_VOUCHER_URL.test(window.location.pathname);
        let voucherCount = this.props.vouchers ? this.props.vouchers.length : 0;
        let voucherButtonText = voucherCount > 0 ? 'button.apply_more_vouchers' : 'button.apply_voucher';

        let voucherUrl = `/${Path.KIOSK}/${this.props.sessionTunnel.code}/${Path.VOUCHER}/${Path.START}`;
        if (Path.GUEST_URL.test(window.location.pathname) || Path.SESSION_URL.test(window.location.pathname)) {
            voucherUrl += '/?return=' + window.location.pathname;
        }
        
        return (
            <div className="kiosk-nav">
                {
                    this.state.showCartWarningModal &&
                    <Prompt onClose={this.closeCartWarning} actions={<>
                        <button className="btn-secondary" onClick={this.doStartOver}>
                            <I18n $="button.yes" iFlyUpperCase />
                        </button>{' '}
                        <button className="btn-secondary" onClick={this.closeCartWarning}>
                            <I18n $="button.no" iFlyUpperCase />
                        </button>
                    </>}>
                        <I18n $="media.alert.clear_cart_confirmation" />
                    </Prompt>
                }
                
                
                <div className="brand"><Svg id="ifly-logo"/></div>
                
                {
                    this.props.vouchers && this.renderVouchers()
                }
                
                <div className="actions">
                    
                    {/*{ !back && <div className="btn-primary" onClick={()=>this.props.setLocale('en')}>EN</div> }*/}
                    {/*{ !back && <div className="btn-primary" onClick={()=>this.props.setLocale('pt-BR')}>pt-BR</div> }*/}

                    {
                        showApplyMoreVouchersButton &&
                        <Link to={voucherUrl} className="btn-primary">
                            <I18n $={voucherButtonText} iFlyUpperCase />
                        </Link>
                    }

                    {
                        back &&
                        <button onClick={this.onClickBack} className="back btn-primary">
                            <span className="wrap">
                                <i className="icon"><Svg id="ic-arrow" /></i>
                                <I18n $="button.back" iFlyUpperCase />
                            </span>     
                        </button>
                    }

                    <Link to="/" onClick={this.onStartOver} className="btn-primary">
                        <I18n $="button.start_over" iFlyUpperCase />
                    </Link>

                    { headerButton }

                    <a href={Path.cleanCartUrl()} onClick={this.props.viewCart} className="cart">
                        <i className="icon"><Svg id="ic-cart" /></i>
                        <span className="total m-badge">{cartQty}</span>
                    </a>
                </div>
            </div>
        )
    }
    
    static propTypes = {
        back: PropTypes.bool.isRequired,
        voucherCode: PropTypes.string,
        vouchers: MediaPropTypes.vouchers
    }
}

export default shoppingCart(KioskNavigation);