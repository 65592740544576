import React from 'react'

/**
 * React context to persist media settings shared across the application.
 */

const MediaContext = React.createContext({
    isKiosk: false,
    sessionTunnel: null /// see MediaPropTypes.sessionTunnel for shape
});

export default MediaContext;

/// Wraps the component with a MediaContext.Consumer passing the context value
/// as the props `isKiosk`, and `sessionTunnel`. The wrapped component 
// re-renders if any context value changes.
export const mediaSettings = WrappedComponent =>
    function mediaContext$mediaSettings(props) {
        return <MediaContext.Consumer>
            { contextValue => <WrappedComponent {...props} { ...contextValue } /> }
        </MediaContext.Consumer>
    };