import React from 'react'
import PropTypes from 'prop-types'
import I18n from 'locale/I18n'

const WEEKDAY = [
    <I18n $="calendar.sunday"/>,
    <I18n $="calendar.monday"/>,
    <I18n $="calendar.tuesday"/>,
    <I18n $="calendar.wednesday"/>,
    <I18n $="calendar.thursday"/>,
    <I18n $="calendar.friday"/>,
    <I18n $="calendar.saturday"/>
];

const MONTH = [
    <I18n $="calendar.january" />,
    <I18n $="calendar.february" />,
    <I18n $="calendar.march" />,
    <I18n $="calendar.april" />,
    <I18n $="calendar.may" />,
    <I18n $="calendar.june" />,
    <I18n $="calendar.july" />,
    <I18n $="calendar.august" />,
    <I18n $="calendar.september" />,
    <I18n $="calendar.october" />,
    <I18n $="calendar.november" />,
    <I18n $="calendar.december" />
];

function SessionTime(props) {
    let { time, date } = props;

    return (
        <div className="section-heading">
            { time }
            <br/>
            { WEEKDAY[date.getDay()] }{', '}
            { MONTH[date.getMonth()] }{' '}
            { date.getDate() }{', '}
            { date.getFullYear() }
        </div>
    )
}

SessionTime.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
    time: PropTypes.string.isRequired
}

export default SessionTime;