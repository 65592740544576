import { arrayOf, bool, shape, number, string, oneOf } from 'prop-types'

const guests = arrayOf(shape({
    id: number.isRequired,
    name: string.isRequired
}));

const vouchers = arrayOf(shape({
    voucherCode: string.isRequired,
    mediaType: oneOf(['PHOTO','VIDEO']).isRequired,
    qty: number.isRequired
}));

const flyer = shape({
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    address1: string,
    postalCode: string
});

const wristband = shape({
    guestId: number.isRequired,
    sessionId: number.isRequired,
    date: string.isRequired,
    flyer: flyer
});

const mediaCommon = {
    id: number.isRequired,
    infoId: number,
    dateTime: string.isRequired,
    isHighFlight: bool.isRequired,
    sessionTime: string.isRequired,
    sessionId: number.isRequired,
    thumbnailUrl: string.isRequired,
    mediaUrl: string.isRequired,
    mediaType: oneOf(['PHOTO','VIDEO']).isRequired,
    apiMediaSlug: string.isRequired,
    guests: guests.isRequired
};

const selectableMedia = {
    ...mediaCommon,
    selected: bool
};

const mediaProduct = {
    type: string.isRequired,
    price: number.isRequired,
    productCode: string.isRequired
};

const tunnelCommon = {
    code: string.isRequired,
    name: string.isRequired,
    isNewMedia: bool.isRequired
};

const tunnels = arrayOf(shape({
    ...tunnelCommon,
    city: string.isRequired,
    country: string.isRequired,
    displayName: string.isRequired,
    latitude: string.isRequired,
    longitude: string.isRequired,
    phone: string.isRequired,
    slug: string.isRequired,
    state: string.isRequired,
    stateCode: string.isRequired
}));

/// Complex PropTypes Definitions
const MediaPropTypes = {
    flyer,
    guests,
    tunnels,
    vouchers,
    wristband,
    
    media: arrayOf(shape({...mediaCommon})),
    
    selectableMedia: shape({ ...selectableMedia }),
    selectablePhoto: shape({ ...selectableMedia, mediaType: oneOf(['PHOTO']).isRequired }),
    selectableVideo: shape({ ...selectableMedia, mediaType: oneOf(['VIDEO']).isRequired }),
    
    sessionTunnel: shape({
        ...tunnelCommon,
        products: shape({
            photo: shape({ ...mediaProduct }),
            video: shape({ ...mediaProduct })
        }),
        mediaConfig: shape({
            thankYouMessage: string.isRequired,
            videoPreviewStart: number.isRequired,
            videoPreviewStop: number.isRequired
        })
    }),
    
    video: shape({
        thumbnailUrl: string.isRequired,
        mediaUrl: string.isRequired,
        mediaType: oneOf(['VIDEO']).isRequired
    })
};

export default MediaPropTypes;