import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { createModule } from '@ifly/redux-modules'
import timesearchModule, { TimeSearchSaga } from './timesearch'
import MediaApi from 'api/MediaApi'

const Module = createModule({
    name: 'groupmedia',

    initialState: {
        time: null,
        media: null,
        guests: null,
        isLoading: false
    },

    actions: {
        requestGroupSession: { args:['date', 'sessionId'], reducer:false, dispatch:true }, // reduced by saga
        loadingGroupSession: { },
        receiveGroupSession: { },
        failureGroupSession: { }
    }
});

export default Module;

export class GroupMediaSaga {
    static* watcher() {
        yield all([
            takeLatest(Module.constants.requestGroupSession, GroupMediaSaga.fetchGroupSession)
        ]);
    }

    /**
     * Fetch group media session from the local API for the given session id.
     * @param {Object} action The action being dispatched
     */
    static* fetchGroupSession(action) {
        let isLoading = true;
        yield put(Module.actions.loadingGroupSession({ isLoading }));
        
        let { date, sessionId } = action;
        
        // media slug is used to restore shopping cart (see cart.js)
        const apiMediaSlug = `Session/${sessionId}`;
        
        let ajaxcalls = [
            call(MediaApi.get, `/api/Guests/Session/${sessionId}`),
            call(MediaApi.get, `/api/Media/${apiMediaSlug}`)
        ];

        // look up the time of the session by sessionId from a different module
        let timesearch = yield select(timesearchModule.selector.default);
        if (null === timesearch.mediaSessions) {
            ajaxcalls.push(call(TimeSearchSaga.fetchMediaSessions, { date }));
        }
        
        let [ guestsResponse, mediaResponse ] = yield all(ajaxcalls),
            guests = guestsResponse ? guestsResponse.data : null,
            media = mediaResponse ? mediaResponse.data : null,
            time = null;
        
        isLoading = false;
        
        if (Array.isArray(media)) {
            media.forEach(item => item.apiMediaSlug = apiMediaSlug);
        }

        if (null === timesearch.mediaSessions) {
            timesearch = yield select(timesearchModule.selector.default);
        }
        
        if (Array.isArray(timesearch.mediaSessions)) {
            let mediaSession = timesearch.mediaSessions.find(s => s.id + '' === sessionId);
            time = mediaSession ? mediaSession.time : '';
        }

        if (guestsResponse && mediaResponse) {
            yield put(Module.actions.receiveGroupSession({ guests, media, time, isLoading }));
        } else {
            // server error
            yield put(Module.actions.failureGroupSession({ guests, media, time, isLoading }));
        }
    }
}