import React, { PureComponent } from 'react'
import Svg from 'components/common/Svg';

/// This component is a custom navigation bar for the react-day-picker. See
/// Calendar.js where this component is passed as a `navbarElement` prop to
/// the `DayPicker` component.

const disabled = ' disabled';
const icn = <i className="icon"><Svg id="chevron-right" /></i>

export default class CalendarNavbar extends PureComponent {
    onClickNext = evt => this.props.onNextClick()
    onClickPrev = evt => this.props.onPreviousClick()
    
    render() {
        let { labels, showNextButton, showPreviousButton } = this.props;
        let { previousMonth, nextMonth } = labels;
        
        return (
            <div className="calendar-navbar">
                <div className={`navbtn prev${ showPreviousButton ? '' : disabled}`}
                     tabIndex="0" role="button"
                     onClick={this.onClickPrev}
                     aria-label={previousMonth}>{icn}</div>
                <div className={`navbtn next${ showNextButton ? '' : disabled}`}
                     tabIndex="0" role="button"
                     onClick={this.onClickNext}
                     aria-label={nextMonth}>{icn}</div>
            </div>
        )
    }
}