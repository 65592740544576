import { connect, Field } from 'formik';
import I18n from 'locale/I18n';
import React, { Component } from 'react';
import StringUtil from 'util/StringUtil';
import FieldError from './FieldError';

/**
 * Emit a set of fields to specify a expiration date
 */
class ExpirationDate extends Component {
    handleChange = ({ target: { name, value } }) => {
        let { formik } = this.props;

        let maskedValue = maskNonNumeric(value);
        formik.setFieldValue(name, maskedValue);

        if (autoAdvanceLengths[name] <= maskedValue.length) {
            // find the parent form
            const currentField = document.querySelector(`#${name}`) || document.querySelector(`[name="${name}"]`);
            const form = currentField.closest('form');

            // find the next field by tabIndex
            // we use the spread operator to convert an HtmlElementCollection to an Array
            const fields = [...form.getElementsByTagName('input')]
                .filter(field => field.tabIndex > currentField.tabIndex)
                .sort((a, b) => a.tabIndex - b.tabIndex);

            if (fields.length) {
                // focus on the next field. Need to set a timeout because there may still
                // be some automatic behavior in the field, and we need to let it complete.
                setTimeout(() => {
                    fields[0].focus();
                }, 1);
            }
        }
    };

    render() {
        let { className, formik, tunnel = {}, tabIndex } = this.props;
        const dateFieldLocations = determineDateFieldLocations(tunnel.defaultLanguageTag);

        if (StringUtil.isNotBlank(tabIndex)) {
            try {
                tabIndex = parseInt(tabIndex);
            } catch (error) {
                console.warn("Couldn't parse tabIndex", tabIndex);
                tabIndex = null;
            }
        }

        /*
      Dynamically generate the date fields in the correct order
      for the language of the waiver.
    */
        const dateFields = new Array(2);
        dateFields[dateFieldLocations.expirationYear] = (
            <Field
                name="expirationYear"
                className="expiration-year"
                type="text"
                placeholder="YYYY"
                maxLength="4"
                min={new Date().getFullYear()}
                tabIndex={tabIndex && tabIndex + dateFieldLocations.expirationYear}
                onChange={this.handleChange}
                data-input-type="number"
            />
        );

        dateFields[dateFieldLocations.expirationMonth] = (
            <Field
                name="expirationMonth"
                className="expiration-month"
                type="text"
                placeholder="MM"
                maxLength="2"
                tabIndex={tabIndex && tabIndex + dateFieldLocations.expirationMonth}
                onChange={this.handleChange}
                data-input-type="number"
            />
        );

        return (
            <div className={className}>
                <label>
                    <b><I18n $="checkout.expiration_date"/></b>
                    <div>
                        {dateFields.map((field, index) => (
                            <React.Fragment key={index}>
                                {field}
                                {index < dateFields.length - 1 && (
                                    <div className="date-separator">/</div>
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                </label>
                <FieldError
                    fieldNames={['expirationYear', 'expirationMonth']}
                    {...formik}
                />
            </div>
        );
    }
}

export default connect(ExpirationDate);

/**
 * Format a date to the short form of the current browser locale format
 * @param {Date} date
 */
function formatDate(date, ...locales) {
    return date.toLocaleDateString(...locales, {
        year: 'numeric',
        month: 'numeric'
    });
}

/**
 * Determine the locations for the date fields, based on the given locales
 */
function determineDateFieldLocations(...locales) {
    // because we don't know the locale format, we use a quick hack to determine the format
    const sampleDate = new Date('12/31/1999');
    const sampleFormatted = formatDate(sampleDate, ...locales);
    const sampleDateParts = splitDate(sampleFormatted);

    const expirationYear = findDatePart(sampleDateParts, '1999', '99');
    const expirationMonth = findDatePart(sampleDateParts, '12');

    return {
        expirationYear,
        expirationMonth
    };
}

const dateSeparator = '/';

function splitDate(dateStr) {
    return dateStr.replace(/[^\d]+/g, dateSeparator).split(dateSeparator);
}

function findDatePart(parts, ...part) {
    for (let i = 0; i < parts.length; i++) {
        for (let segment of part) {
            if (parts[i] === segment) return i;
        }
    }
    console.warn('Could not find part', part, 'in', parts);
    return -1;
}

export const DEFAULT_FIELD_NAME = 'expirationDate';

/*
  If the value of this field exceeds this, we can auto-advance
*/
const autoAdvanceLengths = Object.freeze({
    expirationYear: 4,
    expirationMonth: 2
});

/**
 * Mask non-numeric fields
 * @param {string} input
 */
function maskNonNumeric(input) {
    const mask = /[^\d]+/;
    return input.replace(mask, '');
}