import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Cookies from 'js-cookie'
import MediaUtil from 'util/MediaUtil'
import { createSelector } from 'reselect'
import scanner from 'redux/modules/scanner'
import order from 'redux/modules/order'
import MediaPropTypes from 'util/MediaPropTypes'
import { mediaSettings } from 'util/MediaContext'
import { shoppingCart } from 'cart'
import LoadingRoller from 'components/misc/LoadingRoller'
import I18n from 'locale/I18n'
import LineItem from './LineItem'


class ConvergenceOrderSummary extends PureComponent {

	componentDidMount() {
		let { cartItems } = this.props;
		
		if (cartItems?.length) {
			this.props.actions.updateOrder();
		}
	}
	
	componentDidUpdate(prevProps) {
		let { isLoading, cartItems, scannedVouchers } = this.props;
		
		if (isLoading !== prevProps.isLoading) {
			this.props.onLoadingChange(isLoading);
		}
		
		if (cartItems !== prevProps.cartItems || scannedVouchers !== prevProps.scannedVouchers) {
			this.props.actions.updateOrder();
		}
	}
	
    render() {
        let { button, isLoading, cartItems, order, isKiosk } = this.props,
            { videoPrice, videoQty, photoPrice, photoQty, subtotal, tax, total, voucherTotal } = order || {},
            appliedVouchers = 0;

        if (!order) {
            videoPrice = photoPrice = subtotal = tax = total = voucherTotal = null;
        }

        if (isKiosk) {
            let vouchers = this.props.scannedVouchers;
            appliedVouchers = vouchers ? vouchers.length : 0;
        } else {
            let vouchers = Cookies.get('vouchers'); // set from https://www.iflyworld.com/check-balance/
            appliedVouchers = vouchers ? vouchers.split(/,/).length : 0;
        }

        if (isLoading) {
            photoQty = MediaUtil.filterPhotos(cartItems).length;
            videoQty = MediaUtil.filterVideos(cartItems).length;
        }
		
		return(
            <div className="order-summary">
                {isLoading && <LoadingRoller className="loading-order" />}
                <div className="order-ct">
                    <div className="receipt-head">
                        <I18n $="order.order_summary" />
                    </div>
                    <div className="receipt">
                        {
                            photoQty > 0 &&
                            <LineItem value={photoPrice ? photoQty * photoPrice : null}>
                                {
                                    photoQty === 1
                                        ?
                                        <I18n $="order.one_photo" amount={photoPrice} />
                                        :
                                        photoPrice
                                            ?
                                            <I18n $="order.photos_each" qty={photoQty} amount={photoPrice} />
                                            :
                                            <I18n $="order.n_photos" qty={photoQty} />
                                }
                            </LineItem>
                        }
                        {
                            videoQty > 0 &&
                            <LineItem value={videoPrice ? videoQty * videoPrice : null}>
                                {
                                    videoQty === 1
                                        ?
                                        <I18n $="order.one_video" amount={videoPrice} />
                                        :
                                        videoPrice
                                            ?
                                            <I18n $="order.videos_each" qty={videoQty} amount={videoPrice} />
                                            :
                                            <I18n $="order.n_videos" qty={videoQty} />
                                }
                            </LineItem>
                        }
                        <LineItem className="value-hr" />
                        <LineItem value={subtotal}><I18n $="order.subtotal" /></LineItem>
                        <LineItem value={tax}><I18n $="order.tax" /></LineItem>
                        {
                            voucherTotal > 0 &&
                            <LineItem value={-1 * voucherTotal}>
                                {
                                    appliedVouchers > 1 && cartItems.length > 1
                                        ?
                                        <I18n $="order.applied_vouchers" />
                                        :
                                        <I18n $="order.applied_voucher" />
                                }
                            </LineItem>
                        }
                        
                        <LineItem className="full-hr" />
                        <LineItem className="receipt-total" value={total}><I18n $="order.order_total" /></LineItem>
                    </div>
                    {
                        !isLoading && button
                    }
                </div>
            </div>
		)
    }

    static defaultProps = {
        onLoadingChange: () => { }
    }
	
    static propTypes = {
        // mediaSettings
        isKiosk: PropTypes.bool,

        // shoppingCart
        cartItems: MediaPropTypes.media
    }
}

const selector = createSelector(
	order.selector.default,
	scanner.selector.vouchers,
	(order={}, scannedVouchers={}) => ({ ...order, scannedVouchers })
);

export default mediaSettings(shoppingCart(order.connect(ConvergenceOrderSummary, selector)));