import React, { PureComponent } from 'react'
import { shoppingCart } from "cart";
import PropTypes from 'prop-types'
import MediaPropTypes from 'util/MediaPropTypes'
import memoize from 'memoize-one'
import I18n, { i18n, localize } from 'locale/I18n'
import Svg from 'components/common/Svg';
import { mediaSettings } from 'util/MediaContext'
import MediaUtil from 'util/MediaUtil'
import Path from 'util/PathUtil'
import Loading from 'components/misc/Loading'
import groupmedia from 'redux/modules/groupmedia'
import GuestButton from './GuestButton'
import ShoppingMediaGrid from './ShoppingMediaGrid'
import { navigate } from '@reach/router'

import SessionTime from './SessionTime'

const ALL_MEDIA = { id:0, name:i18n('button.all_guests') };
const UNASSIGNED_MEDIA = { id:-1, name:i18n('media.unassigned') };

class GroupView extends PureComponent {
    
    state = {
        selectedGuests: []
    }

    onClickBack = evt => {
        const url = new URL(window.location.href);
        url.pathname = url.pathname.split('/session')[0];
        window.location.href = url.href;
    }
    
    maybeFetchGroupSession = () => {
        let { dateSlug, sessionId, isLoading, sessionTunnel } = this.props;
        if (sessionTunnel && dateSlug && sessionId && !isLoading) {
            this.props.actions.requestGroupSession(dateSlug, sessionId);
        }
    }
    
    maybeRestoreSelectedGuests = () => {
        let { '*':selectedGuestIds, guests } = this.props;

        if (selectedGuestIds && guests.length) {
            let selectedGuests = selectedGuestIds.split(/,/).map(id => parseInt(id,10));
            
            for (let guestId of selectedGuests) {
                if (!guests.find(g => g.id===guestId)) {
                    console.info(`Unknown guestId [${guestId}]. ... guests=`, guests);
                    let url = Path.cleanSessionUrl();
                    this.props.navigate(url, { replace:true });
                    return;
                }
            }

            this.setState({ selectedGuests });
        }
    }
    
    componentDidMount() {
        let { isLoading, media, guests, sessionId, isKiosk } = this.props;
        media = media || [];
        guests = guests || [];

        if (guests.length && media.length && sessionId===media[0].sessionId) {
            this.maybeRestoreSelectedGuests();
        } else if (!isLoading) {
            this.maybeFetchGroupSession();
        }

        MediaUtil.scrollViewportTop(isKiosk);
    }
    
    componentDidUpdate(prevProps) {
        let { sessionId, sessionTunnel, guests } = this.props;
        
        if (sessionId!==prevProps.sessionId ||
            sessionTunnel!==prevProps.sessionTunnel) {
            this.maybeFetchGroupSession();
        }
        
        if (guests && guests !== prevProps.guests) {
            this.maybeRestoreSelectedGuests();
        }
    }

    getGuestList = memoize(
        (guests, media) => {
            if (!guests || !media) return guests;
            
            let guestIds = [];
            guests.forEach(g => guestIds.push(g.id));
            
            let list = [ ALL_MEDIA, ...guests ];
            
            if (media.find(m => 0===m.guests.length)) {
                // if any media have no associated guests, include UNASSIGNED
                list.push(UNASSIGNED_MEDIA);
            }
            
            return list;
        }
    )

    filterMedia = memoize(
        (media, guestIds) => {
            if (!media) return media;

            let filteredMedia = [];
            
            media.forEach(item => {
                // media has an array of associated guests 
                let { guests } = item;
                
                if (0===guestIds.length ||                                            // All Guests selected
                    (0===guests.length && guestIds.includes(UNASSIGNED_MEDIA.id)) ||  // Unassigned selected
                    0!==guests.filter(g => guestIds.includes(g.id)).length) {         // Guest selected
                    filteredMedia.push(item);
                    // filter the media (unless All Guests option is selected)
                }
            });
            
            if (guestIds.length>0) {
                // sort by rank value when selected
                filteredMedia.sort(MediaUtil.sortMediaByRankValue);
            }
            
            return filteredMedia;
        }
    )

    viewCart = evt => {
        evt.preventDefault();
        evt.stopPropagation();

        if(!this.props.cartItems?.length) {
            return;
        }
        
        navigate(Path.cleanCartUrl());
    }
    
    selectGuest = guestId => {
        let sel = [ ...this.state.selectedGuests ],
            selectedGuests = [];

        if (guestId) {
            
            if (sel.length===1 && sel[0]===guestId) {
                return; // ignore deselect of only selected guest
            }
            
            // DX-7278 --- Guest Selection should be a Select Single
            selectedGuests = [ guestId ];
            //
            /// Original Select Multiple Implementation:
            ///
            // selectedGuests = sel.includes(guestId)
            //     ? sel.filter(num => num !== guestId) // deselect guest
            //     : [ ...sel, guestId ];               // select guest
            
        } else if (!sel.length) {
            return; // all media already selected
        }
        
        this.setState({ selectedGuests });
        this.props.navigate(selectedGuests.join(','), { replace:true });
    }

    render() {
        let { time, guests, media, isLoading, dateSlug, cartItems } = this.props;
        let { selectedGuests } = this.state;
        let guestList = this.getGuestList(guests, media);

        media = this.filterMedia(media, selectedGuests);
        selectedGuests = selectedGuests.length ? selectedGuests : [ALL_MEDIA.id];

        return (
            <div className="group-view">
                <div className="view-head">
                    <I18n $="prompt.select_photos_and_videos" />
                </div>
                {
                    !time || !guests || !media || isLoading
                        ?
                        <Loading />
                        :
                        <>
                            <SessionTime time={time} date={Path.parseDate(dateSlug)} />
                            {
                                guestList.length
                                    ?
                                    guestList.map(({ id, name }, index) =>
                                        <GuestButton key={`${index}--${id}`}
                                                     id={id}
                                                     onClick={this.selectGuest} 
                                                     selected={selectedGuests.includes(id)}>
                                            { name }
                                        </GuestButton>
                                    )
                                    :
                                    <div className="no-guests"><I18n $="media.no_guests_yet"/></div>
                            }
    
                            <ShoppingMediaGrid media={media} showPrice={true} />
                        </>
                }
                {
                    !time || !guests || !media || isLoading ? <></> :
                    <div className='web-btn-container'>
                        <button onClick={this.onClickBack} className="back btn-primary">
                            <span className="wrap">
                                <i className="icon"><Svg id="ic-arrow" /></i>
                                <I18n $="button.back" iFlyUpperCase />
                            </span>     
                        </button>
                        <button onClick={this.viewCart} className={`cart btn-primary ${cartItems?.length > 0 ? 'btn-active' : 'btn-inactive'}`}>
                            <span className="wrap">
                                <I18n $="button.view_cart" iFlyUpperCase />
                            </span>     
                        </button>
                    </div>
                }
                
            </div>
        )
    }
    
    static propTypes = {
        // mediaSettings
        isKiosk: PropTypes.bool,
        sessionTunnel: MediaPropTypes.sessionTunnel,
        
        // reach-router
        sessionId: PropTypes.string,

        // shoppingCart
        cartItems: MediaPropTypes.media,

        // redux -- groupmedia.connect()
        time: PropTypes.string,
        media: MediaPropTypes.media,
        guests: MediaPropTypes.guests,
        isLoading: PropTypes.bool.isRequired,

        actions: PropTypes.shape({
            requestGroupSession: PropTypes.func.isRequired
        }).isRequired
    }
}

export default localize(mediaSettings(shoppingCart(groupmedia.connect(GroupView))));