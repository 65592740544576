import commonEn from './languages/common_en.js'
import commonPtBR from './languages/common_pt-BR.js'
import en from './languages/en.js'
import ptBR from './languages/pt-BR.js'
import { addBundle } from '@ifly/ifly-i18n'

addBundle('poc-media', { 'en': en, 'pt-BR':ptBR });
addBundle('ifly-web-common', { 'en': commonEn, 'pt-BR': commonPtBR });

export * from '@ifly/ifly-i18n';
export { default } from '@ifly/ifly-i18n';
