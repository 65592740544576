// import axios from 'axios'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import MediaPropTypes from 'util/MediaPropTypes'
import Cookies from 'js-cookie'
import { contextualize } from 'locale/I18n'
import session from 'redux/modules/session'
import MediaContext from 'util/MediaContext'
import TunnelContext from 'util/TunnelContext'
import Loading from 'components/misc/Loading'
import ShoppingCart from 'components/ShoppingCart'
import WebLayout from 'components/web/WebLayout'
import KioskLayout from 'components/kiosk/KioskLayout'
import Alert from './components/misc/Prompt'
import I18n from 'locale/I18n'

class App extends PureComponent {
	
    // tunnelContext state fn
    setTunnel = tunnel => {
        this.props.actions.setSessionTunnel(tunnel);
    }

    state = {
        mediaContext: {
            isKiosk: false,
            sessionTunnel: null
        },
        
        tunnelContext: {
            tunnel: Cookies.get('tunnel'),
            setTunnel: tunnelCode => {
                this.setTunnel(tunnelCode);
            }
        }
    }

    clearRequestException = () => this.props.actions.setRequestException(false)
    
    componentDidUpdate(prevProps) {
        let { isKiosk, tunnel } = this.props,
            tunnelContext = { ...this.state.tunnelContext },
            nextState = {};
        
        if (tunnel && tunnel !== prevProps.tunnel && tunnel.code !== tunnelContext.tunnel) {
            console.log('TUNNEL: ', tunnel);
            tunnelContext.tunnel = tunnel.code; // update tunnelContext
			tunnelContext.venueId = tunnel.convergenceVenueId;
            nextState.tunnelContext = tunnelContext;
        }
        
        if (tunnel !== prevProps.tunnel || isKiosk !== prevProps.isKiosk) {
            nextState.mediaContext = { // update mediaContext
                ...this.state.mediaContext, 
                isKiosk,
                sessionTunnel: tunnel
            };
        }
        
        if (Object.keys(nextState).length) {
            this.setState(nextState);
        }
        
        if (isKiosk !== prevProps.isKiosk && !tunnel && tunnelContext.tunnel) {
            // requestSession() is done ;; set tunnel if available
            this.setTunnel(tunnelContext.tunnel);
        }
    }
    
    componentDidMount() {
        this.props.actions.requestSession();
    }



    render() {
        let { isLoading, isKiosk } = this.props;
        
        if (isKiosk===undefined) return <Loading/>;
        
        return (
            <MediaContext.Provider value={this.state.mediaContext}>
{/*
                <button onClick={evt => { evt.stopPropagation(); axios.post('/api/Session/Reset'); }}
                        style={{position:'absolute', top:10, left:370, zIndex:10, padding:'6px 12px'}}>
                    Clear Session
                </button>

                <button onClick={evt => { evt.stopPropagation(); this.props.actions.setRequestException(false); }}
                        style={{position:'absolute', top:10, left:500, zIndex:10, padding:'6px 12px'}}>
                    Exception False
                </button>

                <button onClick={evt => { evt.stopPropagation(); this.props.actions.setRequestException(true); }}
                        style={{position:'absolute', top:10, left:650, zIndex:10, padding:'6px 12px'}}>
                    Exception True
                </button>
*/}
                
                {
                    this.props.isServerError &&
                    <Alert onClose={this.clearRequestException}><I18n $="error.server" /></Alert>
                }
                
                { isLoading && <Loading/> }
                <ShoppingCart>
                { 
                    isKiosk
                        ?
                        <KioskLayout clearSession={this.props.actions.clearSession}/>
                        :
                        <TunnelContext.Provider value={this.state.tunnelContext}>
                            <WebLayout />
                        </TunnelContext.Provider>
                }
                </ShoppingCart>
                
            </MediaContext.Provider>
        );
    }
    
    static propTypes = {
        // redux -- session.connect()
        isKiosk: PropTypes.bool,
        isLoading: PropTypes.bool,
        isServerError: PropTypes.bool,
        tunnel: MediaPropTypes.sessionTunnel,

        actions: PropTypes.shape({
            clearSession: PropTypes.func.isRequired,
            requestSession: PropTypes.func.isRequired,
            setSessionTunnel: PropTypes.func.isRequired,
            setRequestException: PropTypes.func.isRequired
        }).isRequired
    }
}

export default contextualize(session.connect(App));