/**
 * Brazillian Portuguese language bundle module.
 *
 * @module module:locale/languages/en_BR
 * @see module:locale/languages/bundle
 * @see module:locale/languages/en
 */

/** The language bundle. */
export default {
    $: {
        code: 'pt-BR',
        name: 'Portuguese (Brazil)'
    },

    auth: {
        greeting: 'Olá %{name}'
    },

    button: {
        account: 'Conta',
        all_locations: 'Todos os Locais',
        back: 'Volte',
        book_a_flight: 'Agendar um Voo',
        cart: 'Carrinho',
        close: 'Fechar',
        login: 'Conecte-se',
        logout: 'Sair',
        menu: 'Cardápio',
        my_profile: 'Minha Conta',
        nearby: 'Perto',
        privacy_policy: 'Política de Privacidade',
        search: 'Procurar',
        selected: 'Selecionado',
        shopping_cart: 'Carrinho de Compras',
        sign_up: 'Inscrever-se',
        terms_of_use: 'Termos de Uso',
        toggle: 'Alternancia',
        toggle_close: 'Alternar Fechar',
        toggle_open: 'Alternar Abrir',
        upcoming_flights: 'Próximos Voos'
    },

    common: {
        copyright: '© 2019 iFLY Holdings, LLC. Todos os direitos reservados.'
    },

    country: {
        usa: 'Estados Unidos',
        international: 'Internacional'
    },

    footer: {
        safety_standards: 'Manter os mais altos padrões de segurança, treinamento e competições de acordo com as',
        site_map: 'Mapa do Site'
    },

    header: {
        authenticating: 'Autenticando',
        greeting: 'Bem vindo de volta!',
        hi: 'Oi,',
        language: 'Língua',
        location: 'Localização',
        near_you: 'Perto de você',
        others_nearby: 'Outros por Perto',
        select_language: 'Selecione o Idioma'
    },

    languages: {
        english: 'Inglês',
        portuguese: 'Português'
    },

    loginform: {
        title: 'Sua conta para tudo o iFLY',
        forgot_password: 'Esqueceu a senha?',
        log_in: 'Conecte-se',
        join_now: 'Não é um membro? Inscrever-se',
        agreement: {
            preamble: 'Ao fazer o login, você concorda com o iFLY',
            and: 'e'
        },
        error: {
            credential: 'Seu email ou senha foram digitados incorretamente.',
            unknown: 'Ocorreu um erro de autenticação desconhecido.'
        }
    },

    placeholder: {
        email: 'O email',
        password: 'Senha'
    }
}