import axios from 'axios'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { createModule } from '@ifly/redux-modules'
import Cookies from 'js-cookie'

const Module = createModule({
    name: 'session',
    
    initialState: {
        tunnel: null,
        isKiosk: undefined,
        isLoading: false,
        isServerError: false
    },
    
    actions: {
        clearSession: { reducer: false, dispatch:true },                // reduced by saga
        requestSession: { reducer: false, dispatch:true },              // reduced by saga
        setSessionTunnel: { args:['tunnelCode'], reducer:false, dispatch:true },   // reduced by saga

        setRequestException: { args:['isServerError'], dispatch:true },

        restoringSession: {},
        restoredSession: {},

        loadingSessionTunnel: {},
        receiveSessionTunnel: {},
        failureSessionTunnel: {},

        loadingSession: {},
        receiveSession: {},
        failureSession: {}
    }
});

export default Module;

export class SessionSaga {

    static* watcher() {
        yield all([
            takeLatest(Module.constants.clearSession, SessionSaga.resetSession),
            takeLatest(Module.constants.requestSession, SessionSaga.fetchSession),
            takeLatest(Module.constants.setSessionTunnel, SessionSaga.setSessionTunnel)
        ]);
    }
    
    static* resetSession() {
        yield call(request, `/api/Session/Reset`);
    }
    
    static* restoreSession() {
        let session = yield select(Module.selector.default);
        let code = session.tunnel?.code;  
        
        let isLoading = true;
        yield put(Module.actions.restoringSession({ isLoading }));

        let data = { isKiosk:session.isKiosk, tunnel:code };
        // already inside a try/catch from MediaApi.js
        const response = yield call(axios.post, '/api/Session', data);
        isLoading = false;

        let { tunnel=null } = response.data || {};
        yield put(Module.actions.restoredSession({ tunnel, isLoading }));
    }
    
    /**
     * Set the tunnel for the shopping session (web only).
     * @param {Object} action The action being dispatched
     */
    static* setSessionTunnel(action) {
        let { tunnelCode } = action,
            isLoading = true;
        
        yield put(Module.actions.loadingSessionTunnel({ isLoading }));
        
        let response = yield call(request, `/api/Session/Tunnel/${tunnelCode}`);
        
        isLoading = false;
        
        if (response) {
            let { tunnel } = response.data || {};
            yield put(Module.actions.receiveSessionTunnel({ tunnel, isLoading }));
        } else {
            // server error
            yield put(Module.actions.failureSessionTunnel({ isLoading }));
        }
    }
    
    /**
     * Start a user session via the local API
     * @param {Object} action The action being dispatched
     */
    static* fetchSession(action) {
        let isLoading = true;
        
        yield put(Module.actions.loadingSession({ tunnel:null, isLoading }));

        // DEBUG KIOSK: set cookie named 'kiosk' to a tunnel code (e.g. AUS)
        let tunnelCode = Cookies.get('kiosk'),
            data = tunnelCode ? { isKiosk:true, tunnel:tunnelCode } : {},
            response = yield call(request, '/api/Session', data);
        
        isLoading = false;
        
        if (response) {
            let { isKiosk, tunnel=null } = response.data || {};
            yield put(Module.actions.receiveSession({ tunnel, isKiosk, isLoading }));
        } else {
            // server error
            yield put(Module.actions.failureSession({ isLoading }));
        }
    }
}

/**
 * Manages an axios request which may throw an exception.
 * @param args The request arguments.
 * @returns A response object.
 */
function* request(url, data) {
    let response = null;

    try {
        response = yield call(axios.post, url, data);

    } catch (err) {
        console.warn('MediaApi Exception', new Date(), url, data, err);
        yield put(Module.actions.setRequestException(true));
    }

    return response;
}