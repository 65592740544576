import React from 'react'
import PropTypes from 'prop-types'
import I18n from 'locale/I18n'
import CommonUtil from 'util/CommonUtil'

const i18n_location = <I18n $="header.location"/>
const i18n_near_you = <I18n $="header.near_you"/>
const i18n_all_locations = <I18n $="button.all_locations"/>

/// Force telephone links to navigate normally rather than triggering the
/// parent PopupMenu to open the locations menu.
const onClickTel = evt => evt.stopPropagation();

const LocationsToggle = React.memo(function LocationsToggle(props) {
    let { tunnel, selectedTunnel } = props;
    
    if (!tunnel) return <span className="near">{ i18n_all_locations }</span>

    let displayName = tunnel.display_name || tunnel.displayName; // support snake and camel case

    return <>
        <span className="near">{selectedTunnel ? i18n_location : i18n_near_you }</span>
        <span className="city">{`${displayName}, ${tunnel.state}`}</span>
        <a className="phone tel" href={CommonUtil.telUrl(tunnel.phone)} onClick={onClickTel}>
            {tunnel.phone}
        </a>
    </>
});

LocationsToggle.propTypes = {
    tunnel: PropTypes.object,
    selectedTunnel: PropTypes.string
}

export default LocationsToggle;