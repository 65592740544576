import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { shoppingCart } from 'cart'
import KioskKeyboard from '@ifly/react-kiosk-keyboard'
import { Link } from '@reach/router'
import I18n from 'locale/I18n'
import Path from 'util/PathUtil'
import convergencesale from 'redux/modules/convergencesale'
import scanner from 'redux/modules/scanner'
import order from 'redux/modules/order'
import { createSelector } from 'reselect'
import MediaPropTypes from 'util/MediaPropTypes'
import MediaUtil from 'util/MediaUtil'
import Svg from 'components/common/Svg';
import CheckoutForm from './ConvergenceCheckoutForm'
import AddVoucherForm from './ConvergenceVoucherForm'
import Loading from 'components/misc/Loading'
import Alert from 'components/misc/Prompt'
import ConvergenceOrderSummary from 'components/cart/ConvergenceOrderSummary'
import Cookies from 'js-cookie';
import { Prompt } from 'components/misc/Prompt'

const FORM_ID = 'convergence-checkout';
const VOUCHER_FORM_ID = 'convergence-enter-voucher';

const DEV_USE_TEST_VALUES = false;
const DEV_TEST_VALUES = {
    firstName: 'Dev01',
    lastName: 'DevName',
    email: 'dev01@iflytest.com',
    phone: '+15125551212',
    address1: '123 Main ST',
    city: 'Allston',
    state: 'MA',
    postalCode: '02134',
    cardNumber: '5454545454545454',
    expirationMonth: '01',
    expirationYear: '2022',
    cvv: '123'
};


class ConvergenceCheckout extends PureComponent {

    state = {
        showServerError: false,
        showTransactionError: false,
        formValues: DEV_USE_TEST_VALUES ? DEV_TEST_VALUES : {},
        showCartWarningModal: false,
        isKeyboardVisible: true
    }

    enterVoucher = evt => {
        evt.preventDefault();
        evt.stopPropagation();
        let { navigate } = this.props,
            path = Path.cleanConvergenceVoucherUrl();

        navigate(path);
    }

    hideServerError = () => this.setState({ showServerError: false })
    hideTransactionError = () => this.setState({ showTransactionError: false })

    setFormikRef = formik => this.form = formik
    onSubmitForm = evt => {
        this.form.submitForm();
    }

    toggleKeyboard = () => {
        this.setState(prevState => ({
            isKeyboardVisible: !prevState.isKeyboardVisible
        }));
    }

    processSale = (values, form) => {
        values.isKiosk = this.props.kioskTunnelCode ? true : false;
        this.props.actions.processSale(values);
        this.setState(prevState => ({
            ...prevState,
            formValues: values
        }));
    }

    applyVoucher = (values) => {
        let data = {
            voucherCode: values
        };

        this.props.actions.applyVoucher(data);
        this.setState(prevState => ({
            ...prevState,
            formValues: values
        }));
    }

    onStartOver = evt => {
        let { cartItems } = this.props;
        if (cartItems?.length) {
            evt.preventDefault();
            evt.stopPropagation();
            this.setState({ showCartWarningModal: true });

        } else {
            this.props.clearCart();
        }
    }

    closeCartWarning = () => this.setState({ showCartWarningModal: false })

    doStartOver = evt => {
        let { navigate } = this.props;
        evt.preventDefault();
        evt.stopPropagation();

        this.props.clearCart();

        navigate(Path.cleanTunnelUrl());
    }

    onClickBack = evt => {
        window.history.back();
    }

    removeVouchersFromNavBar = () => {
        /* Removes the nav bar photo and video voucher counts. 
         * Uncertain how to refresh the nav bar after the vouchers were removed, so doing this the non-react way 
         */
        let elements = document.getElementsByClassName('voucher slide-in');
        while (elements.length > 0) {
            elements[0].parentNode.removeChild(elements[0]);
        }
    }

    clearAllVouchers = (values) => {
        /* Clear all kiosk vouchers */
        let vouchers = this.props.scannedVouchers;

        if (vouchers) {
            while (vouchers.length > 0) {
                vouchers.pop();
            }
        }

        this.removeVouchersFromNavBar();

        /* Clear vouchers from back-end cart */
        this.props.actions.clearAllVouchers();
    }

    receiveVoucher = (values) => {   
        this.prop.actions.updateOrder(values);
        this.setState(prevState => ({
            ...prevState,
            formValues: values
        }));
    }

    componentDidUpdate(prevProps) {
        let { error, transactionError, navigate, sale, isLoading } = this.props,
            { formValues } = this.state;

        if (sale && !isLoading && !error) {
            if (sale.status === 'OK') {
                // sale result succeeded, move to confirmation page
                navigate(`../${Path.DONE}`);
            }
        }

        // Check formValues so that we only display any errors if the user has submitted the form.
        // Otherwise, a residual error may exist from a prior submission, triggering an alert.
        if (formValues && error && !prevProps.error) {
            this.setState({ showServerError: true });
        }

        if (formValues && transactionError && !prevProps.transactionError) {
            this.setState({ showTransactionError: true });
        }

    }

    componentWillUnmount() {
        // restore the header button to default state
        this.props.setHeaderButton();
    }

    componentDidMount() {
        this.props.actions.resetSale();
        MediaUtil.scrollViewportTop();
    }

    render() {
        let { cartItems } = this.props;
        let { transactionError, wristband = {}, order = {}, kioskTunnelCode, isLoading } = this.props,
            { showServerError, showTransactionError, formValues, isKeyboardVisible } = this.state;
        let isKiosk = (kioskTunnelCode) ? true : false,
            hasVouchers = false,
            appliedVouchers = 0;

        if (isKiosk) {
            let vouchers = this.props.scannedVouchers;

            appliedVouchers = vouchers ? vouchers.length : 0;
        } else {
            let vouchers = Cookies.get('vouchers'); // set from https://www.iflyworld.com/check-balance/

            appliedVouchers = vouchers ? vouchers.split(/,/).length : 0;
        }

        hasVouchers = appliedVouchers > 0;
        
        let isOrderReady = false;
        if (order.order != null && hasVouchers && order.order.total === 0) {
            isOrderReady = true;
        }
        if (order.order != null && !hasVouchers) {
            isOrderReady = true;
        }

        return <>
            {
                showServerError &&
                <Alert onClose={this.hideServerError}><I18n $="error.server" /></Alert>
            }
            {
                showTransactionError &&
                <Alert onClose={this.hideTransactionError}>{transactionError}</Alert>
            }

            {isLoading && <Loading />}

            {
                this.state.showCartWarningModal &&
                <Prompt onClose={this.closeCartWarning} actions={<>
                    <button className="btn-secondary" onClick={this.doStartOver}>
                        <I18n $="button.yes" iFlyUpperCase />
                    </button>{' '}
                    <button className="btn-secondary" onClick={this.closeCartWarning}>
                        <I18n $="button.no" iFlyUpperCase />
                    </button>
                </>}>
                    <I18n $="media.alert.clear_cart_confirmation" />
                </Prompt>
            }

            {<div className={isLoading ? "hide-convergence-checkout" : ""}>
                <div className="convergence-checkout">
                    <div className="view-head">
                        <I18n $="order.complete_your_order" />
                    </div>
                    <div className="view-body">
                        {cartItems.length === 0 &&
                            <div className="empty-cart">
                                <I18n $="order.empty_cart" /><br />
                                <Link to={`../../${Path.datePath()}`}><I18n $="order.browse_media" /></Link>{' '}
                                <I18n $="order.to_begin_shopping" />
                            </div>
                        }
                        <div className={cartItems.length === 0 ? "hide-convergence-checkout" : isKiosk ? "convergence-checkout-kiosk-body" : "convergence-checkout-body"}>
                            <div className="left-col">
                                <CheckoutForm formId={FORM_ID}
                                    formRef={this.setFormikRef}
                                    onSubmit={this.processSale}
                                    values={{ ...wristband, ...formValues }}
                                />
                            </div>
 
                            <div className="right-col">
                                {
                                    !isKiosk &&
                                    <AddVoucherForm formId={VOUCHER_FORM_ID}
                                        formRef={this.setFormikRef}
                                        onSubmit={this.applyVoucher}
                                        onClear={this.clearAllVouchers}
                                        values={{ ...formValues }} />
                                }
                                
                                <ConvergenceOrderSummary
                                    onLoadingChange={this.setLoading}
                                    button={
                                        <div className="order-checkout-buttons">
                                        {
                                            !isLoading &&
                                                <div class="flex-container">
                                                    If you have pre-purchased a voucher, check your original purchase receipt email for the voucher code.
                                                </div>

                                        }
                                        {
                                            isLoading  &&
                                            <button className="btn-disabled" type="button">
                                                Please Wait
                                            </button>
                                        }
                                        {
                                            (!isLoading && isOrderReady && hasVouchers) &&
                                            <button onClick={this.onSubmitForm} className="btn-primary" >
                                                <I18n $="button.place_order" iFlyUpperCase />
                                            </button>
                                        }
                                        {
                                            (!isLoading && isOrderReady && !hasVouchers) &&
                                            <button onClick={this.onSubmitForm} className="btn-primary" >
                                                CONTINUE TO PAYMENT
                                            </button>
                                        }
                                        {
                                            (!isLoading && !isOrderReady && hasVouchers) &&
                                            <>
                                                <label>Add more vouchers to complete your order *OR* clear the vouchers to continue to payment. Vouchers cannot be used in the same transaction as a credit card. Please make separate purchases for each payment method.</label>
                                                
                                                <button className="btn-disabled" type="button">
                                                    <I18n $="button.place_order" iFlyUpperCase />
                                                </button>
                                            </>
                                            
                                        }
                                            {
                                                !isLoading && isKiosk &&
                                                <div className="flex-container">
                                                    <div className="or">- OR -</div>
                                                </div>
                                            }
                                            <div className="flex-container">
                                                {
                                                    isKiosk &&
                                                    <button onClick={this.enterVoucher} className={hasVouchers ? "btn-secondary flex-left-big" : "btn-secondary" }>
                                                        <I18n $="button.apply_voucher" iFlyUpperCase />
                                                    </button>
                                                }
                                                {
                                                    !isLoading && !isKiosk &&
                                                    <button onClick={this.onClickBack} className="back">
                                                        <span className="wrap">
                                                            <i className="icon"><Svg id="ic-arrow" /></i>
                                                            <I18n $="button.back" iFlyUpperCase />
                                                        </span>     
                                                    </button>
                                                }
                                                {
                                                    (!isLoading && hasVouchers) &&
                                                    <button className={`btn-warning ${isKiosk ? 'flex-right-small' : ''}`} onClick={this.clearAllVouchers}>CLEAR VOUCHERS</button>
                                                }
                                            </div>
                                        </div>
                                    } />
                            </div>
                            {
                                isKiosk &&
                                <div>
                                    <button 
                                        onClick={this.toggleKeyboard}
                                        className={`toggle-button ${isKeyboardVisible ? 'button-up' : 'button-down'}`}
                                    >{isKeyboardVisible ? 'Hide Keyboard' : 'Show Keyboard'}</button>
                                    <div className={`${isKeyboardVisible ? 'keyboard-visible' : 'keyboard-hidden'}`}>
                                        <KioskKeyboard formId={FORM_ID} />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            }
        </>
    }

    static propTypes = {
        // shoppingCart
        cartItems: MediaPropTypes.media,

        // redux -- sale.connect()
        error: PropTypes.bool,
        isLoading: PropTypes.bool.isRequired,
        sale: PropTypes.object,
        transactionError: PropTypes.string,

        actions: PropTypes.shape({
            processSale: PropTypes.func.isRequired,
            applyVoucher: PropTypes.func.isRequired
        }).isRequired,

        // redux -- createSelector :: scanner.vouchers
        vouchers: MediaPropTypes.vouchers,

        // redux -- createSelector :: scanner.wristband.flyer
        wristband: MediaPropTypes.flyer,

        // reach router
        navigate: PropTypes.func
    };
}

const selector = createSelector(
    convergencesale.selector.default,
    scanner.selector.vouchers,
    order.selector.default,
    (sale, scannedVouchers = {}, order) => ({ ...sale, scannedVouchers, order })
);

export default shoppingCart(convergencesale.connect(ConvergenceCheckout, selector));