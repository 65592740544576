import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import I18n, { localize, i18n } from 'locale/I18n'
import CountryCityState from 'countrycitystatejson'
import { Field, Form, Formik } from 'formik'
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import order from 'redux/modules/order'
import { scrollToError } from 'util/DomUtil'
import StringUtil from 'util/StringUtil'
import CountriesAndStates from 'util/CountriesAndStates'
import AutocompleteField from '../fields/AutocompleteField'
import FieldError from '../fields/FieldError'
import ExpirationDate from '../fields/ExpirationDate'
import PhoneInput from '../fields/PhoneInput'

const defaultCountry = 'US';

class CheckoutForm extends PureComponent {
    
    validateForm = values => {
        let errors = {},
            { expirationYear, expirationMonth } = values;

        if (StringUtil.isBlank(values.firstName)) errors.firstName = i18n('checkout.error.first_name');
        if (StringUtil.isBlank(values.lastName)) errors.lastName = i18n('checkout.error.last_name');

        if (!emailIsValid(values.email)) errors.email = i18n('checkout.error.email');
        if (StringUtil.isBlank(values.phone) || !isPossiblePhoneNumber(values.phone)) errors.phone = i18n('checkout.error.phone');

        if (StringUtil.isBlank(values.address1)) errors.address1 = i18n('checkout.error.address1');
        if (StringUtil.isBlank(values.city)) errors.city = i18n('checkout.error.city');
        if (StringUtil.isBlank(values.state)) errors.state = i18n('checkout.error.state');
        if (StringUtil.isBlank(values.postalCode)) errors.postalCode = i18n('checkout.error.postal_code');
        
        if (0!==this.props.order?.total) {
            if (StringUtil.isBlank(values.cardNumber)) errors.cardNumber = i18n('checkout.error.card_number');
            if (StringUtil.isBlank(expirationMonth)) errors.expirationMonth = i18n('checkout.error.expiration_month');
            if (StringUtil.isBlank(expirationYear)) errors.expirationYear = i18n('checkout.error.expiration_year');
            if (StringUtil.isNotBlank(expirationYear) && StringUtil.isNotBlank(expirationMonth)) {
                try {
                    if (isExpired(parseDate(expirationYear, expirationMonth, '01' ))) {
                        errors.expirationYear = i18n('checkout.error.card_expired');
                    }
                } catch (error) {
                    errors.expirationYear = i18n('checkout.error.invalid_date');
                }
            }
            if (StringUtil.isBlank(values.cvv)) errors.cvv = i18n('checkout.error.cvv');
        }

        setTimeout(scrollToError, 0);
        return errors;
    }

    formRenderer = formik => {
        let { formId, formRef } = this.props;
        if (formRef) formRef(formik);

        const { values } = formik;
        const country = values.country;
        const states = getStatesForCountry(country);
        
        return (
            <Form id={formId} className="checkout-form">
                <div className="col">
                    <div className="row">
                        <div className="field">
                            <label>
                                <b><I18n $="checkout.first_name"/></b>
                                <Field name="firstName" type="text" autoFocus data-kiosk-auto-capitalize />
                            </label>
                            <FieldError fieldName="firstName" {...formik} />
                        </div>
                        <div className="field">
                            <label>
                                <b><I18n $="checkout.last_name"/></b>
                                <Field name="lastName" type="text" data-kiosk-auto-capitalize />
                            </label>
                            <FieldError fieldName="lastName" {...formik} />
                        </div>
                    </div>
                    <div className="field">
                        <label>
                            <b><I18n $="checkout.email"/></b>
                            <Field name="email" type="text" />
                        </label>
                        <FieldError fieldName="email" {...formik} />
                    </div>
                    <div className="field phone">
                        <label>
                            <b><I18n $="checkout.phone"/></b>
                            <Field type="text" name="phone" defaultCountry={country} component={PhoneInput} />
                        </label>
                        <FieldError fieldName="phone" {...formik} />
                    </div>
                </div>
                
                <div className="col">
                    <div className="field">
                        <label>
                            <b><I18n $="checkout.address1"/></b>
                            <Field type="text" name="address1" data-kiosk-auto-capitalize />
                        </label>
                        <FieldError fieldName="address1" {...formik} />
                    </div>

                    <div className="autocomplete field">
                        <label>
                            <b><I18n $="checkout.country"/></b>
                            <Field name="country" component={AutocompleteCountry} options={countryOptions} />
                        </label>
                        <FieldError fieldName="country" {...formik} />
                    </div>
                    <div className="field">
                        <label>
                            <b><I18n $="checkout.city"/></b>
                            <Field type="text" name="city" data-kiosk-auto-capitalize />
                        </label>
                        <FieldError fieldName="city" {...formik} />
                    </div>

                    <div className="row">
                        <div className="autocomplete field state">
                            <label>
                                <b><I18n $="checkout.state"/></b>
                                <Field name="state" component={AutocompleteState} options={states} />
                            </label>
                            <FieldError fieldName="state" {...formik} />
                        </div>
                        <div className="field postal-code">
                            <label>
                                <b><I18n $="checkout.postal_code"/></b>
                                <Field type="text" name="postalCode" />
                            </label>
                            <FieldError fieldName="postalCode" {...formik} />
                        </div>
                    </div>
                        
                </div>
                
                <div className="col">
                    <div className="field card-number">
                        <label>
                            <b><I18n $="checkout.card_number"/></b>
                            <Field type="text" maxLength="16" name="cardNumber" data-input-type="number" />
                        </label>
                        <FieldError fieldName="cardNumber" {...formik} />
                    </div>
                    <div className="row">
                        <ExpirationDate className="field expiration-date" />
                        <div className="field cvv">
                            <label>
                                <b><I18n $="checkout.cvv"/></b>
                                <Field type="text" name="cvv" data-input-type="number" />
                            </label>
                            <FieldError fieldName="cvv" {...formik} />
                        </div>
                    </div>
                </div>
            </Form>
        )
    }
    
    render() {
        return <Formik onSubmit={this.props.onSubmit}
                       validate={this.validateForm}
                       initialValues={{
                           firstName: '',
                           lastName: '',
                           email: '',
                           phone: '',

                           address1: '',
                           country: defaultCountry,
                           city:'',
                           state:'',
                           postalCode: '',
                           
                           cardNumber: '',
                           expirationMonth: '',
                           expirationYear: '',
                           cvv: '',
                           ...this.props.values
                       }}>{ this.formRenderer }</Formik>
    }
    
    static propTypes = {
        formId: PropTypes.string.isRequired, // the HTML ID to assign the form
        formRef: PropTypes.func, // a reference to the Formik form will be passed to this function
        onSubmit: PropTypes.func.isRequired, // the function to call when the form is submitted
        values: PropTypes.object // any form values that are already known
    }
}

export default localize(connect(order.selector.default)(CheckoutForm));

const AutocompleteCountry = React.memo(function AutocompleteCountry(props) {
    return <AutocompleteField {...props} maxMenuHeight={285} />
});

const AutocompleteState = React.memo(function AutocompleteState(props) {
    return <AutocompleteField {...props} menuPlacement="top" maxMenuHeight={290} />
});

/**
 * Attempt to interpret the supplied values as a date
 * @param {*} year
 * @param {*} month
 * @param {*} day
 * @param {*} strict
 */
function parseDate(year, month, day, strict = true) {
    const result = new Date();

    try {
        year = parseInt(year);
        month = parseInt(month);
        day = parseInt(day);

        // pre-load the date to a 31-day month in order to avoid unwanted date errors because of month / day combinations
        result.setMonth(0);

        // set the year first in case of leap-year
        result.setFullYear(year);
        result.setDate(day);
        result.setMonth(month - 1);

        // strict handles cases where we enter a date and JS interpolates it into some other plausible date,
        // e.g. 01/32/1900 => 02/01/1900
        if (strict) {
            if (
                result.getFullYear() !== year ||
                result.getMonth() !== month - 1 ||
                result.getDate() !== day
            ) {
                throw new Error('Improper date: ', year, month, day);
            }
        }

        return result;
    } catch (error) {
        throw new Error('Invalid date: ', 'error', error);
    }
}

/**
 * Slightly naive but 99.9% useful email validator
 * @param {string} email
 */
function emailIsValid(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

function isExpired(expirationDate) {
    // the expiration date needs to be on this month or later
    let today = new Date();

    return (
        expirationDate.getFullYear() < today.getFullYear() ||
        (expirationDate.getFullYear() === today.getFullYear() &&
            expirationDate.getMonth() < today.getMonth())
    );
}


/**
 * Generate all the country options
 */
const countryOptions = CountryCityState.getCountries()
    .map(country => ({
        value: country.shortName,
        label: country.name
    }))
    .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0));

/**
 * Generate the state options for a given country code
 * @param {string} country
 */
function getStatesForCountry(country) {
    const states = CountryCityState.getStatesByShort(country) || [];

    // we have a data structure that contains countries => state map, where state is { name: "Name", abbreviation: "AB"}
    // However, this only covers the states in US and Canada
    const stateAbbreviations =
        (CountriesAndStates.find(entry => entry.abbreviation === country) || {})
            .states || [];
    const abbreviations = {};

    for (let i in stateAbbreviations) {
        const state = stateAbbreviations[i];
        abbreviations[state.name] = state.abbreviation;
    }

    let options ;
    if (country) {
        if (states?.length) {
            options = states.map(state => {
                return { label: state, value: abbreviations[state] || state };
            });
        } else {
            // in some rare cases, e.g. Åland, there are no states defined.
            // in those cases, we just use the country again. Don't use the country
            // abbreviation because that may be the same as a state abbreviation, and
            // that could throw off some queries downstream
            const countryLong = CountryCityState.getCountryByShort(country).name;
            options = [
                {
                    label: countryLong,
                    value: countryLong
                }
            ];
        }
    } else {
        options = [];
    }

    return options;
}
