/**
 * Add a CSS class to an element if not already present
 * @param {object} element
 * @param {string} className
 */
export function addClass(element, className) {
  if (element.className.indexOf(className) < 0) {
    element.className += " ".concat(className);
  }
}

/**
 * Remove a CSS class from an element if present
 * @param {object} element
 * @param {*} className
 */
export function removeClass(element, className) {
  if (element.className.indexOf(className) > 0) {
    element.className = element.className.replace(" ".concat(className), "");
  }
}

/**
 * Look for any error messages on the page, and scroll to the highest one found
 */
export function scrollToError() {
  // convert a NodeList to an Array
  const error = document.querySelector(".error");
  if (error) {
    window.scrollBy({
      left: 0,
      // scroll some extra pixels in order to show the offending choice
      top: error.getBoundingClientRect().top - 125,
      behavior: "smooth"
    });
  }
}
