import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import MediaPropTypes from 'util/MediaPropTypes'
import Slider from 'react-slick'
import I18n from 'locale/I18n'
import Svg from 'components/common/Svg';
import MediaUtil from 'util/MediaUtil'
import { mediaSettings } from 'util/MediaContext'
import KioskVideo from '../kiosk/KioskVideo'
import WebVideo from '../web/WebVideo'
import 'slick-carousel/slick/slick.css'
// import 'slick-carousel/slick/slick-theme.css' // we don't use any of the slick UI elements
// See:
// https://react-slick.neostack.com/docs/api/

// potrait mode constants
const PORTRAIT = 'portrait';
const IS_PORTRAIT = /\bportrait\b/; 
const PHOTO_MAX_HEIGHT = 494; // see MediaDetail.scss :: $web-photo-max-height

const MONTH = [
    <I18n $="calendar.january" />,
    <I18n $="calendar.february" />,
    <I18n $="calendar.march" />,
    <I18n $="calendar.april" />,
    <I18n $="calendar.may" />,
    <I18n $="calendar.june" />,
    <I18n $="calendar.july" />,
    <I18n $="calendar.august" />,
    <I18n $="calendar.september" />,
    <I18n $="calendar.october" />,
    <I18n $="calendar.november" />,
    <I18n $="calendar.december" />
];

function getVideoAt(index) {
    let sel = `.media-carousel .slick-slide[data-index="${index}"] video`;
    return document.querySelector(sel);
} 

class MediaDetail extends PureComponent {
    
    constructor(props) {
        super(props);
        this.detailRef = React.createRef();
    }
    
    slider = { 
        slickNext:()=>{},
        slickPrev:()=>{}
    }
    
    setSliderRef = slider => this.slider = slider;
    
    /// Pauses the currently visible video before carousel slide changes.
    onBeforeChange = index => {
        let video = getVideoAt(index);
        if (video) video.pause();
    }
    
    /// Manages the now visible video after the carousel slide changes.
    onAfterChange = index => {
        let video = getVideoAt(index);
        if (video) {
            // allow the new video to buffer
            video.setAttribute('preload', 'auto');
            
            if (!this.props.isKiosk) {
                /// Fixes a chrome bug where the video controls do not span the width of the
                /// video until focus. Applying the `controls` attribute after the animation
                /// of the carousel slide is complete prevents slide misalignment.
                video.setAttribute('controls', '');
            }
        }
    }
    
    onClickPrev = () => this.slider.slickPrev()
    onClickNext = () => this.slider.slickNext()
    
    /// Toggles portrait mode on window resize (to minimize whitespace).
    onResize = () => {
        let { innerWidth:w, innerHeight:h } = window,
            el = this.detailRef.current;
        if (!h || !el) return;
        
        let isPortrait = IS_PORTRAIT.test(el.className);         
        
        // Applying a portrait class (for device_S) sets the max-height of the
        // className=body element to 100% of the viewport width. The css rules
        // already enforce a max-height of PHOTO_MAX_HEIGHT (494px) so only if
        // if the viewport is narrower than photo max height, and if the width
        // is 66% less than the height should the portrait class be applied.
        
        if (w<PHOTO_MAX_HEIGHT && w/h < 0.66) {
            if (!isPortrait) el.classList.add(PORTRAIT);
        } else {
            if (isPortrait) el.classList.remove(PORTRAIT);
        }

        // console.log(`the window size is now ${w}x${h} ... w/h = ${(w/h).toFixed(3)
        //    } ... ${IS_PORTRAIT.test(el.className) ? 'portrait' : 'landscape'}`);
    }
    
    componentDidMount() {
        window.addEventListener('resize', this.onResize);
        this.onResize();
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    renderGuestName = guests => {
        if (!guests || 0===guests.length) return null;
        if (guests.length > 1) return <I18n $="media.multiple_flyers" />
        
        let { name } = guests[0];
        if (name==='UNASSIGNED') return <I18n $="media.unassigned" /> 
            
        return name;
    }
    
    render() {
        let { media, initialId, isKiosk } = this.props;
        
        return (
            <div ref={this.detailRef} className="media-detail">
                <Slider arrows={false}
                        ref={this.setSliderRef}
                        className="media-carousel"
                        beforeChange={this.onBeforeChange}
                        afterChange={this.onAfterChange}
                        initialSlide={media.findIndex(i => i.id===initialId)}>
                {
                    media.map(item => {
                        let { year, month, day } = MediaUtil.parseISODate(item.dateTime);
                        
                        return (
                            <div key={item.id} className="media-slide">
                                <button className="close" onClick={this.props.closeModal}><Svg id="ic-close"/></button>
                                <div className="info">
                                    <div className="time">
                                        <span>{ ` ${item.sessionTime}` }</span>
                                        <br/>
                                        <span className="_on S">{' '}&ndash;{' '}</span>
                                        <span>{ MONTH[month - 1] }{ ` ${day}, ${year} ` }</span>
                                    </div>
                                    <div className="view-head">
                                        { this.renderGuestName(item.guests) }
                                    </div>
                                    <div className={`item-id ${item.mediaType === 'PHOTO' ? 'photo' : 'video'}`}>
                                        { item.infoId }
                                    </div>
                                </div>
                                <div className="body">
                                {
                                    item.mediaType === 'PHOTO'
                                        ?
                                        <figure style={{ backgroundImage: `url(${item.mediaUrl})` }}></figure>
                                        :
                                        isKiosk
                                            ?
                                            <KioskVideo video={item} preload={item.id===initialId} />
                                            :
                                            <WebVideo video={item} preload={item.id===initialId} />
                                }
                                </div>
                                <div className="actions">
                                    <div className="item-actions">
                                        { this.props.renderItemActions(item) }
                                    </div>

                                    <button onClick={this.onClickPrev} className="nav prev btn-secondary">
                                        <i className="prev icon"><Svg id="ic-arrow-down"/></i>
                                        <I18n $="button.previous" />
                                    </button>


                                    <button onClick={this.onClickNext} className="nav next btn-secondary">
                                        <I18n $="button.next" />
                                        <i className="next icon"><Svg id="ic-arrow-down"/></i>
                                    </button>
                                </div>
                            </div>
                        )
                    })
                }
                </Slider>
            </div>
        )
    }
    
    static defaultProps = {
        renderItemActions: () => null
    }
    
    static propTypes = {
        // passed props
        media: MediaPropTypes.media,
        initialId: PropTypes.number.isRequired,
        renderItemActions: PropTypes.func.isRequired
    }
}

export default mediaSettings(MediaDetail);