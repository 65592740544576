import React, {Component} from 'react'
import PropTypes from 'prop-types'
import CommonUtil from 'util/CommonUtil'

// body css class applied while the modal is active
const MODAL_OPEN = 'accpl-modal-open'

/**
 * This modal dialog fades in to show its content. Pressing ESC or clicking
 * outside the content area dismisses the modal (with a fade-out animation).
 * Immediate children of the modal are passed a `closeModal` property so they
 * may close the modal programmatically. 
 */
export class Modal extends Component {
    /**
     * Constructor establishes child references for determining click origin.
     * @param {Object} props The component properties.
     */
    constructor(props) {
        super(props);
        this.contentRef = React.createRef();
    }

    /**
     * Applies an animation class to the content element fading it out before
     * the modal is dismissed completely.
     */
    onBeforeClose = () => {
        this.contentRef.current.classList.add('closing');
        setTimeout(() => this.props.onClose(), 500);
    }
    
    /**
     * Click handler closes the popup if the click happens outside the menu.
     * @param {Event} evt The browser event.
     */
    onWindowClick = evt => {
        if (!this.contentRef.current.contains(evt.target)) {
            this.onBeforeClose();
        }
    }

    /**
     * Keypress handler closes the popup if ESC is pressed.
     * @param {Event} evt The browser event.
     */
    onWindowKeypress = evt => {
        if (CommonUtil.ESC === evt.keyCode) {
            this.onBeforeClose();
        }
    }

    /**
     * Adds a body modal css class and listeners to close the modal if the 
     * user clicks away or presses ESC.
     */
    componentDidMount = () => {
        window.addEventListener('click', this.onWindowClick);
        window.addEventListener('keydown', this.onWindowKeypress);
        CommonUtil.addBodyCls(MODAL_OPEN);

        // Fix jumpiness that occurs when setting the body overflow:hidden by 
        // setting padding-right on position:fixed elements (body, header)
        // to compensate for the space typically occupied by the scrollbar 
        let body = window.document.body,
            header = body.querySelector('header');
        if (header) {
            let sWidth = CommonUtil.getScrollbarWidth() + 'px';
            header.style.paddingRight = body.style.paddingRight = sWidth;
        }
    }
    
    /**
     * Removes the body modal css class and listeners to close the modal.
     */
    componentWillUnmount = () => {
        window.removeEventListener('click', this.onWindowClick);
        window.removeEventListener('keydown', this.onWindowKeypress);
        CommonUtil.removeBodyCls(MODAL_OPEN);

        // un-set padding-right on position:fixed elements (body, header)
        let body = window.document.body,
            header = body.querySelector('header');
        if (header) {
            header.style.paddingRight = body.style.paddingRight = null;
        }
    }
    
    render() {
        return (
            <div className="accpl-modal">
                <div className="overlay"></div>
                <div className="content" ref={this.contentRef}>
                {
                    // Pass closeModal fn to child component(s) for use in the content.
                    CommonUtil.mapChildrenProps(this.props.children, {
                        closeModal: this.onBeforeClose
                    })
                }
               </div>
            </div>
        );
    }
    
    static propTypes = {
        onClose: PropTypes.func.isRequired
    }
}
