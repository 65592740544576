import React from 'react'

/**
 * React context implements a shopping cart. This context is not required 
 * for default operation of the Header. 
 */
const CartContext = React.createContext({
    /// Array of items in the cart.
    cartItems: [],

    /// Maximum number of items allowed in the cart.
    cartLimit: 500,
    
    /// Add items to the cart.
    addToCart: () => {},

    /// Remove all items from the cart.
    clearCart: () => {},

    /// Remove items from the cart.
    removeFromCart: () => {},

    /// Set to a function called instead of navigating to the cart page,
    /// or set null to navigate the browser to the default url (/cart). 
    viewCart: null,

    /// Toggles the visibility of the user account login controls.
    isAccountHidden: false,

    /// Set to a component configuration, or set `true` to display the 
    /// default BOOK A FLIGHT button, or `false` to hide the button. 
    headerButton: true
});

export default CartContext;

/// Wraps the component with a CartContext.Consumer passing the context value
/// as the props `cartItems`, `addToCart`, and `removeFromCart`. The wrapped
/// component re-renders if any context value changes.
export const shoppingCart = WrappedComponent =>
    function CartContext$shoppingCart(props) {
        return <CartContext.Consumer>
            { contextValue => <WrappedComponent {...props} { ...contextValue } /> }
        </CartContext.Consumer>
    };