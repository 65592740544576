import React from 'react'
import PropTypes from 'prop-types'
import ExternalLink from './ExternalLink'
import Environment from '@ifly/common-env'

/**
 * This class renders an anchor tag using the WordPress server root configured
 * in `webpack.config.js` prepended to the given `path` property. An optional
 * `label` property may be supplied in lieu of using `props.children` as the
 * anchor label.
 * 
 * Example: <WpLink path="/">Home Page</WpLink>
 * Renders: <a href="https://www.iflyworld.com/">Home Page</a>
 *
 * Example: <WpLink path="/cart" className="cart">Cart</WpLink>
 * Renders: <a href="https://www.iflyworld.com/cart" class="cart">Cart</a>
 *
 * Example: <WpLink path="/" label="Home" className="top link" />
 * Renders: <a href="https://www.iflyworld.com/" class="top link">Home</a>
 *
 * Example: <WpLink path="/book-now" label="Book a Flight" />
 * Renders: <a href="https://www.iflyworld.com/book-now">Book a Flight</a>
 */
const WpLink = React.memo(function WpLink(props) {
    return <ExternalLink root={Environment.wordPressServer()} {...props} />
});

WpLink.propTypes = {
    path: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    // all other attributes are passed down to the anchor
}

export default WpLink;