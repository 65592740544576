const GRECAPTCHA_KEY = '6LeylrkUAAAAAPq2tQYEzOdcws9urZaA-K4sXFDI';

const script = document.createElement('script');
script.type = 'text/javascript';
script.async = true;
script.onload = function(){};
script.src = `https://www.google.com/recaptcha/api.js?render=${GRECAPTCHA_KEY}`;
document.getElementById('root').appendChild(script);

// Saga Usage:
//
//     const token = yield call(Recaptcha.getToken);
//
export default class Recaptcha {
    static getToken(action='media') {
        return window.grecaptcha.execute(GRECAPTCHA_KEY, { action });
    }
}