import React from 'react'
import PropTypes from 'prop-types'

import I18n from 'locale/I18n'
import Svg from 'components/common/Svg'
import WpLink from 'components/link/WpLink'

const i18n_all_locations = <I18n $="button.all_locations" />
const i18n_location = <I18n $="header.location" />
const i18n_near_you = <I18n $="header.near_you" />
const i18n_others_nearby = <I18n $="header.others_nearby" iFlyUpperCase />

const LocationsSubMenu = React.memo(function LocationsSubMenu(props) {
    let [ tunnel ] = props.nearbyTunnels || [];
    
    return tunnel && <>
        <li className="menu-item">
            <div className="location">
                <div className="near">{ props.selectedTunnel ? i18n_location : i18n_near_you }</div>
                <div className="wrap">
                    <i className="icon"><Svg id="ic-pin"/></i>
                    <WpLink className="city" path={tunnel.slug}>
                        {
                            tunnel.display_name || tunnel.displayName /* support old and new model name */
                        }
                    </WpLink>
                </div>
            </div>
        </li>
        <li className="menu-item">
            <div className="location other">
                <div className="near">{ i18n_others_nearby }</div>
                { props.nearbyTunnels.slice(1,5).map(nearby =>
                    <span className="opt" key={nearby.tunnel || nearby.code /* support old and new model name for tunnel code */}>
                        <WpLink path={nearby.slug}>
                            { 
                                nearby.display_name || nearby.displayName /* support old and new model name */
                            }
                        </WpLink>
                    </span>)
                }
            </div>
        </li>

        <li className="menu-item">
            <div className="location all">
                <a href={props.url} className="all">
                    <i className="icon pin _on LD XL"><Svg id="pin-menu"/></i>
                    { i18n_all_locations }
                </a>
            </div>
        </li>
    </>
});

LocationsSubMenu.propTypes = {
    url: PropTypes.string,
    nearbyTunnels: PropTypes.array,
    selectedTunnel: PropTypes.string
};

export default LocationsSubMenu;