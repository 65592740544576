import { all, call, put, takeLatest } from 'redux-saga/effects'
import { createModule } from '@ifly/redux-modules'
import MediaApi from 'api/MediaApi'

const Module = createModule({
    name: 'medialookup',

    initialState: {
        media: null,
        isLoading: false
    },

    actions: {
        lookupMedia: {args: ['lookupCode'], reducer: false, dispatch: true}, // reduced by saga
        loadingMedia: {},
        receiveMedia: {},
        failureMedia: {}
    }
});

export default Module;

export class MediaLookupSaga {
    static* watcher() {
        yield all([
            takeLatest(Module.constants.lookupMedia, MediaLookupSaga.lookupMedia)
        ]);
    }

    /**
     * Lookup a guest from the local API for the given lookup code.
     * @param {Object} action The action being dispatched
     */
    static* lookupMedia(action) {
        let { lookupCode } = action;

        let isLoading = true;
        yield put(Module.actions.loadingMedia({ isLoading }));

        let response = yield call(MediaApi.get, `/api/Guests/Lookup/${lookupCode}`),
            media = null;

        isLoading = false;
        
        if (response) {
            media = response.data;
            yield put(Module.actions.receiveMedia({ media, isLoading }));
        } else {
            // server error
            yield put(Module.actions.failureMedia({ media, isLoading }));
        }
    }
}