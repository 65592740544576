import axios from 'axios'
import { call, put } from 'redux-saga/effects'
import sessionModule, { SessionSaga } from 'redux/modules/session'

/**
 *  Basic Saga Usage:
 *
 *     let response = yield call(MediApi.post, '/api/Resource', data);
 *     if (response) {
 *        // success
 *     } else {
 *        // exception (server error) 
 *     }
 *
 */
export default class MediaApi {
    static* get(url) {
        return yield call(request, axios.get, url);
    }

    static* post(url, data) {
        return yield call(request, axios.post, url, data);
    }
}

const SESSION_TUNNEL_NOT_SET = 'Tunnel not set in session.';
// TODO: From order.js --- getting this response: {"success":false,"message":"Session not started."}

/**
 * Manages an axios request which may throw an exception or require the session
 * to be restored before retrying the request.
 * @param args The request arguments.
 * @returns A response object.
 */
function* request(...args) {
    let response = null;

    try {
        response = yield call(...args);

        if (isSessionExpired(response)) {
            yield call(SessionSaga.restoreSession);
            // try again with a restored session
            response = yield call(...args);
        }
        
    } catch (err) {
        console.warn('MediaApi Exception', new Date(), ...args.slice(1), err);
        yield put(sessionModule.actions.setRequestException(true));
    }
    
    return response;
}

/**
 * Returns true if the response indicates the session is expired.
 * @param {Object} response The ajax response.
 */
function isSessionExpired(response) {
    let { data } = response || {};
    return (false === data.success && data.message === SESSION_TUNNEL_NOT_SET);
}