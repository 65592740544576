import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { i18n } from 'locale/I18n'

const customTheme = theme => ({
    ...theme,
    borderRadius: 0,
    colors: {
        ...theme.colors,
        neutral20: '#ddd',  // border color
        primary: '#95b6ec'  // focus border color
        
        // see: https://react-select.com/styles#overriding-the-theme
    }
});

/**
 * The component to render a Formik Field as a ReactAutocomplete
 * @param {object} params
 */
class AutocompleteField extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedOption: null
        };

        this.selectRef = React.createRef();
    }

    handleBlur = event => {
        const { field } = this.props;
        field.handleBlur && field.handleBlur(event);
        this.setState({
            ...this.state
        });
    };

    handleChange = selectedOption => {
        const { form, field } = this.props;
        this.setState({ selectedOption }, () => {
            form.setFieldValue(field.name, selectedOption.value);
        });
    };

    handleFocus = event => {
        this.setState({
            ...this.state
        });
    };

    render() {
        const { field, options, tabIndex, menuPlacement, maxMenuHeight } = this.props;
        let { selectedOption } = this.state;

        if (!selectedOption) {
            selectedOption = options.find(option => option.value === field.value);
        }
        
        return (
            <Select
                name={field.name}
                value={selectedOption}
                isSearchable
                noOptionsMessage={() => i18n("prompt.no_options")}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                onChange={this.handleChange}
                options={options}
                theme={customTheme}
                tabIndex={tabIndex}
                ref={this.selectRef}

                maxMenuHeight={maxMenuHeight}
                menuPlacement={menuPlacement}
            />
        );
    }

    static propTypes = {
        field: PropTypes.object.isRequired, // from Formik
        form: PropTypes.object.isRequired, // from Formik
        options: PropTypes.array.isRequired // an array of name/value objects
    };
}

export default AutocompleteField;