import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import MediaPropTypes from 'util/MediaPropTypes'
import Environment from '@ifly/common-env'
import { shoppingCart } from 'cart'
import { Link, navigate } from '@reach/router'
import I18n, { localize, i18n } from 'locale/I18n'
import Path from 'util/PathUtil'
import Svg from 'components/common/Svg';
import MediaUtil from 'util/MediaUtil'
import { mediaSettings } from 'util/MediaContext'
import ShoppingMediaGrid from 'components/browse/ShoppingMediaGrid'
import OrderSummary from 'components/cart/OrderSummary'
import ConvergenceOrderSummary from './ConvergenceOrderSummary'
import { Prompt } from 'components/misc/Prompt'

class CartSummary extends PureComponent {
    state = {
        isLoading: false,
        showCartWarningModal: false
    }
    
	setLoading = isLoading => this.setState({ isLoading })

    onStartOver = evt => {
        let { cartItems } = this.props;
        if (cartItems?.length) {
            evt.preventDefault();
            evt.stopPropagation();
            this.setState({ showCartWarningModal: true });

        } else {
            this.props.clearCart();
        }
    }

    onClickBack = evt => {
        window.history.back();
    }

    closeCartWarning = () => this.setState({ showCartWarningModal: false })

    doStartOver = evt => {
        let { navigate } = this.props;
        evt.preventDefault();
        evt.stopPropagation();

        this.props.clearCart();

        navigate(Path.cleanTunnelUrl());        
    }

    goToCheckoutPage = evt => {
        evt.preventDefault();
        evt.stopPropagation();
        navigate(Path.cleanCheckoutUrl());
    }

    goToConvergenceCheckoutPage = evt => {
        evt.preventDefault();
        evt.stopPropagation();
        let path = Path.cleanConvergenceCheckoutUrl();

        console.log('navigating to...', path);
        navigate(Path.cleanConvergenceCheckoutUrl());
    }
    
    componentDidMount() {
        this.props.setHeaderButton(null);
        MediaUtil.scrollViewportTop(this.props.isKiosk);
    }
    
    componentDidUpdate(prevProps, prevState) {
        let { isLoading } = this.state,
            { isKiosk, cartItems } = this.props;
        let convergenceVenueId = this.props?.sessionTunnel?.convergenceVenueId;
        let isConvergence = convergenceVenueId != null;
        
        if (!cartItems || !cartItems.length || (isLoading && !prevState.isLoading)) {
            this.props.setHeaderButton(null);
        } else if (!isLoading && prevState.isLoading) {
            if (isConvergence) {
                this.props.setHeaderButton(
                    <a href={Path.cleanConvergenceCheckoutUrl()} onClick={this.goToConvergenceCheckoutPage}>
                        <I18n $="button.check_out" iFlyUpperCase />
                    </a>
                );
            } else {
                this.props.setHeaderButton(isKiosk
                    ?
                    <a href={Path.cleanCheckoutUrl()} onClick={this.goToCheckoutPage}>
                        <I18n $="button.check_out" iFlyUpperCase />
                    </a>
                    :
                    <a href={`${Environment.wordPressServer()}${i18n('checkout_path')}`}>
                        <I18n $="button.check_out" iFlyUpperCase />
                    </a>
                );
            }            
        }
    }
    
    componentWillUnmount() {
        // restore the header button to default state
        this.props.setHeaderButton();
    }
    
    renderSummary = () => {
        let { cartItems, isKiosk } = this.props;
        let { isLoading } = this.state;
        let convergenceVenueId = this.props?.sessionTunnel?.convergenceVenueId;
        let isConvergence = convergenceVenueId != null;
        let button = null;

        if (isConvergence) {
            button = <div>
                <div>On the next page, if you have a media voucher, enter your voucher number (include all punctuation, dashes); if you did not pre-purchase a voucher, proceed with payment.</div>
                <a className="btn-primary" href={Path.cleanConvergenceCheckoutUrl()} onClick={this.goToConvergenceCheckoutPage}>
                    <I18n $="button.check_out" iFlyUpperCase />
                </a>
                {
                    !isKiosk &&
                    <div className="order-btn-group">
                        <button onClick={this.onClickBack} className="back btn-primary">
                            <span className="wrap">
                                <i className="icon"><Svg id="ic-arrow" /></i>
                                <I18n $="button.back" iFlyUpperCase />
                            </span>     
                        </button>
                        <a className="btn-warning" href="." onClick={this.onStartOver}> Start Over </a>
                    </div>
                }
            </div>

        } else {
            button = isKiosk
                ?
                <div><Link className="btn-primary" to={`../${Path.CHECKOUT}`}>
                        <I18n $="button.check_out" iFlyUpperCase />
                    </Link>
                </div>
                :
                <div><a className="btn-primary" href={`${Environment.wordPressServer()}${i18n('checkout_path')}`}>
                        <I18n $="button.check_out" iFlyUpperCase />
                    </a>
                </div>
        }
        
        return (
            <div className="summary">
                <div className="cart">
                    <ShoppingMediaGrid media={cartItems} checkoutMode isLoading={isLoading} />
                </div>
                {
                    isConvergence &&
                    <ConvergenceOrderSummary button={button} onLoadingChange={this.setLoading} />
                }
                {
                    !isConvergence &&
                    <OrderSummary button={button} onLoadingChange={this.setLoading} />
                }    
            </div>
        )
    }

    render() {
        let { cartItems } = this.props;
        
        return(
            <div className="cart-summary">
                {
                    this.state.showCartWarningModal &&
                    <Prompt onClose={this.closeCartWarning} actions={<>
                        <button className="btn-secondary" onClick={this.doStartOver}>
                            <I18n $="button.yes" iFlyUpperCase />
                        </button>{' '}
                        <button className="btn-secondary" onClick={this.closeCartWarning}>
                            <I18n $="button.no" iFlyUpperCase />
                        </button>
                    </>}>
                        <I18n $="media.alert.clear_cart_confirmation" />
                    </Prompt>
                }

                <div className="view-head">
                    <I18n $="order.shopping_cart" />
                </div>
                {
                    cartItems.length===0
                        ?
                        <div className="empty-cart">
                            <I18n $="order.empty_cart" /><br/>
                            <Link to={`../${Path.datePath()}`}><I18n $="order.browse_media" /></Link>{' '}
                            <I18n $="order.to_begin_shopping" />
                        </div>
                        :
                        this.renderSummary()
                }
            </div>
        )
    }

    static propTypes = {
        // mediaSettings
        isKiosk: PropTypes.bool,

        // shoppingCart
        cartItems: MediaPropTypes.media
    }
}

export default localize(mediaSettings(shoppingCart(CartSummary)));