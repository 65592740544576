import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import I18n, { localize } from 'locale/I18n'
import Path from 'util/PathUtil';

const FORM_ID = "convergence-enter-voucher";

class ConvergenceVoucherForm extends PureComponent {
    state = {
        voucherCode: ''
    }

    constructor(props) {
        super(props);
        this.inputEl = React.createRef();
    }

    onChange = evt => this.setState({ voucherCode: evt.target.value.toUpperCase() })

    applyVoucher = evt => {
        let el = this.inputEl.current,
            code = el.value.replace(/ /, '');

        if (code) {
            this.props.onSubmit(code, Path.START === this.props['*']);
            this.setState({ voucherCode: '' });
        } else {
            el.focus();
        }
    }

    componentWillUnmount = () => {
        window.document.body.classList.remove(FORM_ID);
    }

    componentDidMount = () => {
        // hide the shopping cart navigation glyph
        window.document.body.classList.add(FORM_ID);
        this.inputEl.current.focus();
    }

    render() {
        return (
            
            <form id={FORM_ID} className="convergence-voucher-form">
                <div className="voucher-fieldset">
                    <input data-input-type="voucher"
                        ref={this.inputEl}
                        name="voucherCode"
                        maxLength={20}
                        value={this.state.voucherCode}
                        placeholder="Add Voucher Code"
                        onChange={this.onChange} />
                    <button type="button" className="" onClick={this.applyVoucher}>
                        <I18n $="button.apply" iFlyUpperCase />
                    </button>
                </div>
            </form>
        )
    }

    static propTypes = {
        formId: PropTypes.string.isRequired, // the HTML ID to assign the form
        formRef: PropTypes.func, // a reference to the Formik form will be passed to this function
        onSubmit: PropTypes.func.isRequired, // the function to call when the form is submitted
        values: PropTypes.object // any form values that are already known
    }
}

export default localize(ConvergenceVoucherForm);
