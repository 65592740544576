import React, { PureComponent } from 'react'
import I18n from 'locale/I18n'
import QrCode from 'qrcode.react';
import Path from 'util/PathUtil'
import Svg from 'components/common/Svg'

const logoSvg = <Svg id="ifly-logo-box" />
const voucherSvg = <Svg id="voucher" />
const searchSvg = <Svg id="calendar-clock" />

export default class StartPage extends PureComponent {

    enterVoucher = () => {
        let { navigate, kioskTunnelCode } = this.props,
            voucherUrl = `/${Path.KIOSK}/${kioskTunnelCode}/${Path.VOUCHER}/${Path.START}`;
        navigate(voucherUrl);
    }

    componentDidMount() {
        this.props.resetKiosk();
    }
    
    render() {
        let { kioskTunnelCode, convergenceVenueId } = this.props,
            host = window.location.hostname.replace(/-kiosk/,''),
            desktopUrl = `https://${host}/${Path.TUNNEL}/${kioskTunnelCode}`;
        
        return (
            <section className="start">
                <div className="brand"><i className="icon">{logoSvg}</i></div>
                <div className="title"><I18n $="kiosk.title" /></div>
                <div className="prompt"><I18n $="kiosk.prompt.choose_an_action" iFlyUpperCase /></div>
                
                <div className="menu">
                    <div className="item qrcode">
                        <div className="action"><I18n $="kiosk.action.qrcode" /></div>
                        <QrCode size={192} renderAs="svg" value={desktopUrl} />
                    </div>
                    <div className={convergenceVenueId === null ? "item wristband" : "item wristband hidden" }>
                        <div className="action"><I18n $="kiosk.action.wristband" /></div>
                        <figure></figure>
                    </div>
                    <div className={convergenceVenueId == null ? "item voucher" : "item voucher hidden"} onClick={this.enterVoucher}>
                        <div className="action"><I18n $="kiosk.action.voucher" /></div>
                        <i className="icon">{voucherSvg}</i>
                    </div>
                    <div className="item search" onClick={this.props.enterKiosk}>
                        <div className="action"><I18n $="kiosk.action.search" /></div>
                        <i className="icon">{searchSvg}</i>
                    </div>
                </div>
            </section>
        )
    }
}