import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects'
import { createModule } from '@ifly/redux-modules'
import MediaApi from 'api/MediaApi'
import Recaptcha from 'api/Recaptcha'

const ANIMATION_DURATION = 2000;

const Module = createModule({
    name: 'scanner',
    
    initialState: {
        wristband: null,
        wristbandCode: null,
        isLoadingWristband: false,

        voucher: null,
        vouchers: null,
        voucherCode: null,
        isLoadingVoucher: false,
        isDuplicateVoucher: false
    },
    
    actions: {
        clearScanner: { reducer:false, dispatch:true },                            // reduced by saga
        scannerReset: {},
        
        applyVoucher: { args:['voucherCode', 'isConvergenceOrder'], reducer:false, dispatch:true },      // reduced by saga
        resetVoucher: { reducer:false, dispatch:true },                            // reduced by saga
        loadingVoucher: {},
        receiveVoucher: {},
        failureVoucher: {},
        
        resetWristband: { reducer:false, dispatch:true },                          // reduced by saga
        lookupWristband: { args:['wristbandCode'], reducer:false, dispatch:true }, // reduced by saga
        loadingWristband: {},
        receiveWristband: {},
        failureWristband: {}
    },

    selector: {
        vouchers: state => state.scanner.vouchers,
        wristbandFlyer: state => state.scanner.wristband?.flyer
    }
       
});

export default Module;

export class ScannerSaga {

    static* watcher() {
        yield all([
            takeLatest(Module.constants.clearScanner, ScannerSaga.resetScanner),
            takeLatest(Module.constants.applyVoucher, ScannerSaga.redeemVoucher),
            takeLatest(Module.constants.resetVoucher, ScannerSaga.clearVoucher),
            takeLatest(Module.constants.resetWristband, ScannerSaga.clearWristband),
            takeLatest(Module.constants.lookupWristband, ScannerSaga.checkWristband)
        ]);
    }
    
    static* resetScanner(action) {
        yield put(Module.actions.scannerReset({ ...Module.initialState }));
    }
    
    static* clearVoucher(action) {
        yield put(Module.actions.receiveVoucher({
            voucher: null,
            voucherCode: null,
            isLoadingVoucher: false,
            isDuplicateVoucher: false
        }));
    }
    
    static* clearWristband(action) {
        yield put(Module.actions.receiveWristband({
            wristband: null,
            wristbandCode: null,
            isLoadingWristband: false
        }));
    }
    
    /**
     * Processes the scanned voucher.
     * @param {Object} action The action being dispatched
     */
    static* redeemVoucher(action) {
        let { voucherCode } = action
		let isLoadingVoucher = true;
        
        yield put(Module.actions.loadingVoucher({ voucherCode, isLoadingVoucher }));
        isLoadingVoucher = false;
        
        let scanner = yield select(Module.selector.default),
            vouchers = scanner.vouchers ? [ ...scanner.vouchers ] : [];
        
        //
        //    vouchers = [{
        //        voucherCode: '13RV37',
        //        mediaType: 'PHOTO',
        //        qty: 2
        //    },{
        //        voucherCode: '1VKTYD',
        //        mediaType: 'VIDEO',
        //        qty: 1
        //    }]
        //
        
		
        if (vouchers.find(v => v.voucherCode===voucherCode)) {
			// voucher has already been applied to this order
            yield put(Module.actions.failureVoucher({ isDuplicateVoucher:true, voucherCode:null, isLoadingVoucher }));
        } else {
            let recaptcha = yield call(Recaptcha.getToken);
			let data = { voucherCode, recaptcha };
			let response;

            response = yield call(MediaApi.post, '/api/Order/Voucher', data);
		
			if (!response) {
				voucherCode = null;
				yield put(Module.actions.failureVoucher({ voucherCode, isLoadingVoucher }));
				return;
			}
			
			const voucher = response.data;
			
			// Handle Siriusware vouchers
			if (response) {
                if (voucher?.success) {
                    // save the voucher information
                    let { mediaType, qty } = voucher;
                    vouchers.push({ voucherCode, mediaType, qty });
                }

                yield put(Module.actions.receiveVoucher({ voucher, vouchers, isLoadingVoucher }));
			}
			
			//// Handle Convergence vouchers
			//if (response && isConvergenceOrder) {
			//	if (voucher.valid === false) {
			//		yield put(Module.actions.failureVoucher({ voucherCode, isLoadingVoucher }));
			//		return;
			//	}
				
			//	// Fail if the entered voucher does not cover PHOTO or VIDEO
			//	// (for example, it may be a voucher for flights)
			//	if (voucher.share_code !== 'PHOTO' && voucher.share_code !== 'VIDEO') {
			//		yield put(Module.actions.failureVoucher({ voucherCode, isLoadingVoucher }));
			//		return;
			//	}
				
			//	let voucherData = {
			//		success: true,
			//		message: "Voucher applied!",
			//		voucherCode: voucherCode,
			//		mediaType: voucher.share_code,
			//		qty: 1
			//	}
				
			//	vouchers.push(voucherData);
			//	yield put(Module.actions.receiveVoucher({ voucher: voucherData, vouchers, isLoadingVoucher }));
   //         }
        }
    }

    /**
     * Processes the scanned wristband.
     * @param {Object} action The action being dispatched
     */
    static* checkWristband(action) {
        let ts = new Date().getTime(),
            isLoadingWristband = true,
            wristband = null,
            { wristbandCode } = action;

        yield put(Module.actions.loadingWristband({ wristbandCode, wristband, isLoadingWristband }));
        
        let recaptcha = yield call(Recaptcha.getToken),
            data = { wristbandCode, recaptcha },
            response = yield call(MediaApi.post, '/api/Guests/Wristband', data);
        
        isLoadingWristband = false;
        
        if (response) {
            let wait = ANIMATION_DURATION - (new Date().getTime() - ts);
            if (wait > 0) yield delay(wait);
            wristband = response.data;
            
            if (wristband?.flyer) {
                let flyer = wristband.flyer,
                    phone = flyer.phone;

                // phone field requires `+` for country code; TODO: fix international
                if (/^1/.test(phone)) flyer.phone = `+${phone}`; else
                if (/^\d/.test(phone)) flyer.phone = `+1${phone}`;
                
                if (flyer.state && !flyer.country) {
                    // country must be set in order for state to be set
                    flyer.country = 'US';
                }
            }
            
            yield put(Module.actions.receiveWristband({ wristband, isLoadingWristband }));
        } else {
            // server error
            wristbandCode = null;
            yield put(Module.actions.failureWristband({ wristbandCode, isLoadingWristband }));
        }
    }
}