import React, { PureComponent } from 'react'
import '../../styles/media/components/misc/Loading.scss'

const instances = [];

/**
 * Loading component displays an animation. This component only allows a 
 * single instance to be displayed at any time.
 */
export default class Loading extends PureComponent {
    
    componentDidMount() {
        instances.push(this);
        this.forceUpdate();
    }
    
    componentWillUnmount() {
        instances.splice(instances.indexOf(this), 1);
        instances.forEach(instance => instance.forceUpdate());
    }
    
    render() {
        let hide = !instances.length || this!==instances[0];  
        return hide ? null :
            <div className="loading-ifly">
                <div className="wrapper">
                    <div className="mask"/>
                    <div className="flyer"/>
                </div>
            </div>
    }
}