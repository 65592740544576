import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { IFlyLogin } from '@ifly/ifly-web-login'

// Application Styles
import 'styles/core.scss'

import I18n from 'locale/I18n'
import { Modal } from 'components/common/Modal'
import CommonUtil from 'util/CommonUtil'
import TunnelUtil, { GEOSOURCE_TUNNEL, GEOSOURCE_IPINFO, GEOSOURCE_DEVICE, GEOSOURCE_ERROR } from 'util/TunnelUtil'

import LoginForm from 'components/view/LoginForm'
import HeaderTopBar from 'components/header/HeaderTopBar'
import HeaderBrandBar from 'components/header/HeaderBrandBar'
import HeaderNavigation from 'components/header/HeaderNavigation'
import Environment from '@ifly/common-env'

let LoginClient = new IFlyLogin({ identityHost: Environment.identityServer() });

// Body css class applied to when the (mobile) navigation menu is opened.
const OPEN_NAV_MENU = 'open-nav-menu';

// Body css class applied when the main page has been scrolled
const SCROLLED = 'scrolled';

const i18n_copyright = <I18n $="common.copyright"/>

// Initial component state
const initialState = {
    isBodyScrolled: false,
    closingNavigation: null,
    isLoginOpen: false,
    isNavigationOpen: false,
    user: null,
    geolocation: null,
    selectedTunnel: null,
    nearbyTunnels: null
};

export default class Header extends Component {
    
    state = initialState

    /**
     * Opens the login modal window.
     */
    openLoginModal = evt => {
        LoginClient.removeSession();
        this.setState({ isLoginOpen:true });
        evt.preventDefault();
        evt.stopPropagation();
    }
    
    /**
     * Closes the login modal window.
     */
    closeLoginModal = () => {
        this.setState({ isLoginOpen:false });
    }
    
    /**     
     * Resets the navigation to its initial (closed) state.
     */
    closeNavigation = () => this.setState({
        isNavigationOpen: false,
        closingNavigation: null
    });
    
    /**
     * Click handler opens and closes the primary navigation menu.
     * @param {Event} evt The browser event.
     */
    toggleNavigation = evt => {
        let closingNavigation = this.state.closingNavigation;
        
        if (closingNavigation) {
            clearTimeout(closingNavigation);
            this.setState({
                isNavigationOpen: true,
                closingNavigation: null
            });
            // user quickly re-opened the toggle
        } else if (this.state.isNavigationOpen) {
            this.setState({
                closingNavigation: setTimeout(_ => this.closeNavigation(), 300)
            });
            // user is closing the toggle ;; a transient closingNavigation state is
            // removed when the animation completes
            
        } else {
            this.setState({ isNavigationOpen: true });
            // user is opening the toggle
        }
    }

    /**
     * Adds a css class to the body when the state `isNavigationOpen` is true,
     * or when `isBodyScrolled` is true.  Once the `tunnels` prop is first set 
     * calculate the closest tunnel (if geolocation resolved).
     */
    componentDidUpdate(prevProps, prevState) {
        let { tunnels } = this.props;
        let { geolocation, nearbyTunnels } = this.state;

        if (geolocation && tunnels && !nearbyTunnels && 
            (tunnels!==prevProps.tunnels || geolocation!==prevProps.geolocation)) {
            nearbyTunnels = TunnelUtil.getClosestTunnels(geolocation, tunnels);
            this.setState({ nearbyTunnels });
            if (nearbyTunnels.length) this.props.onChangeLocation(nearbyTunnels[0].code);
        }

        let { isNavigationOpen } = this.state;
        if (isNavigationOpen !== prevState.isNavigationOpen) {
            if (isNavigationOpen) {
                CommonUtil.addBodyCls(OPEN_NAV_MENU);
            } else {
                CommonUtil.removeBodyCls(OPEN_NAV_MENU);
            }
        }

        let { isBodyScrolled } = this.state;
        if (isBodyScrolled !== prevState.isBodyScrolled) {
            if (isBodyScrolled) {
                CommonUtil.addBodyCls(SCROLLED);
            } else {
                CommonUtil.removeBodyCls(SCROLLED);
            }
        }
    }

    /**
     * Handler called when the user changes their nearby tunnel.
     * @param {String} code The selected tunnel code.
     */
    onChangeTunnel = code => {
        TunnelUtil.saveSelectedTunnel(code);
        this.onGeolocate({ source:GEOSOURCE_TUNNEL, code });
    }
    
    /**
     * Callback invoked when various geolocation services return with information.
     * @param {Object} loc The result of the geolocation.
     * @param {Object} loc.source The api source.
     * @param {Object} loc.coords The position returned from the api.
     */
    onGeolocate = loc => {
        let { geolocation } = this.state,
            src = geolocation ? geolocation.source : null;

        if (loc.source===GEOSOURCE_ERROR) {
            console.log('Geolocation Error -- ', loc.error);
        } else if (
               (loc.source===GEOSOURCE_TUNNEL)
            || (loc.source===GEOSOURCE_DEVICE && src!==GEOSOURCE_TUNNEL)
            || (loc.source===GEOSOURCE_IPINFO && src!==GEOSOURCE_DEVICE)
        ) {
            let geolocation = { ...loc },
                newState = { geolocation }; 
            
            if (this.props.tunnels) {
                newState.nearbyTunnels = TunnelUtil.getClosestTunnels(geolocation, this.state.tunnels)
            }
            
            if (loc.source===GEOSOURCE_TUNNEL) {
                newState.selectedTunnel = loc.code;
                this.props.onChangeLocation(loc.code);
                // ex: { source:'tunnel', code:'AUS' }
            } else if (newState.nearbyTunnels?.length) {
                this.props.onChangeLocation(newState.selectedTunnel[0].code);
            }

            this.setState({ ...newState });
            TunnelUtil.saveGeolocation(geolocation);
        } 
    }
    
    /**
     * Makes request(s) to the geolocation services to resolve the visitor's 
     * latitude and longitude.
     */
    startGeolocation = () => {
        let { geolocation } = this.state;
        if (!geolocation) {
            TunnelUtil.geolocateVisitor(this.onGeolocate);
        }
    }
    
    /**
     * When the header first mounts this method adds a window scroll listener,
     * loads the available tunnels, requests the visitor's geolocation, and 
     * attempts to re-establish any existing account login.
     */
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        
        this.startGeolocation();
        
        if (LoginClient.isLoggedIn()) {
            this.setState({ user: LoginClient.getDecoded() });
        }
    }

    /**
     * Removes the window scroll listener when the header unmounts.
     */
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    /**
     * Adds a body scroll class when `isBodyScrolled` is true.
     */
    handleScroll = evt => {
        let isBodyScrolled = !!window.document.documentElement.scrollTop;

        if (isBodyScrolled !== this.state.isBodyScrolled) {
            this.setState({ isBodyScrolled });
        }
    }
    
    /**
     * LoginForm callback to establish the user state after a successful login.
     */
    onLogin = () => {
        this.setState({
            isLoginOpen: false,
            user: LoginClient.getDecoded()
        });
    }

    /**
     * Logs out the currently logged in user.
     */
    logout = evt => {
        evt.preventDefault();
        evt.stopPropagation();
        LoginClient.logout({ postLogoutRedirectUri: 'https://' + window.location.hostname });
        this.setState({ user: null });
    }

    render() {
        let { isLoginOpen, isNavigationOpen, closingNavigation } = this.state;
        
        return <>
            { isLoginOpen &&
                <Modal onClose={this.closeLoginModal}>
                    <LoginForm client={LoginClient} afterLogin={this.onLogin} />
                </Modal>
            }
            <header>
                {/*
                    Visible only on larger devices, this bar at the very top of the
                    page includes closest tunnel and a dropdown to change language.
                    On smaller devices the bar is a shrunk down to a black border.
                */}
                <HeaderTopBar tunnels={this.props.tunnels}
                              selectedTunnel={this.state.selectedTunnel}
                              nearbyTunnels={this.state.nearbyTunnels}
                              onChangeTunnel={this.onChangeTunnel}
                />

                <div className="main">
                    {/*
                        Visible on all devices (from 60-80px) the brand + book now bar 
                        may also host the hamburger menu on tablet or mobile devices, 
                        or top-level navigation and user/cart glyphs on desktop.
                    */}
                    <HeaderBrandBar user={this.state.user}
                                    logout={this.logout}
                                    isNavigationOpen={isNavigationOpen}
                                    openLoginModal={this.openLoginModal}
                                    toggleNavigation={this.toggleNavigation} />

                    {/*
                        Navigation is visible on desktop devices adjacent the logo,
                        or revealed on mobile devices by tapping the menu glyph.
                    */}
                    <nav className={(isNavigationOpen && (closingNavigation ? 'open closing' : 'open')) || null}>
                        <HeaderNavigation user={this.state.user}
                                          nearbyTunnels={this.state.nearbyTunnels}
                                          selectedTunnel={this.state.selectedTunnel}
                                          logout={this.logout}
                                          openLoginModal={this.openLoginModal}
                                          closeNavigation={this.closeNavigation} />
                        
                        <div className="nav-main _on S M L">
                            <div className="copyright">{ i18n_copyright }</div>
                        </div>

                        <div className="anchor"></div>
                    </nav>
                    <div className="nav-mask"></div>

                </div>
            </header>
        </>
    }
    
    static propTypes = {
        onChangeLocation: PropTypes.func.isRequired
    }

    static defaultProps = {
        onChangeLocation: loc => console.log('Change location: ', loc)
    }
}
