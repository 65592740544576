import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import I18n, { i18n, localize } from 'locale/I18n'
import Svg from 'components/common/Svg'
import Path from 'util/PathUtil';
import KioskKeyboard, { addKeyboard } from '@ifly/react-kiosk-keyboard'

const FORM_ID = "convergence-enter-voucher";

addKeyboard('kiosk-voucher', [
    '1 2 3 4 5 6 7 8 9 0',
    'Q W E R T Y U I O P',
    'A S D F G H J K L',
    'Z X C V B N M . - {bksp}',
    '{space}'
]);

class ConvergenceVoucher extends PureComponent {

    state = {
        voucherCode: ''
    }

    constructor(props) {
        super(props);
        this.inputEl = React.createRef();
    }

    onChange = evt => this.setState({ voucherCode: evt.target.value.toUpperCase() })

    applyVoucher = evt => {
        let el = this.inputEl.current,
            code = el.value.replace(/ /, '');
        
        if (code) {
            this.props.onSubmit(code, Path.START === this.props['*']);
        } else {
            el.focus();
        }
    }

    componentWillUnmount = () => {
        window.document.body.classList.remove(FORM_ID);
    }

    componentDidMount = () => {
        // hide the shopping cart navigation glyph
        window.document.body.classList.add(FORM_ID);
        this.inputEl.current.focus();
    }

    render() {
        return (
            <div className="kiosk-voucher">
                <div className="view-head">
                    <I18n $="prompt.scan_or_enter_a_voucher_code" />
                </div>
                <i className="icon"><Svg id="voucher" /></i>
                <div class="voucher-info-text">If you have pre-purchased a voucher, check your original purchase receipt email for the voucher code.</div>
                <form id={FORM_ID}>
                    <input data-input-type="kiosk-voucher"
                        ref={this.inputEl}
                        name="voucherCode"
                        maxLength={20}
                        value={this.state.voucherCode}
                        placeholder={i18n('media.voucher_code')}
                        onChange={this.onChange} />
                    <button type="button" className="btn-primary" onClick={this.applyVoucher}>
                        <I18n $="button.apply" iFlyUpperCase />
                    </button>
                </form>
                <KioskKeyboard formId={FORM_ID} containerSelector=".kiosk-voucher" />
            </div>
        )
    }

    static propTypes = {
        onSubmit: PropTypes.func.isRequired
    }
}

export default localize(ConvergenceVoucher);