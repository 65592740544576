import React, { PureComponent } from 'react';
import PropTypes from 'prop-types'
import MediaPropTypes from 'util/MediaPropTypes'
import { Router, navigate } from '@reach/router'
import { shoppingCart } from 'cart'
import I18n from 'locale/I18n'

import { mediaSettings } from 'util/MediaContext'
import Path from 'util/PathUtil'
import MediaRedirect from 'components/MediaRedirect'
import Loading from 'components/misc/Loading'
import LoadingRoller from 'components/misc/LoadingRoller'

import StartPage from './StartPage'
import BarcodeScanner from './BarcodeScanner'
import KioskView from './KioskView'
import OrderDone from './OrderDone'
import Alert, { Modal } from 'components/misc/Prompt'
import MediaUtil from 'util/MediaUtil'

import scanner from 'redux/modules/scanner'
import Environment from '@ifly/common-env'

const env = Environment.selected();

const initialState = {
    historyBackPending: false,
    voucherRedeemFailure: null,
    wristbandScanFailure: false,
    wristbandScanSuccess: false
};

class KioskLayout extends PureComponent {    
    state = { ...initialState }

    tunnelUrl = () => `/${Path.KIOSK}/${this.props.sessionTunnel.code}` 
    
    navigateToday = () => navigate(`${this.tunnelUrl()}/${Path.datePath()}`)

    onKioskReset = () => {
        this.props.clearCart();
        this.props.clearSession();
        this.props.actions.clearScanner();
        this.setState({ ...initialState });
    }

    convergenceVenueId = () => {
        this.setState({ convergenceVenueId: this.props.sessionTunnel.convergenceVenueId });
    }

    applyVoucherCode = (voucherCode, isStartPage, goTo=false) => {
		let { isLoadingWristband, isLoadingVoucher } = this.props;
        if (isLoadingWristband || isLoadingVoucher) return;

        let params = new URLSearchParams(window.location.search);

        if (goTo) {
            navigate(goTo);
        } else if (params.has('return')) {
            navigate(`${window.location.origin}${params.get('return')}`);
        } else {
            this.setState({ historyBackPending: true });
        }

        let isConvergenceOrder = (this.props.sessionTunnel.convergenceVenueId != null);

		this.setState({ voucherRedeemFailure: null });
        this.props.actions.applyVoucher(voucherCode, isConvergenceOrder);
	}
    
    onScanWristband = wristbandCode => {
        
        let { isLoadingWristband, isLoadingVoucher } = this.props;
        if (isLoadingWristband || isLoadingVoucher) return;
        
        this.setState({ 
            wristbandScanFailure: false, 
            wristbandScanSuccess: false
        });
        this.props.actions.lookupWristband(wristbandCode);
    }
    
    onLookupWristband = () => {
        let { wristband, wristbandCode } = this.props;

        if (wristband) {
            // show a success message
            this.setState({ wristbandScanSuccess: true });
            
            // wait a moment, clear message, then maybe navigate
            setTimeout(() => {
                this.setState({ wristbandScanSuccess: false });

                if (!Path.CHECKOUT_URL.test(window.location.pathname)) {
                    // navigate to the wristband session
                    let { year, month, day } = MediaUtil.parseISODate(wristband.date),
                        date = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`,
                        url = `${this.tunnelUrl()}/${Path.DATE}/${date}/${Path.GUEST}/${wristband.guestId}`;
                    // TODO: parsing a session can fail even when a flyer is resolved
                    navigate(url);
                }
            }, 2000);

        } else if (wristbandCode) {
            this.setState({ wristbandScanFailure: true });
        }
    }

    onCloseWristbandScanFailurePrompt = () => {
        this.props.actions.resetWristband();
        this.setState({wristbandScanFailure: false});
    }

    onCloseRedeemVoucherFailurePrompt = () => {
        let historyBackPending = false,
            voucherRedeemFailure = false;
            
        this.props.actions.resetVoucher();
        this.setState({ voucherRedeemFailure, historyBackPending });
    }

    componentDidUpdate(prevProps) {
        let { isLoadingWristband, isLoadingVoucher } = this.props;
        
        if (!isLoadingWristband && isLoadingWristband !== prevProps.isLoadingWristband) {
            this.onLookupWristband();
        }

        if (!isLoadingVoucher && isLoadingVoucher !== prevProps.isLoadingVoucher) {
            let { voucherCode, voucher } = this.props;

            if (voucherCode && voucher?.success) {
                if (this.state.historyBackPending) {
                    window.history.back();
                    this.setState({ historyBackPending: false });
                }
            } else {
                this.setState({ voucherRedeemFailure: true });
            }
        }
    }
    
    getScannerMessage = () => {
        if (this.props.isLoadingWristband) {
            return <I18n $="media.checking_wristband" iFlyUpperCase />
        } else if (this.state.wristbandScanSuccess) {
            return <I18n $="media.session_found" iFlyUpperCase />
        } else if (this.props.isLoadingVoucher) {
            return <I18n $="media.redeeming_voucher" iFlyUpperCase />
        }
    }
    
    render() {
        let { sessionTunnel, isLoadingVoucher, voucher, vouchers, voucherCode } = this.props;
        if (!sessionTunnel) return <Loading/>
        let scannerMessage = this.getScannerMessage();

        return <>
            {
                // Debug Voucher / Wristband Scanner
                env!==Environment.PRODUCTION &&
                    <div className="scan-debug">
                        <style type="text/css" dangerouslySetInnerHTML={{__html: `
                            .scan-debug { position: absolute; top:7px; left:295px; z-index:10; }
                            .scan-debug input, .scan-debug button { padding:5px; border:1px solid #999; }
                            .scan-debug button { width:130px; padding-bottom:4px; }
                            .scan-debug .fld { margin-top:5px; }       
                            }
                        `}}/>
                        <div className="fld">
                            <input size={6} id="vc" defaultValue="1V2LEL"/>{' '}
                            <button onClick={evt => {
                                evt.stopPropagation();
                                this.applyVoucherCode(document.getElementById('vc').value);
                            }}>
                                Scan Voucher
                            </button>
                        </div>
                        <div className="fld">
                            <input size={6} id="wb" defaultValue="164ZQ5" />{' '}
                            <button onClick={evt => {evt.stopPropagation(); this.onScanWristband(document.getElementById('wb').value)}}>
                                Scan Wristband
                            </button>
                        </div>
                    </div>
            }
            
            {
                this.state.wristbandScanFailure &&
                <Alert onClose={this.onCloseWristbandScanFailurePrompt}>
                    <I18n $="media.alert.wristband_lookup_failed" /><br/>
                    <I18n $="media.alert.try_manual_session_lookup" />
                </Alert>
            }

            {
                this.state.voucherRedeemFailure &&
                <Alert onClose={this.onCloseRedeemVoucherFailurePrompt}>
                    {
                        voucher?.message ?? <I18n $="error.server" />
                    }
                </Alert>
            }
            
            {
                this.props.isDuplicateVoucher &&
                <Alert onClose={this.props.actions.resetVoucher}>
                    <I18n $="media.duplicate_voucher" />
                </Alert>
            }
            
            {
                scannerMessage &&
                <Modal>
                    <div className="scanner-message">
                        <LoadingRoller />
                        <div className="label">{ scannerMessage }</div>
                    </div>
                </Modal>
            }

            <BarcodeScanner onScanVoucher={this.applyVoucherCode} onScanWristband={this.onScanWristband} />
            
            <main className="kiosk">
                <Router primary={false}>
                    <StartPage path={`${Path.KIOSK}/:kioskTunnelCode/${Path.START}`}
                        enterKiosk={this.navigateToday}
                        resetKiosk={this.onKioskReset}
                        convergenceVenueId={this.props.sessionTunnel.convergenceVenueId}
                    />
                    <OrderDone path={`${Path.KIOSK}/:kioskTunnelCode/${Path.DONE}`} />
                    <KioskView path={`${Path.KIOSK}/:kioskTunnelCode`}
                               vouchers={vouchers}
                               voucherCode={voucherCode}
                               onSubmitVoucher={this.applyVoucherCode}
                               isLoadingVoucher={isLoadingVoucher}
                               sessionTunnel={sessionTunnel}>
                        <MediaRedirect path="." to="start" note="KioskLayout redirect kiosk/TUNNEL to StartPage"/>
                    </KioskView>
                    <MediaRedirect default to={`/${Path.KIOSK}/${sessionTunnel.code}/${Path.START}`} note="KioskLayout redirect(/) to KioskView"/>
                </Router>
            </main>
        </>
    }
    
    static propTypes = {
        // passed props
        clearSession: PropTypes.func.isRequired,

        // mediaSettings
        sessionTunnel: MediaPropTypes.sessionTunnel,
        
        // redux -- scanner.connect()
        wristband: MediaPropTypes.wristband,
        wristbandCode: PropTypes.string,
        isLoadingWristband: PropTypes.bool,

        voucher: PropTypes.shape({
            success: PropTypes.bool,
            message: PropTypes.string,
            mediaType: PropTypes.string
        }),

        vouchers: MediaPropTypes.vouchers,

        voucherCode: PropTypes.string,
        isLoadingVoucher: PropTypes.bool,
        isDuplicateVoucher: PropTypes.bool,
        
        actions: PropTypes.shape({
            applyVoucher: PropTypes.func.isRequired,
            resetVoucher: PropTypes.func.isRequired,
            resetWristband: PropTypes.func.isRequired,
            lookupWristband: PropTypes.func.isRequired,
            clearScanner: PropTypes.func.isRequired
        }).isRequired
    }
}

export default shoppingCart(mediaSettings(scanner.connect(KioskLayout)));