import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import MediaPropTypes from 'util/MediaPropTypes'
import { navigate } from '@reach/router'
import KioskNavigation from './KioskNavigation'
import Storefront from 'components/Storefront'
import Path from 'util/PathUtil'
import I18n from 'locale/I18n'
import { Prompt } from 'components/misc/Prompt'
import KioskCheckout from './KioskCheckout'
import KioskVoucher from './KioskVoucher'
import TunnelContext from 'util/TunnelContext'
import ConvergenceVoucher from '../convergence/ConvergenceVoucher';

// Allowed seconds of inactivity before session ends.
const TIMEOUT = 130;

// Seconds of remaining inactivity before warning user.
const WARNING_TIME = 10;

export default class KioskView extends PureComponent {
    static contextType = TunnelContext;
	
    state = {
        intervalId: null,
        inactivityTimer: TIMEOUT
    }
    
    onInteraction = evt => {
        this.setState({ inactivityTimer:TIMEOUT });
    }

    checkInactivity = () => {
        let { inactivityTimer } = this.state;
        
        if (inactivityTimer > 0) {
            --inactivityTimer;
            this.setState({ inactivityTimer });
        } else {
            navigate('/');
        }
    }
    
    componentDidUpdate(prevProps) {
        let { isLoadingVoucher } = this.props;
        
        if (isLoadingVoucher !== prevProps.isLoadingVoucher) {
            this.onInteraction(); // scanning counts as interaction
        }
    }
    
    componentDidMount() {
        window.addEventListener('pointerdown', this.onInteraction);
        let intervalId = setInterval(this.checkInactivity, 1000);
        this.setState({ intervalId });
    }

    componentWillUnmount() {
        window.removeEventListener('pointerdown', this.onInteraction);
        clearInterval(this.state.intervalId);
    }

    render() {
        let { inactivityTimer } = this.state;
        let { uri, '*':wildcard } = this.props,
            path = wildcard ? `${uri}/${wildcard}` : uri,
            isFlyerSearch = Path.DATE_URL.test(path) && path === Path.cleanDateUrl(path);

        return (
            <div className="kiosk-view">
                {
                    (inactivityTimer <= WARNING_TIME) &&
                    <Prompt hideCloseButton>
                        <I18n $="kiosk.prompt.are_you_there" /><br/>
                        <I18n $="kiosk.prompt.session_about_to_expire" />
                        <div className="timer">{ inactivityTimer }</div>
                        <I18n $="kiosk.prompt.tap_screen_to_continue" />
                    </Prompt>
                        
                }
                <KioskNavigation back={!isFlyerSearch} 
                                 voucherCode={this.props.voucherCode} 
                                 vouchers={this.props.vouchers}
                                 sessionTunnel={this.props.sessionTunnel} />
                                 
                <Storefront className="kiosk-body" tunnel={this.context.tunnel} isKiosk="true">
                    <KioskVoucher path={`${Path.VOUCHER}/*`} onSubmit={this.props.onSubmitVoucher} />
                    <ConvergenceVoucher path={`${Path.CONVERGENCE_VOUCHER}/*`} onSubmit={this.props.onSubmitVoucher} />
                    <KioskCheckout path={Path.CHECKOUT} />
                </Storefront>
                
                { this.props.children }
            </div>
        )
    }
    
    static propTypes = {
        isLoadingVoucher: PropTypes.bool,
        onSubmitVoucher: PropTypes.func,
        voucherCode: PropTypes.string,
        vouchers: MediaPropTypes.vouchers
    }
}