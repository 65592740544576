import React from 'react'
import PropTypes from 'prop-types'
import MediaPropTypes from 'util/MediaPropTypes'
import I18n from 'locale/I18n'

const WebVideo = React.memo(function WebVideo(props) {
    let { video, preload } = props;

    /// The `controls` property is applied programmatically to fix a chrome bug
    /// where the video controls do not span the width of the video until focus.
    /// Applying the `controls` attribute after the animation of the carousel
    /// slide is complete prevents slide misalignment.

    return (
        <div className="video-wrap">
            <video playsInline
                   controls={preload}
                   controlsList="nodownload"
                   disablePictureInPicture
                   poster={video.thumbnailUrl}
                   preload={preload ? 'auto' : 'none'}>
                <source src={video.mediaUrl} type="video/mp4"/>
            </video>
            <div className="video-disclaimer">
                <I18n $="media.sample_video_disclaimer" />
                <br/>
                <I18n $="media.purchased_video_info" />
            </div>
        </div>
    )
});  

WebVideo.propTypes = {
    // passed props
    preload: PropTypes.bool.isRequired,
    video: MediaPropTypes.video.isRequired
}

export default WebVideo;