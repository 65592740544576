import memoize from 'memoize-one'

/// Application Utilities

// very relaxed ISO date regex
const ISO_DATE = /^(\d+\D*){3,}$/;

// Returns a map of an ISO Date string (ex: 2020-02-24T17:15:02.316Z) with
// keys: year, month, day, hours, minutes, seconds, and milliseconds.
//
function parseISODate(dateISOString) {
    let date = ISO_DATE.test(dateISOString) ? dateISOString.split(/\D/) : []; 
    date.forEach((val, i) => date[i]=parseInt(val, 10));
    let [ year, month, day, hours, minutes, seconds, milliseconds ] = date;
    return { year, month, day, hours, minutes, seconds, milliseconds };
}

// Sorts an array of media by `rankValue` descending. 
function sortMediaByRankValue({rankValue: r1=0}, {rankValue: r2=0}) {
    if (r1 < r2) return 1;
    if (r1 > r2) return -1;
    return 0;
}

function scrollViewportTop(isKiosk) {
    let sel = isKiosk ? '.kiosk-body' : 'html';
    document.querySelector(sel).scrollTop = 0;

}

// Gets the videos from an array of media.
// @see MediaPropTypes.media for complete structure
const filterVideos = memoize( media => media.filter(m => m.mediaType==='VIDEO') );

// Gets the photos from an array of media 
// @see MediaPropTypes.media for complete structure
const filterPhotos = memoize( media => media.filter(m => m.mediaType==='PHOTO') );

export default {
    filterVideos,
    filterPhotos,
    parseISODate,
    scrollViewportTop,
    sortMediaByRankValue
}