import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Router, Link } from '@reach/router'
import I18n from 'locale/I18n'
import { mediaSettings } from 'util/MediaContext'
import MediaUtil from 'util/MediaUtil'
import Path from 'util/PathUtil'
import Calendar from './Calendar'
import MediaRedirect from 'components/MediaRedirect'
import SearchByTime from 'components/search/SearchByTime'
import SearchByName from 'components/search/SearchByName'

const i18n_search_by_time = <I18n $="button.search_by_time" iFlyUpperCase />
const i18n_search_by_name = <I18n $="button.search_by_name" iFlyUpperCase />

class FlyerSearch extends PureComponent {
    
    onChangeDay = date => this.props.navigate(Path.replaceDateUrl(this.props.uri, date))

    componentDidMount() {
        MediaUtil.scrollViewportTop(this.props.isKiosk);
    }

    render() {
        let now = new Date();
        let today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        let searchByName = Path.SEARCH===this.props.wildcard;
        
        return (
            <div className="flyer-search">
                <div className="view-head">
                    {
                        searchByName
                            ? <I18n $="prompt.select_session_date_and_guest" /> 
                            : <I18n $="prompt.select_session_date_and_time" /> 
                    }
                </div>
                <div className="search-body">
                    <div className="calendar">
                        <Link to={searchByName ? '..' : Path.SEARCH} className="btn-secondary">
                            <span className="btn-icon">
                                { searchByName ? i18n_search_by_time : i18n_search_by_name }
                            </span>
                        </Link>
                        <Calendar today={today}
                                  selectedDay={Path.parseDate(this.props.dateSlug)}
                                  onChangeDay={this.onChangeDay}
                        />
                    </div>
                    <div className="refine">
                        <Router primary={false}>
                            <SearchByTime path="/" />
                            <SearchByName path={Path.SEARCH} />
                            <MediaRedirect default fn={Path.cleanDateUrl} note=":cleanDateUrl for SearchByTime" />
                        </Router>
                    </div>
                </div>
            </div>
        )
    }
    
    static propTypes = {
        // mediaSettings
        isKiosk: PropTypes.bool,

        // reach-router
        uri: PropTypes.string,
        navigate: PropTypes.func,
        wildcard: PropTypes.string.isRequired,
        dateSlug: PropTypes.string.isRequired
    }
}

export default mediaSettings(FlyerSearch)