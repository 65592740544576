import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import MediaPropTypes from 'util/MediaPropTypes'
import { navigate } from '@reach/router'
import { CartContext } from 'cart'
import TunnelContext from 'util/TunnelContext'

import I18n from 'locale/I18n'
import Alert from 'components/misc/Prompt'
import Loading from 'components/misc/Loading'
import cart from 'redux/modules/cart'
import Path from 'util/PathUtil'
import { mediaSettings } from 'util/MediaContext'

class ShoppingCart extends PureComponent {
    static contextType = TunnelContext;

    addToCart = items => {
        if (!items) return;
        items = Array.isArray(items) ? items : [items];
        
        let newItems = [],
            { cartContext } = this.state,
            { cartLimit } = cartContext,
            sessionId = items[0].sessionId,
            cartItems = cartContext.cartItems          // filter forces shopping within
                .filter(i => i.sessionId===sessionId); // same session (api2 restriction)
        
        for (let item of items) {
            if (cartItems.length + newItems.length >= cartLimit) {
                this.setState({ showCartLimitModal:true });
                break;
            }

            if (!cartItems.find(existingItem => existingItem.id===item.id)) {
                newItems.push(item);
            }
        }

        newItems = cartItems.concat(newItems);
        
        if (newItems.length !== cartItems.length) {
            this.props.actions.shoppingCartAdd(newItems, 'add-to-cart', items);
        }
    }
    
    removeFromCart = items => {
        if (!items) return;

        let ids = [];
        for (let { id } of Array.isArray(items) ? items : [items]) {
            ids.push(id);
        }

        let { cartItems } = this.state.cartContext;

        let newItems = cartItems.filter(item => !ids.includes(item.id));

        if (newItems.length !== cartItems.length) {
            this.props.actions.shoppingCartRemove(newItems, 'remove-from-cart', items);
        }
    }

    clearCart = () => {
        this.props.actions.shoppingCartClear();
    }
    
    viewCart = evt => {
        evt.preventDefault();
        evt.stopPropagation();
        
        if (!this.state.cartContext.cartItems.length) {
            this.setState({ showEmptyCartModal:true });
            return;
        }
        
        navigate(Path.cleanCartUrl());
    }
    
    setHeaderButton = (el, cartContext) => {
        let { cartItems, isKiosk } = this.props,
            className = isKiosk ? 'header-button btn-primary' : 'book btn',
            headerButton = false;

        cartContext = cartContext || { ...this.state.cartContext };
        
        if (el===undefined) {
                // VIEW CART (default when cart has items)
                headerButton =
                    <button onClick={cartContext.viewCart} className={`${className} ${cartItems?.length > 0 ? 'btn-active' : 'btn-inactive'}`}>
                        <I18n $="button.view_cart" iFlyUpperCase />
                    </button>
        } else if (el) {
            // apply the appropriate class to provided button 
            headerButton = React.cloneElement(el, { className });
        }
        
        cartContext.headerButton = headerButton;
        this.setState({ cartContext });
    }
    
    onCloseEmptyCartModal = () => this.setState({ showEmptyCartModal:false });
    onCloseCartLimitModal = () => this.setState({ showCartLimitModal:false });
    
    state = {
        cartContext: {
            cartItems: [],
            cartLimit: 20,
            viewCart: this.viewCart,
            addToCart: this.addToCart,
            removeFromCart: this.removeFromCart,
            clearCart: this.clearCart,
            isAccountHidden: true,
            headerButton: false,
            setHeaderButton: this.setHeaderButton // one-off not part of @ifly/ifly-web-common/cart/CartContext
        },
        
        isPendingRestore: false,
        showEmptyCartModal: false,
        showCartLimitModal: false
    }
    
    syncCartContext = () => {
        let cartContext = { ...this.state.cartContext },
            path = window.location.pathname;
        
        cartContext.cartItems = this.props.cartItems;
        
        if (!Path.CHECKOUT_URL.test(path) && !Path.CART_URL.test(path)) {
            // checkout and cart pages programatically manage the headerButton
            this.setHeaderButton(undefined, cartContext);
        } else {
            this.setState({ cartContext });
        }
    }

    componentDidUpdate(prevProps) {
        let { cartItems, sessionTunnel } = this.props;
        if (cartItems !== prevProps.cartItems) {
            this.syncCartContext();
        }
        
        if (sessionTunnel && sessionTunnel !== prevProps.sessionTunnel) {
            if (this.state.isPendingRestore) {
                this.props.actions.shoppingCartRestore();
                this.setState({ isPendingRestore:false });
            } else {
                this.clearCart(); // clear cart on change tunnel
            }
        }
    }
    
    componentDidMount() {
        if (!this.props.isKiosk) {
            if (this.props.sessionTunnel) {
                this.props.actions.shoppingCartRestore();
            } else {
                this.setState({ isPendingRestore:true });
            }
        }
        
        this.syncCartContext();
    }
    
    render() {
        let { showEmptyCartModal, showCartLimitModal, cartContext } = this.state;

        return (
            <CartContext.Provider value={cartContext}>
                { 
                    showEmptyCartModal &&
                    <Alert onClose={this.onCloseEmptyCartModal}>
                        <I18n dangerousHTML $="media.alert.empty_cart" />
                    </Alert>
                }
                { 
                    showCartLimitModal &&
                    <Alert onClose={this.onCloseCartLimitModal}>
                        <I18n dangerousHTML
                              $="media.alert.cart_limit"
                              max={cartContext.cartLimit}/>
                    </Alert>
                }
                { this.props.isLoading && <Loading/> }
                { this.props.children }
            </CartContext.Provider>
        )
    }
    
    static propTypes = {
        // redux -- cart.connect()
        cartItems: MediaPropTypes.media,
        isLoading: PropTypes.bool.isRequired,
        
        actions: PropTypes.shape({
            shoppingCartAdd: PropTypes.func.isRequired,
            shoppingCartClear: PropTypes.func.isRequired,
            shoppingCartRemove: PropTypes.func.isRequired,
            shoppingCartRestore: PropTypes.func.isRequired
        }).isRequired
    } 
}

export default mediaSettings(cart.connect(ShoppingCart));