import Environment from '@ifly/common-env'

let processEnv = process.env.REACT_APP_IFLY_ENV ||
    process.env.NODE_ENV;

if (processEnv === 'stg' || processEnv === 'stage') processEnv = 'staging';

const APP_ENV = processEnv || Environment.PRODUCTION;

console.log("Application environment:", APP_ENV);

Environment.select(APP_ENV);