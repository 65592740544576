import React from 'react'
import PropTypes from 'prop-types'
import ExternalLink from './ExternalLink'
import Environment from '@ifly/common-env'

/**
 * This class renders an anchor tag using the Indentity Server root configured
 * in `webpack.config.js` prepended to the given `path` property. An optional
 * `label` property may be supplied in lieu of using `props.children` as the
 * anchor label.
 *
 * Example: <IdLink path="/Identity/Account/Register">Register</IdLink>
 * Renders: <a href="https://login.iflyworld.com/Identity/Account/Register">Register</a>
 *
 * Example: <IdLink path="/Identity/Account/Register" className="register" label="Sign Up" />
 * Renders: <a href="https://login.iflyworld.com/Identity/Account/Register" class="register">Sign Up</a>
 */
const IdLink = React.memo(function IdLink(props) {
    return <ExternalLink root={Environment.identityServer()} {...props} />
});

IdLink.propTypes = {
    path: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ])
    // all other attributes are passed down to the anchor
}

export default IdLink;