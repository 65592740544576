import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import MediaPropTypes from 'util/MediaPropTypes'
import I18n from 'locale/I18n'
import { Link } from '@reach/router'
import { mediaSettings } from 'util/MediaContext'
import timesearch from 'redux/modules/timesearch'
import Loading from 'components/misc/Loading'

const MORNING = 'morning';
const AFTERNOON = 'afternoon';
const EVENING = 'evening';

const TIME_OF_DAY = {
    [MORNING]: <I18n iFlyUpperCase $="media.morning"/>,
    [AFTERNOON]: <I18n iFlyUpperCase $="media.afternoon"/>,
    [EVENING]: <I18n iFlyUpperCase $="media.evening"/>
};

class SearchByTime extends PureComponent {

    maybeFetchMediaSessions = () => {
        let { dateSlug, isLoading, sessionTunnel } = this.props;
        if (dateSlug && sessionTunnel && !isLoading) {
            this.props.actions.requestMediaSessions(dateSlug);
        }
    }
    
    componentDidMount() {
        this.maybeFetchMediaSessions();
    }

    componentDidUpdate(prevProps) {
        if (this.props.dateSlug!==prevProps.dateSlug ||
            this.props.sessionTunnel!==prevProps.sessionTunnel) {
            this.maybeFetchMediaSessions();
        }
    }

    render() {
        let { mediaSessions, isLoading } = this.props;

        if (isLoading || !mediaSessions) return <Loading/>;

        let sessionGroups = { [MORNING]: [], [AFTERNOON]: [], [EVENING]: [] };

        if (Array.isArray(mediaSessions)) {
            for (let session of mediaSessions) {

                let timeofday = session.time24 < '12:00' ? MORNING
                    : session.time24 < '18:00' ? AFTERNOON : EVENING;

                sessionGroups[timeofday].push(session);
            }
        }
        
        return (
            <div className="time-search">
            {
                mediaSessions.length
                    ?
                    Object.entries(sessionGroups).map(([timeofday, sessions]) =>
                        !sessions.length ? null :
                        <div className="timeofday" key={timeofday}>
                            <div className="label">{ TIME_OF_DAY[timeofday] }</div>
                            <div className="times">
                                {
                                    sessions.map(({ id, time }) =>
                                        <Link key={time} to={`./session/${id}`} className="time btn-primary">
                                            { time }
                                        </Link>
                                    )
                                }
                            </div>
                        </div>
                    )
                    :
                    <>
                        <div className="label"><I18n iFlyUpperCase $="media.sessions" /></div>
                        <div className="no-guests"><I18n $="media.no_sessions_yet"/></div>
                    </>
            }    
            </div>
        )
    }
    
    static propTypes = {
        // mediaSettings
        sessionTunnel: MediaPropTypes.sessionTunnel,

        // redux -- timesearch.connect()
        isLoading: PropTypes.bool.isRequired,
        
        actions: PropTypes.shape({
            requestMediaSessions: PropTypes.func.isRequired
        }).isRequired,

        mediaSessions: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            time: PropTypes.string.isRequired,
            time24: PropTypes.string.isRequired
        })),

        // reach-router
        path: PropTypes.string.isRequired,
        dateSlug: PropTypes.string.isRequired
    }
}

export default mediaSettings(timesearch.connect(SearchByTime));