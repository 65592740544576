import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import MediaPropTypes from 'util/MediaPropTypes'
import { Link } from '@reach/router'
import I18n from 'locale/I18n'
import { mediaSettings } from 'util/MediaContext'
import MediaUtil from 'util/MediaUtil'
import Path from 'util/PathUtil'
import Loading from 'components/misc/Loading'
import guestmedia from 'redux/modules/guestmedia'
import GuestButton from './GuestButton'
import ShoppingMediaGrid from './ShoppingMediaGrid'
import SessionTime from './SessionTime'

class GuestView extends PureComponent {

    state = {
        media: null
    }
    
    maybeFetchGuestSession = () => {
        let { dateSlug, guestId, isLoading, sessionTunnel } = this.props;
        if (sessionTunnel && dateSlug && guestId && !isLoading) {
            this.props.actions.requestGuestSession(guestId);
        }
    }

    componentDidMount() {
        this.maybeFetchGuestSession();
        MediaUtil.scrollViewportTop(this.props.isKiosk);
    }

    componentDidUpdate(prevProps) {
        if (this.props.sessionId!==prevProps.sessionId ||
            this.props.sessionTunnel!==prevProps.sessionTunnel) {
            this.maybeFetchGuestSession();
        }
    }

    render() {
        let { media, isLoading, dateSlug } = this.props;
        let { sessionTime, guests, sessionId } = media?.[0] ?? {};
        
        if (media && !isLoading && !sessionTime) return (
            <div className="guest-view">
                <div className="view-head">
                    <I18n $="media.still_processing_media" />
                </div>
                <div className="no-session">
                    <I18n $="media.wait_until_media_ready" />
                    <br/><br/>
                    <Link to={Path.cleanDateUrl()} className="btn-secondary">
                        <I18n $="button.ok" iFlyUpperCase />
                    </Link>
                </div>
            </div>
        )

        if (media) {
            media.sort(MediaUtil.sortMediaByRankValue);
        }
        
        return (
            <div className="guest-view">
                <div className="view-head">
                    <I18n $="prompt.select_photos_and_videos" />
                </div>
                {
                    !media || isLoading
                        ?
                        <Loading />
                        :
                        <>
                            <SessionTime date={Path.parseDate(dateSlug)} time={sessionTime} />
                            
                            <div className="guests">
                                <GuestButton selected>{ guests?.[0]?.name }</GuestButton> 
                                <Link to={`../../session/${sessionId}`} className="guest btn-primary">
                                    <span className="btn-label"><I18n $="button.all_guests" /></span>
                                </Link>
                            </div>

                            <ShoppingMediaGrid media={media} showPrice={true} />
                        </>
                }
            </div>
        )
    }

    static propTypes = {
        // reach-router
        guestId: PropTypes.string,

        // mediaSettings
        isKiosk: PropTypes.bool,
        sessionTunnel: MediaPropTypes.sessionTunnel,
        
        // redux -- guestmedia.connect()
        media: MediaPropTypes.media,
        isLoading: PropTypes.bool.isRequired,
        actions: PropTypes.shape({
            requestGuestSession: PropTypes.func.isRequired
        }).isRequired
    }
}

export default mediaSettings(guestmedia.connect(GuestView));