import React, { Component } from 'react'
import PropTypes from 'prop-types'

import CommonUtil from 'util/CommonUtil'
import Svg from 'components/common/Svg'
import LocationsSubMenu from './LocationsSubMenu'

export default class HeaderNavMenuItem extends Component {

    onClick = (evt) => this.props.onMenuClick(evt, this.props.item.ID);
    onMouseEnter = (evt) => this.props.onMenuMouseEnter(evt, this.props.item.ID)
    onMouseLeave = (evt) => this.props.onMenuMouseLeave(evt, this.props.item.ID)

    render() {
        let { item, subMenu } = this.props;
        let isLocations = -1 !== item.classes.indexOf('locations');
        
        if (!subMenu && !isLocations) return (
            <li onMouseEnter={this.onMouseEnter} 
                onMouseLeave={this.onMouseLeave}>
                <div className="top-item">
                    <a className="name" href={item.url}>{ CommonUtil.iFlyUpperCase(item.title) }</a>
                </div>
            </li>
        );
		
        return (
            <li className={'menu' + (this.props.isOpen ? ' open' : '')} 
                onMouseEnter={this.onMouseEnter} 
                onMouseLeave={this.onMouseLeave}>
                <div className="top-item">
                    <a className="name" href={item.url}>{ CommonUtil.iFlyUpperCase(CommonUtil.decodeHtmlSpecialChars(item.title)) }</a>
                    <i className="arrow _on S M L" onClick={this.onClick}>
                        <Svg id="chevron-right"/>
                    </i>
                    <i className="arrow _on LD XL" onClick={this.onClick}>
                        <Svg id="ic-arrow-down"/>
                    </i>
                </div>
                <ul 
                    className={'sub-menu' + (isLocations ? ' locations' : '')}>
                    <li className="menu-head _on S M L" onClick={this.onClick}>
                        <i className="arrow"><Svg id="chevron-right"/></i>
                        <h5 className="head">{ CommonUtil.iFlyUpperCase(item.title) }</h5>
                    </li>
                    { 
                        isLocations 
                            ? <LocationsSubMenu url={item.url}
                                                nearbyTunnels={this.props.nearbyTunnels}
                                                selectedTunnel={this.props.selectedTunnel} />
                                                
                            : subMenu.map(item =>
                                <li key={item.ID} className="menu-item">
                                    <a href={item.url}>{ item.title }</a>
                                </li>
                            )
                    }
                </ul>
            </li>
        )
    }

    static propTypes = {
        item: PropTypes.object.isRequired,
        isOpen: PropTypes.bool.isRequired,
        nearbyTunnels: PropTypes.array,
        selectedTunnel: PropTypes.string,
        subMenu: PropTypes.array,
        onMenuClick: PropTypes.func.isRequired,
        onMenuMouseEnter: PropTypes.func.isRequired,
        onMenuMouseLeave: PropTypes.func.isRequired
    }
}