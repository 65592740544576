export default class StringUtil {
  /**
   * Determine if a string has some non-empty value
   * @param {*} value 
   */
  static isEmpty(value = "") {
    return "".concat(value) === "";
  }

  static isNotEmpty(value) {
    return !this.isEmpty(value);
  }

  static isBlank(value) {
    return !this.isNotBlank(value);
  }

  /**
   * Determine if a string contains non-whitespace values
   * @param {*} value 
   */
  static isNotBlank(value = "") {
    return this.isNotEmpty("".concat(value).trim());
  }
}
