import PropTypes from 'prop-types'
import React, { Component } from 'react'
import Input from 'react-phone-number-input/input'
import { mediaSettings } from 'util/MediaContext';

/**
 * A formik field that handles international phone input and supports Kiosk
 */
class PhoneInput extends Component {
    constructor(props) {
        super(props);

        let previousValue = props.field?.value ?? '';
        this.state = { previousValue };

        this.fieldRef = React.createRef();
    }

    handleChange = (value = '') => {
        let { field, form, isKiosk } = this.props;

        form.setFieldValue(field.name, value);

        // for kiosk mode, we need to micro-manage the cursor for times
        // when the phone input field automatically adds delimiters
        if (isKiosk) {
            let { previousValue } = this.state,
                inputValue = this.fieldRef.current.value || '';

            // check if the field value's length has changed by more than one
            // character. If so, that means that delimiters were automatically
            // added or removed.
            if (Math.abs(inputValue.length - previousValue.length) > 1) {
                // we have to set a timeout in order to let the KIOSK handleChange
                // function to finish
                window.setTimeout(() => {
                    this.fieldRef.current.selectionStart = inputValue.length;
                    this.fieldRef.current.selectionEnd = inputValue.length;
                }, 0);
            }

            this.setState(prevState => ({
                ...prevState,
                previousValue: inputValue
            }));
        } else {
            form.handleChange(value);
        }
    }

    render() {
        let {defaultCountry, field, form, isKiosk, sessionTunnel, ...props} = this.props;
        
        return (
            <Input defaultCountry={defaultCountry}
                onBlur={form.handleBlur}
                onChange={this.handleChange}
                ref={this.fieldRef}
                {...props}
                name={field.name}
                value={field.value}
            />
        )
    }

    static defaultProps = {
        defaultCountry: 'US'
    }

    static propTypes = {
        field: PropTypes.object.isRequired, // from formik
        form: PropTypes.object.isRequired, // from formik
        defaultCountry: PropTypes.string // the default country if no country code is entered
    }
}

export default mediaSettings(PhoneInput);