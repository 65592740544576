import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { shoppingCart } from 'util/CartContext'
import I18n from 'locale/I18n'
import Svg from 'components/common/Svg';

import MyLink from 'components/link/MyLink'
import WpLink from 'components/link/WpLink'

import PopupMenu from '../common/PopupMenu'

const i18n_book_a_flight = <I18n $="button.book_a_flight" />
const i18n_greeting = <I18n $="header.greeting" />
const i18n_hi = <I18n $="header.hi" />
const i18n_logout = <I18n $="button.logout" />
const i18n_my_profile = <I18n $="button.my_profile" />

const accountIcon = <i className="icon"><Svg id="ic-account" /></i>

class HeaderBrandBar extends Component {
    getProfilePopup = () => {
        // User is not logged in; clicking glyph opens login modal
        if (!this.props.user) return (
            <div className="popup-tog" onClick={this.props.openLoginModal}>
                { accountIcon }
            </div>
        );

        // User logged in; clicking glyph opens account popup menu 
        let name = this.props.user.given_name; // given_name is first name, but might be blank
        let greeting = name ? `${i18n_hi} ${name}.` : i18n_greeting;
        
        return (
            <PopupMenu toggle={accountIcon}>
                <ul className="body">
                    <li className="tile" role="menuitem">
                        <h5 className="greeting">{greeting}</h5>
                        <MyLink path="/account/profile" className="btn primary login" label={ i18n_my_profile } />
                        <a href="#top" className="btn secondary signup" onClick={this.props.logout}>{ i18n_logout }</a>
                    </li>
                </ul>
            </PopupMenu>
        );
    }
    
    render() {
        let { isAccountHidden, cartItems, viewCart, headerButton } = this.props;
        let cartQty = cartItems ? cartItems.length : 0;
        return (
            <div className="brand-bar">
                <span className={'nav-tog _on S M L' + (this.props.isNavigationOpen ? ' open' : '')}
                      onClick={this.props.toggleNavigation}>
                    <span className="if-shut">
                        <i className="icon _on M L"><Svg id="ic-menu"/></i>
                        <i className="icon _on S"><Svg id="ic-menu-slice"/></i>
                        <span className="total s-badge _on S">{cartQty}</span>
                    </span>
                    <span className="if-open">
                        <i className="icon"><Svg id="ic-close"/></i>
                    </span>
                </span>

                <WpLink path="/" className="logo"><Svg id="ifly-logo"/></WpLink>
                
                {
                    !isAccountHidden &&
                    <ul className="iflyid _on M L LD XL" role="menubar" aria-label="account">
                        <li role="menuitem">
                            { this.getProfilePopup() }
                        </li>
                    </ul>
                }
                
                <WpLink path="/cart" onClick={viewCart} className="cart _on M L LD XL">
                    <i className="icon"><Svg id="ic-cart" /></i>
                    <span className="total m-badge">{cartQty}</span>
                </WpLink>
                
                {
                    headerButton===true
                        ?
                    <WpLink path="/book-now" className="book btn" label={ i18n_book_a_flight } />
                        :
                    headerButton
                }
                
            </div>
        );
    }
    
    static propTypes = {
        isNavigationOpen: PropTypes.bool.isRequired,
        logout: PropTypes.func.isRequired,
        openLoginModal: PropTypes.func.isRequired,
        toggleNavigation: PropTypes.func.isRequired,
        user: PropTypes.object,

        // shoppingCart
        viewCart: PropTypes.func,
        cartItems: PropTypes.array,
        isAccountHidden: PropTypes.bool,
        headerButton: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.node
        ]).isRequired,
    }
}

export default shoppingCart(HeaderBrandBar);