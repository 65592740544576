import React, {Component} from 'react'
import PropTypes from 'prop-types'

import CommonUtil from 'util/CommonUtil'

/**
 * This class encapsulates a popup toggle and its respective menu and manages
 * the toggled state of the popup.  When a popup's toggle is clicked the menu
 * becomes visible. Pressing ESC or clicking outside the menu closes the menu.
 */
export default class PopupMenu extends Component {

    /**
     * Initial state
     */
    state = {
        isOpen: false
    }

    /**
     * Constructor establishes child references for determining click origin. 
     * @param {Object} props The component properties.
     */
    constructor(props) {
        super(props);
        this.toggleRef = React.createRef();
        this.menuRef = React.createRef();
    }
    
    /**
     * Sets the pop-up state closed.
     */
    closePopup = () => this.setState({ isOpen:false });
    
    /**
     * Click handler closes the popup if the click happens outside the menu.
     * @param {Event} evt The browser event.
     */
    onWindowClick = evt => {
        if (!this.menuRef.current.contains(evt.target) &&
            !this.toggleRef.current.contains(evt.target)) {
            this.closePopup();
        }
    }

    /**
     * Keypress handler closes the popup if ESC is pressed.
     * @param {Event} evt The browser event.
     */
    onWindowKeypress = evt => {
        if (CommonUtil.ESC === evt.keyCode) {
            this.closePopup();
        }
    }

    /**
     * When the opened state changes this method updates the listeners attached
     * to the window that dismiss the popup on keypress or out-of-bounds click.
     * @param {Object} prevProps The components previous properties.
     * @param {Object} prevState The components previous state.
     */
    componentDidUpdate(prevProps, prevState) {
        let isOpen = this.state.isOpen;
        
        if (isOpen !== prevState.isOpen) {
            if (isOpen) {
                window.addEventListener('click', this.onWindowClick);
                window.addEventListener('keydown', this.onWindowKeypress);
            } else {
                window.removeEventListener('click', this.onWindowClick);
                window.removeEventListener('keydown', this.onWindowKeypress);
            }
        }
    }

    /**
     * Toggle click handler changes the open state of the popup.  
     * @param {Event} evt The browser event.
     */
    togglePopup = evt => {
        this.setState({ isOpen: !this.state.isOpen });
        evt.preventDefault();
    }

    render() {
        const isOpen = this.state.isOpen;
        return <>
            <div className={'popup-tog' + (isOpen ? ' open' : '')}
                 ref={this.toggleRef} onClick={this.togglePopup} aria-haspopup={true}>
                {this.props.toggle}
            </div>
            <div className="popup-menu" ref={this.menuRef} aria-expanded={isOpen}>
                {
                    isOpen &&
                        
                    // Pass closePopup to child component(s) for use in the content.
                    CommonUtil.mapChildrenProps(this.props.children, {
                        closePopup: this.closePopup
                    })
                }
            </div>            
        </>
    }

    static propTypes = {
        toggle: PropTypes.oneOfType([
            PropTypes.element.isRequired,
            PropTypes.array.isRequired,
            PropTypes.object.isRequired
        ]),
        children: PropTypes.oneOfType([
            PropTypes.element.isRequired,
            PropTypes.array.isRequired,
            PropTypes.object.isRequired
        ])
    }
}
