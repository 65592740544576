import React from 'react'
import PropTypes from 'prop-types'
import ExternalLink from './ExternalLink'
import Environment from '@ifly/common-env'

/**
 * This class renders an anchor tag using the My iFLY server root configured
 * in `webpack.config.js` prepended to the given `path` property. An optional
 * `label` property may be supplied in lieu of using `props.children` as the
 * anchor label.
 *
 * Example: <MyLink path="/">My iFLY</MyLink>
 * Renders: <a href="https://my.iflyworld.com/">My iFLY</a>
 *
 * Example: <MyLink path="/account/profile" className="acct">Account</MyLink>
 * Renders: <a href="https://my.iflyworld.com/account/profile" class="acct">Account</a>
 *
 * Example: <MyLink path="/flights/upcoming" label="Flights" />
 * Renders: <a href="https://my.iflyworld.com/flights/upcoming">Flights</a>
 */
const MyLink = React.memo(function MyLink(props) {
    return <ExternalLink root={Environment.myIflyServer()} {...props} />;
});

MyLink.propTypes = {
    path: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ])
    // all other attributes are passed down to the anchor
};

export default MyLink;
