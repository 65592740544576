import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { shoppingCart } from 'util/CartContext'
import CommonUtil from 'util/CommonUtil'
import I18n from 'locale/I18n'
import Svg from 'components/common/Svg'

import IdLink from 'components/link/IdLink'
import MyLink from 'components/link/MyLink'
import WpLink from 'components/link/WpLink'

const i18n_account = <I18n $="button.account" />
const i18n_greeting = <I18n $="header.greeting" />
const i18n_GREETING = <I18n $="header.greeting" iFlyUpperCase />
const i18n_hi = <I18n $="header.hi" />
const i18n_login = <I18n $="button.login" />
const i18n_logout = <I18n $="button.logout" />
const i18n_shopping_cart = <I18n $="button.shopping_cart" />
const i18n_sign_up = <I18n $="button.sign_up" />
const i18n_upcoming_flights = <I18n $="button.upcoming_flights" />

export const MENU_ID = 'account';

class HeaderNavMenuAccount extends Component {

    onClick = (evt) => this.props.onMenuClick(evt, MENU_ID);
    onMouseEnter = (evt) => this.props.onMenuMouseEnter(evt, MENU_ID)
    onMouseLeave = (evt) => this.props.onMenuMouseLeave(evt, MENU_ID)

    // TODO: dynamically load language options

    render() {
        
        let { isAccountHidden, cartItems, viewCart, user } = this.props,
            isLoggedIn = !!user,
            fname = isLoggedIn ? user.given_name : null;
        
        let accountLoginWelcome = isAccountHidden ? null : isLoggedIn
            ?
            <span className="iflyid toggle" onClick={this.onClick}>
                <i className="icon"><Svg id="ic-account"/></i>
                <span className="greeting">{fname ? `${i18n_hi} ${fname}.` : i18n_greeting}</span>
            </span>
            :
            <span className="iflyid">
                <a href="#top" onClick={this.props.openLoginModal}>{i18n_login}</a>
                <span className="sep">/</span>
                <IdLink path="/Identity/Account/Register" label={i18n_sign_up}/>
            </span>
        
        return (
            <li className={'account menu' + (this.props.isOpen ? ' open' : '')}
                onMouseEnter={this.onMouseEnter} 
                onMouseLeave={this.onMouseLeave}>
            {
                !isAccountHidden &&
                <div className="top-item _on M L">{ accountLoginWelcome }</div>
            }
                <div className="top-item _on S">
                    { accountLoginWelcome }
                    <WpLink path="/cart" onClick={viewCart} className="cart">
                        <i className="icon"><Svg id="ic-cart"/></i>
                        <span className="total m-badge">{ cartItems ? cartItems.length : 0 }</span>
                    </WpLink>
                </div>
            {
                !isAccountHidden && isLoggedIn &&
                <ul className="sub-menu">
                    <li className="menu-head _on S M L" onClick={this.onClick}>
                        <i className="arrow"><Svg id="chevron-right"/></i>
                        <h5 className="head">{ fname ? CommonUtil.iFlyUpperCase(fname) : i18n_GREETING }</h5>
                    </li>
                    <li className="menu-item"><MyLink path="/account/profile" label={ i18n_account }/></li>
                    <li className="menu-item"><MyLink path="/flights/upcoming" label={ i18n_upcoming_flights }/>
                    </li>
                    <li className="menu-item"><WpLink path="/cart" onClick={viewCart} label={ i18n_shopping_cart }/></li>
                    {/*
                        TODO: <Logout/> ==> integrate the @ifly/ifly-web-login package for Logout onClick 
                    */}
                    <li className="menu-item"><a href="#top" onClick={this.props.logout}>{ i18n_logout }</a></li>
                </ul>
            }
            </li>
        )
    }
    
    static propTypes = {
        user: PropTypes.object,
        logout: PropTypes.func.isRequired,
        openLoginModal: PropTypes.func.isRequired,

        // shoppingCart
        viewCart: PropTypes.func,
        cartItems: PropTypes.array,
        isAccountHidden: PropTypes.bool
    }
}

export default shoppingCart(HeaderNavMenuAccount);