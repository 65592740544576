import React, {Component} from 'react'
import PropTypes from 'prop-types'

import I18n, { i18n, localize } from 'locale/I18n'
import Svg from 'components/common/Svg'

import IdLink from 'components/link/IdLink'
import WpLink from 'components/link/WpLink'
import loadingImage from 'images/logo/loading.gif'

const initialState = {
    isAuthenticating: false,
    error: null
}

const i18n_agreement_and = <I18n $="loginform.agreement.and" />
const i18n_agreement_preamble = <I18n $="loginform.agreement.preamble" />
const i18n_error_credential = <I18n $="loginform.error.credential" />
const i18n_error_unknown = <I18n $="loginform.error.unknown" />
const i18n_forgot_password = <I18n $="loginform.forgot_password" />
const i18n_join_now = <I18n $="loginform.join_now" iFlyUpperCase />
const i18n_log_in = <I18n $="loginform.log_in" iFlyUpperCase />
const i18n_privacy_policy = <I18n $="button.privacy_policy" />
const i18n_terms_of_use = <I18n $="button.terms_of_use" />
const i18n_title = <I18n $="loginform.title" iFlyUpperCase />

/**
 * This iFLY ID login form is hosted within a modal window which passes down a
 * `closeModal` property used to close the login form programmatically below.
 */
class LoginForm extends Component {
    
    state = initialState
    
    onSubmit = evt => {
        let form = evt.target;
        evt.preventDefault();
        this.setState({ isAuthenticating: true });

        this.props.client.authenticate(form.email.value, form.password.value)
            .then(val => {
                this.setState({ ...initialState });
                this.props.afterLogin();
            })
            .catch(exception => {
                var error = exception.error==='invalid_grant' ? 'credential-error' : 'unknown-error';
                this.setState({ error, isAuthenticating: false });
            });
    }
    
    render() {
        const i18n_authenticating = i18n('header.authenticating');
        const i18n_email = i18n('placeholder.email');
        const i18n_password = i18n('placeholder.password');

        return <>
            {
                this.state.isAuthenticating && <>
                    <div className="loading-glyph">
                        <img src={loadingImage} width={54} height={54}
                             alt={ i18n_authenticating }
                             title={ i18n_authenticating } />
                    </div>
                    <div className="loading-mask"></div>
                </>
            }
            
            <div className="login-form">
                <div className="head">
                    <i className="logo"><Svg id="ifly-logo"/></i>
                    <i className="close" onClick={this.props.closeModal}><Svg id="ic-close"/></i>
                </div>
                <div className="body">
                    <h2>{ i18n_title }</h2>

                    <form onSubmit={this.onSubmit} className={this.state.error}>
                        <div className="credential error">{  i18n_error_credential  }</div>
                        <div className="unknown error">{  i18n_error_unknown  }</div>
                        
                        <div className="field">
                            <input name="email" type="email" placeholder={ i18n_email } required 
                                   autoComplete="section-iflyid username"/>
                        </div>
                        
                        <div className="field">
                            <input name="password" type="password" placeholder={ i18n_password } required
                                   autoComplete="section-iflyid current-password"/>
                        </div>
                        
                        <div className="agreement">
                            {  i18n_agreement_preamble  }{' '}
                            <WpLink path="/privacy" tabIndex={-1} label={ i18n_privacy_policy } />{' '}
                            {  i18n_agreement_and  }{' '}
                            <WpLink path="/terms" tabIndex={-1} label={ i18n_terms_of_use } />{'.'}
                        </div>
                        <button type="submit" className="btn login primary">
                            { i18n_log_in }
                        </button>
                        
                        <IdLink path="/Identity/Account/Register" className="btn login">
                            { i18n_join_now }
                        </IdLink>
                        <div className="forgot-password">
                            <IdLink path="/Identity/Account/ForgotPassword">
                                { i18n_forgot_password }
                            </IdLink>
                        </div>
                    </form>

                </div>
            </div>
        </>
    }
    
    static propTypes = {
        afterLogin: PropTypes.func.isRequired,
        client: PropTypes.object.isRequired
    }
}

export default localize(LoginForm)