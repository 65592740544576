import React, { Component } from 'react'
import PropTypes from 'prop-types'

import CommonUtil from 'util/CommonUtil'
import WordpressApi from 'api/WordpressApi'
import { localize } from 'locale/I18n'

import HeaderNavMenuItem from './nav/HeaderNavMenuItem'
import HeaderNavMenuAccount, { MENU_ID as ACCOUNT_MENU_ID } from './nav/HeaderNavMenuAccount'
import HeaderNavMenuLanguage, { MENU_ID as LANGUAGE_MENU_ID } from './nav/HeaderNavMenuLanguage'

// Minimum user agent viewport width for desktop views.
const DEVICE_WIDTH_LD = 1440;

// Body css class applied when a top-level navigation item is hovered.
const HOVER_NAV_MENU = 'hover-nav-menu';

// Body css class applied when a sub-menu navigation item is hovered.
const HOVER_NAV_ITEM = 'hover-nav-item';

// Used to manage the enhanced rollover menus
let rolloverState;

class HeaderNavigation extends Component {

    state = {
        openMenu: null,
        menu: null,
        isLoading: false
    }
    
    loadMenu = () => {
        let { isLoading } = this.state;
        
        // load header menu
        if (!isLoading) {
            this.setState({ isLoading: true });

            WordpressApi.getHeaderMenu()
                .then(response => {
                    this.setState({
                        isLoading: false,
                        menu: WordpressApi.buildTopAndSubMenus(response.data)
                    });
                })
                .catch(error => {
                    this.setState({ isLoading: false });
                    console.log('Error fetching accpl_header menu: ', error);
                });
        }
    }
    
    componentDidUpdate(prevProps) {
        let { locale } = this.props;
        if (locale !== prevProps.locale) {
            this.loadMenu();
        }
    }
    
    /**
     * When the navigation component first starts up load its WordPress menu, 
     * and configure window resize listeners.
     */
    componentDidMount() {
        window.addEventListener('resize', this.onWindowResize);
        
        if (!this.state.menu) {
            this.loadMenu();
        }
    }
    
    /**
     * Removes the window resize listeners.
     */
    componentWillUnmount() {
        window.removeEventListener('resize', this.onWindowResize);
    }

    /**
     * When the window resizes reset the hover nav menu body classes and purge
     * the open menu state.
     */
    onWindowResize = evt => {
        CommonUtil.removeBodyCls(HOVER_NAV_MENU, HOVER_NAV_ITEM);
        this.setState({ openMenu: null })
        this.props.closeNavigation();
    }

    /**
     * Opens a top-level menu when clicked. 
     */
    onMenuClick = (evt, menuId) => {
        evt.preventDefault();
        let openMenu = this.state.openMenu===menuId ? null : menuId;
        this.setState({ openMenu });
    }

    /**
     * Part of the enhanced menu rollovers this method monitors the mouse 
     * movement to determine user intent.
     */
    onMouseMove = evt => {
        let rs = rolloverState,
            topEl = rs.topEl;

        if (topEl.contains(evt.target)) {
            // user is moving around inside the top item

            if (rs.updateMouseXY) {
                delete rs.updateMouseXY;
                rs.x = evt.clientX;
                rs.y = evt.clientY;
                //console.log('onMouseMove ... buffered update:(',rs.x,',',rs.y,') .... target=', evt.target);

            } else if (rs.updateMouseXY===undefined) {
                rs.updateMouseXY = false;
                // update the mouse position every 300ms
                setTimeout(() => {
                    if (rs) rs.updateMouseXY = true;
                    //console.log('onMouseMove::setTimeout::rs=', rs);
                }, 100);
            }

        } else {
            // user is outside the top item
            rs.x = rs.midpointX;
            rs.y = rs.midpointY;
            //console.log('onMouseMove ... OUTSIDE reset x,y to default midpoint');
        }
    }

    /**
     * Part of the enhanced menu rollovers this method establishes a new or
     * pending rollover state for further intent calculation.
     */
    createRolloverState = (menuId, evt) => {
        let li = evt.target.closest('li'),
            els = li ? li.querySelectorAll('.sub-menu') : [];

        // check if the hovered nav item has a sub-menu
        if (els.length) {
            let topEl = els[0].previousSibling;
            let rects = topEl.getClientRects()[0],
                midpointX = rects.x + rects.width/2,
                midpointY = rects.y + rects.height/2;

            return {
                menuId: menuId,
                menuEl: els[0],
                topEl: topEl,
                midpointX: midpointX,
                midpointY: midpointY,
                x: midpointX,
                y: midpointY
            };
        }

        return { navItem: true };
    }

    /**
     * Part of the enhanced menu rollovers this method continues management of
     * user intent when the mouse enters a menu element.
     */
    onMenuMouseEnter = (evt, menuId) => {
        // hover behavior only for desktop resolutions 
        if (window.innerWidth >= DEVICE_WIDTH_LD) {
            let rs = rolloverState;

            if (rs?.menuId) {
                // rollover state already established
                if (rs.menuId===menuId) {
                    // re-entering previous nav so purge pending actions
                    delete rs.maybeBlurNav;
                    delete rs.pendingState;

                } else {
                    // entering a new nav which might receive focus
                    rs.pendingState = this.createRolloverState(menuId, evt);
                    //console.log('Pending State created: ', rs.pendingState);
                }
            } else {
                rs = rolloverState = this.createRolloverState(menuId, evt);

                if (rs.menuEl) {
                    //console.log('onMenuMouseEnter -- addEventListener (mousemove)', rolloverState);
                    window.addEventListener('mousemove', this.onMouseMove);

                    CommonUtil.addBodyCls(HOVER_NAV_MENU);
                    this.setState({ openMenu: rs.menuId });
                } else {
                    CommonUtil.addBodyCls(HOVER_NAV_ITEM);
                }
            }
        }
    }

    /**
     * Part of the enhanced menu rollovers this method continues management of
     * user intent after the mouse leaves a menu element.
     */
    monitorMouseLeave = () => {
        let rs = rolloverState;

        rs.maybeBlurNav = true;

        //console.log('monitorMouseLeave ... setTimeout ... rs.maybeBlurNav=', rs.maybeBlurNav);
        setTimeout(() => {
            //console.log('monitorMouseLeave::setTimeout ... rs.maybeBlurNav=', rs.maybeBlurNav);

            if (rs.maybeBlurNav) {
                let ps = rs.pendingState;

                if (ps) {
                    if (ps.menuEl) {
                        // pending state is also a menu item (continue watching mousemove events)
                        this.setState({ openMenu: ps.menuId });

                    } else {
                        this.hideMenu();

                        // switch from hovering a menu item to hovering a plain item
                        CommonUtil.addBodyCls(HOVER_NAV_ITEM);
                    }

                    rolloverState = ps;

                } else {
                    this.hideMenu();
                    rolloverState = null;
                }
            }

        }, 500); // user has this much time to traverse sibling menu item without hiding the sub-menu

    }

    /**
     * Part of the enhanced menu rollovers this method continues management of
     * user intent when the mouse leaves a menu element.
     */
    onMenuMouseLeave = (evt, menuId) => {
        // hover behavior only for desktop resolutions 
        if (window.innerWidth >= DEVICE_WIDTH_LD) {
            let rs = rolloverState;

            if (!menuId || !rs) {
                CommonUtil.removeBodyCls(HOVER_NAV_ITEM);

            } else if (rs.menuId===menuId) {

                //console.log('onMenuMouseLeave menuId ... tgt=', evt.target);

                if (evt.target!==rs.menuEl) {
                    let rects = rs.menuEl.getClientRects()[0],
                        x0 = rs.x,
                        y0 = rs.y,
                        x1 = evt.clientX,
                        y1 = evt.clientY,
                        y2 = rects.y,
                        left = rects.x,
                        right = rects.x + rects.width;

                    //console.log('onMenuMouseLeave ... origin:(',x0,',',y0,') ... exit:(',x1,',',y1,') ... target-Y:(',y2,')');

                    if (x1 !== x0 /* no divide by zero (mouse movement horizontal) */
                        &&  y1>y0 /* mouse must be moving down towards the menu  */
                        &&  y1<y2 /* mouse should not be leaving the sub-menu */ ) {

                        // Top-level mouse entry (x0, y0) and exit (x1, y1) define a 
                        // line with slope:
                        let m = (y1-y0)/(x1-x0);

                        // If that line were to continue to the Y-coordinate of the 
                        // sub-navigation menu top (y2) the line's X-coordinate is:
                        let x2 = (y2-y0)/m + x0;

                        // if x2 is between the left and right edges of the menu, the 
                        // line intersects the menu i.e. the user is moving the mouse
                        // towards the sub-navigation menu.
                        if (x2 >= left && x2 <= right) {
                            return this.monitorMouseLeave();
                        }
                    }
                }

                this.hideMenu();
                rs = rolloverState = null;
            }
        }
    }

    hideMenu = () => {
        this.setState({ openMenu: null });
        window.removeEventListener('mousemove', this.onMouseMove);
        CommonUtil.removeBodyCls(HOVER_NAV_MENU);
    }

    render() {
        return (
            <ul className="nav nav-main">
                {/*
                    Account menu is only for S M L devices. For LD XL devices the accout
                    menu is set to 0 height and used to indent the DISCOVER item.
                 */}
                <HeaderNavMenuAccount isOpen={this.state.openMenu===ACCOUNT_MENU_ID}
                                      user={this.props.user}
                                      logout={this.props.logout}
                                      openLoginModal={this.props.openLoginModal}
                                      onMenuClick={this.onMenuClick}
                                      onMenuMouseEnter={this.onMenuMouseEnter}
                                      onMenuMouseLeave={this.onMenuMouseLeave} />

                {/*
                    Iterate the WordPress menus and their submenus.
                 */
                    this.state.menu?.top.map(item =>
                        <HeaderNavMenuItem key={item.ID}
                                           item={item}
                                           isOpen={this.state.openMenu===item.ID}
                                           nearbyTunnels={this.props.nearbyTunnels}
                                           selectedTunnel={this.props.selectedTunnel}
                                           subMenu={this.state.menu.sub[item.ID]}
                                           onMenuClick={this.onMenuClick}
                                           onMenuMouseEnter={this.onMenuMouseEnter}
                                           onMenuMouseLeave={this.onMenuMouseLeave} />
                    )}

                {/*
                    Langage menu is only for S M devices. For L LD XL devices this 
                    language menu hidden, and langauge is updated from the top bar.
                 */}
                <HeaderNavMenuLanguage isOpen={this.state.openMenu===LANGUAGE_MENU_ID}
                                       nearbyTunnels={this.props.nearbyTunnels}
                                       selectedTunnel={this.props.selectedTunnel}
                                       onMenuClick={this.onMenuClick}
                                       onMenuMouseEnter={this.onMenuMouseEnter}
                                       onMenuMouseLeave={this.onMenuMouseLeave} />
            </ul>
        )
    }

    static propTypes = {
        nearbyTunnels: PropTypes.array,
        selectedTunnel: PropTypes.string,
        openLoginModal: PropTypes.func.isRequired,
        closeNavigation: PropTypes.func.isRequired
    }
}

export default localize(HeaderNavigation)