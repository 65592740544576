import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import MediaPropTypes from 'util/MediaPropTypes'
import { Link } from '@reach/router'
import KioskKeyboard, { addKeyboard } from '@ifly/react-kiosk-keyboard'
import I18n, { i18n, localize } from 'locale/I18n'
import { mediaSettings } from 'util/MediaContext'
import guestsearch from 'redux/modules/guestsearch'
import Loading from 'components/misc/Loading'

addKeyboard('kiosk-search-by-name', [
    'Q W E R T Y U I O P',
    'A S D F G H J K L \'',
    'Z X C V B N M - {bksp}',
    '{space}'
]);

const FORM_ID = "media-search-by-name";
const i18n_guests_label = <div className="label"><I18n iFlyUpperCase $="media.guests" /></div>

class SearchByName extends PureComponent {

    state = {
        query: '',
        guests: null
    }
    
    constructor(props) {
        super(props);
        this.inputEl = React.createRef();
    }
    
    onChangeQuery = evt => {
        let query = evt.target.value.toUpperCase();
        let guests = this.props.guests.filter(
            record => record.name.match(new RegExp(query, 'i'))
        );
        this.setState({ query, guests });
    }
    
    maybeFetchGuests = () => {
        if (this.props.dateSlug && this.props.sessionTunnel && !this.props.isLoading) {
            this.props.actions.requestGuests(this.props.dateSlug);
        }
    }
    
    componentDidMount() {
        this.maybeFetchGuests();
    }

    componentDidUpdate(prevProps) {
        if (this.props.dateSlug!==prevProps.dateSlug ||
            this.props.sessionTunnel!==prevProps.sessionTunnel) {
            this.maybeFetchGuests();
        }
        
        if (this.inputEl.current) {
            this.inputEl.current.focus();
        }
    }

    render() {
        let { isKiosk, isLoading, guests } = this.props;

        if (isLoading || !guests) return <Loading/>;
        
        guests = this.state.guests || guests;
        
        return (
            <div className="name-search">
            {
                this.props.guests.length ? <>
                    <form id={FORM_ID}>
                        <input data-input-type="kiosk-search-by-name"
                               ref={this.inputEl}
                               name="media-query-by-name"
                               value={this.state.query}
                               placeholder={i18n('media.search_placeholder')}
                               onChange={this.onChangeQuery} />
                    </form>
                    { i18n_guests_label }
                    <div className="guests">
                    {
                        guests.length
                            ?
                            guests.map(({ id, name }) => 
                                <Link key={id} to={`../guest/${id}`} className="guest btn-primary">
                                    <span className="btn-label">{ name }</span>
                                </Link>
                            )
                            :
                            <div className="no-guests"><I18n $="media.empty_search"/></div>
                    }
                    </div>
                    { isKiosk && <KioskKeyboard formId={FORM_ID} containerSelector=".name-search" /> }

                </> : <>
                    { i18n_guests_label }
                    <div className="no-guests"><I18n $="media.no_guests_yet"/></div>
                </>
            }
            </div>
        )
    }

    static propTypes = {
        // mediaSettings
        isKiosk: PropTypes.bool,
        sessionTunnel: MediaPropTypes.sessionTunnel,

        // redux -- guestsearch.connect()
        isLoading: PropTypes.bool.isRequired,
        
        guests: MediaPropTypes.guests,

        actions: PropTypes.shape({
            requestGuests: PropTypes.func.isRequired
        }).isRequired,

        // reach-router
        path: PropTypes.string.isRequired,
        dateSlug: PropTypes.string.isRequired
    }
}

export default localize(mediaSettings(guestsearch.connect(SearchByName)));