import React from 'react'
import { Router } from '@reach/router'
import MediaFinder from './MediaFinder'
import MediaRedirect from './MediaRedirect'
import Path from 'util/PathUtil'
import CartSummary from 'components/cart/CartSummary'
import ConvergenceCheckout from './convergence/ConvergenceCheckout';
import ConvergenceOrderDone from './convergence/ConvergenceOrderDone';

export default React.memo(function Storefront(props) {
    
    return (
        <div className={props.className}>
            <Router primary={false}>
                <MediaFinder path={`${Path.DATE}/:dateSlug/*`} />
                <CartSummary path={`${Path.CART}/*`} />
                <ConvergenceOrderDone path={`${Path.CONVERGENCE_DONE}/:lookupCode/*`} />
                <ConvergenceCheckout path={`${Path.CONVERGENCE_CHECKOUT}/*`} />
                { props.children }
                <MediaRedirect default to="/" note="Storefront redirect(/) for MediaFinder" />
            </Router>
        </div>
    )
});