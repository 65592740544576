import React from 'react'

let _scrollbarWidth;

/**
 * Static class contains a collection of useful static utility methods. 
 */
export default class CommonUtil {
    /**
     * Numeric code for the ESC key.
     */
    static ESC = 27
    
    /**
     * Adds the given css classes to the document body.
     * @param {String} cls The css class to add.
     */
    static addBodyCls = (...classes) => {
        for (let cls of classes) {
            window.document.body.classList.add(cls);
        }
    }

    /**
     * Removes the given css classes from the document body.
     * @param {String} cls The css class to remove.
     */
    static removeBodyCls = (...classes) => {
        for (let cls of classes) {
            window.document.body.classList.remove(cls);
        }
    }

    /**
     * Converts the passed string to uppercase except for the 'i' in iFLY. 
     * @param {String} s The string to uppercase.
     * @returns {string} An uppercase string, never capitalizing iFLY.
     */
    static iFlyUpperCase = s => s.toUpperCase().replace(/\bIFLY\b/, 'iFLY');

    /**
     * Returns the given array of children after applying the supplied `props`
     * to every immediate child React component.
     * @param {Mixed[]} children A component's props.children data structure.
     * @param {Object} props The additional properties to apply to each child.
     * @returns {Mixed[]} A collection of children with new `props` applied.
     */
    static mapChildrenProps = (children, props) =>
        React.Children.map(children, child => {
            return typeof child.type !=='function' ? child
                : React.cloneElement(child, { ...props });
        });

    /**
     * Returns the width of the user agent vertical scrollbar.
     * @returns {Integer} The width of the scrollbar.
     */
    static getScrollbarWidth = () => {
        if (_scrollbarWidth===undefined) {
            let doc = window.document,
                outer = doc.createElement('div'),
                inner = doc.createElement('div');
            
            outer.style.visibility = 'hidden';
            outer.style.width = '100px';
            doc.body.appendChild(outer);

            let noScrollbar = outer.offsetWidth;

            outer.style.overflow = 'scroll';
            inner.style.width = '100%';
            outer.appendChild(inner);
            
            let hasScrollbar = outer.clientWidth;
            _scrollbarWidth = noScrollbar - hasScrollbar;
            
            doc.body.removeChild(outer);
        }
        return _scrollbarWidth;
    }


    /**
     * Emits a phone number URL with the `tel:` scheme. The given number is
     * stripped of non-digits; however, plus (+) is allowed for country code.
     * @param phone A phone number.
     * @returns {string} A phone number URL.
     */
    static telUrl = phone => 'tel:' + (''+phone).replace(/[^0-9+]/g,'')

	/**
	 * Encodes a string so that it renders special characters in html properly.
	 * At the moment, only &#038; (&) is supported.
	 * @param string The string to modify
	 * @returns {string} The properly escaped string
	 */
	static decodeHtmlSpecialChars(string) {
		return string.replaceAll('&#038;', '&');
	}
}