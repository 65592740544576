import { all, call, put, takeLatest } from 'redux-saga/effects'
import { createModule } from '@ifly/redux-modules'
import MediaApi from 'api/MediaApi'

const Module = createModule({
    name: 'tunnels',
    
    initialState: {
        tunnels: null,
        isLoading: false
    },
    
    actions: {
        requestTunnels: { reducer:false, dispatch:true }, // reduced by saga
        loadingTunnels: { },
        receiveTunnels: { },
        failureTunnels: { }
    }
});

export default Module;

export class TunnelsSaga {
    static* watcher() {
        yield all([
            takeLatest(Module.constants.requestTunnels, TunnelsSaga.fetchTunnels)
        ]);
    }

    /**
     * Fetch tunnels from the local API
     * @param {Object} action The action being dispatched
     */
    static* fetchTunnels() {
        let isLoading = true;
        yield put(Module.actions.loadingTunnels({ isLoading }));

        let response = yield call(MediaApi.get, '/api/Tunnels'),
            tunnels = null;
        
        isLoading = false;
        
        if (response) {
            tunnels = response.data;
            yield put(Module.actions.receiveTunnels({ isLoading, tunnels }));
        } else {
            yield put(Module.actions.failureTunnels({ isLoading, tunnels }));
        }
    }
}