// These must be the first lines in src/index.js
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'env'

import React from 'react'
import ReactDOM from 'react-dom'
import Modal from 'react-modal'
import { Provider } from 'react-redux'
import createStore from 'redux/create'
import App from './App'
import './styles/core.scss'

import polyfillElementClosest from 'element-closest'
polyfillElementClosest(window);

const rootElement = document.getElementById('root');
const store = createStore();

Modal.setAppElement(rootElement);

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    rootElement
);