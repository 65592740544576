import axios from 'axios'
import { getLocale } from 'locale/I18n'
import Environment from '@ifly/common-env'

// iFLY WordPress API path ;; e.g. https://www.iflyworld.com/wp-json/ifly_api/v1/menu/accpl_header
const API_PATH = '/wp-json/ifly_api/v1';

// Names of menu defined in WordPress -- https://www.iflyworld.com/wp-admin/nav-menus.php
const MENU_ACCPL_HEADER = 'accpl_header';
const MENU_ACCPL_FOOTER = 'accpl_footer_en-us';

export default class WordpressApi {
    /**
     * Gets the header navigation menu.
     * @returns {AxiosPromise}
     */
    static getHeaderMenu = () => WordpressApi.getMenu(MENU_ACCPL_HEADER);

    /**
     * Gets the footer navigation menu.
     * @returns {AxiosPromise}
     */
    static getFooterMenu = () => WordpressApi.getMenu(MENU_ACCPL_FOOTER);

    /**
     * Geolocates a visitor by their IP address (via http://ipinfo.io/).
     *  
     * Successful Lookup Example:
     *     {
     *         "ip": "174.44.255.46",
     *         "city": "Stamford",
     *         "region": "Connecticut",
     *         "country": "US",
     *         "loc": "41.0692,-73.5236",
     *         "hostname": "ool-ae2cff2e.static.optonline.net",
     *         "postal": "06906",
     *         "asn": {
     *             "asn": "AS6128",
     *             "name": "Cablevision Systems Corp.",
     *             "domain": "cablevision.com",
     *             "route": "174.44.0.0/16",
     *             "type": "isp"
     *        }
     *     }
     *
     * Unsuccessful Lookup Example:
     *     {
     *         "ip": "127.0.0.1",
     *         "bogon": true
     *     }
     *  
     * @returns {*}
     */
    static geolocateByIp = () =>
        WordpressApi.get('/wp-content/themes/ifly/xhr/ip.php')

    /**
     * Gets a menu from WordPress.
     * @param {String} menu The name of the menu to get. 
     * @returns {AxiosPromise}
     */
    static getMenu = name =>
        WordpressApi.get(`${API_PATH}/menu/${name}?lang=${getLocale().toLowerCase()}`);

    /**
     * Helper method to make an api GET request.
     * @param {String} url The requested url.
     * @returns {AxiosPromise}
     */
    static get = url => axios(Environment.wordPressServer() + url);

    /**
     * Helper method parses the flat Wordpress menu array into an array of 
     * top-level menus and a map of sub-menus (keyed by top-level menu ID).
     * @param {Object[]} items The flat WordPress menu taxonomy.
     * @returns {{top: Object, sub: Array}} An object representing the menu.
     */
    static buildTopAndSubMenus = items => {
        // group into top-level items and sub-menus keyed by top-level id

        let top = [], sub = {};

        for (let item of items) {
            let pid = item.menu_item_parent;
            if (pid && pid !== '0') {
                let menu = sub[pid] || (sub[pid] = []);
                menu.push(item); // child item
            } else {
                top.push(item); // top-level item
            }
        }

        return { top, sub };
    }

};
