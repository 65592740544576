import { all, call, put, takeLatest } from 'redux-saga/effects'
import { createModule } from '@ifly/redux-modules'
import MediaApi from 'api/MediaApi'

const Module = createModule({
    name: 'guestsearch',

    initialState: {
        guests: null,
        isLoading: false
    },

    actions: {
        requestGuests: { args:['date'], reducer:false, dispatch:true }, // reduced by saga
        loadingGuests: { },
        receiveGuests: { },
        failureGuests: { }
    }
});

export default Module;

export class GuestSearchSaga {
    static* watcher() {
        yield all([
            takeLatest(Module.constants.requestGuests, GuestSearchSaga.fetchGuests)
        ]);
    }

    /**
     * Fetch flight sessions from the local API for the given date.
     * @param {Object} action The action being dispatched
     */
    static* fetchGuests(action) {
        let { date } = action;

        let isLoading = true;
        yield put(Module.actions.loadingGuests({ isLoading }));

        let response = yield call(MediaApi.get, `/api/Guests/Date/${date}`),
            guests = null;

        isLoading = false;

        if (response) {
            guests = response.data;
            if (Array.isArray(guests)) {
                guests.sort((a,b) => a.guestName < b.guestName ? -1
                    : a.guestName > b.guestName ? 1 : 0)
            }
            yield put(Module.actions.receiveGuests({ isLoading, guests }));
        } else {
            // server error
            yield put(Module.actions.failureGuests({ isLoading, guests }));
        }
    }
}