import React, { Component } from 'react'
import PropTypes from 'prop-types'
import I18n, { getLanguages, localize } from 'locale/I18n'

import CommonUtil from 'util/CommonUtil'
import Svg from 'components/common/Svg'
import WpLink from 'components/link/WpLink'

const languages = getLanguages();

const i18n_language = <I18n $="header.language" iFlyUpperCase />
const i18n_location = <I18n $="header.location" />
const i18n_near_you = <I18n $="header.near_you" />

export const MENU_ID = 'language';

class HeaderNavMenuLanguage extends Component {

    onClick = (evt) => this.props.onMenuClick(evt, MENU_ID)
    
    createOnClick = code => evt => {
        evt.preventDefault();
        this.props.setLocale(code);
    }
    
    render() {
        let { selectedTunnel, nearbyTunnels } = this.props,
            [ tunnel ] = nearbyTunnels || [];

        let stateCode = tunnel && (tunnel.state_code || tunnel.stateCode); // support snake and camel case

        return (
            <li className="loc-menu nav-main">
                <ul role="menubar" aria-label="locale">
                    <li onClick={this.onClick} className={'locale menu' + (this.props.isOpen ? ' open' : '')}>
                        <div className="heading">
                            <h5 className="head">{ i18n_language }</h5>
                            <i className="rflag en"></i>
                            <i className="arrow">
                                <Svg id="chevron-right"/>
                            </i>
                        </div>
                        <ul className="sub-menu">
                            <li className="menu-head" onClick={this.onClick}>
                                <i className="arrow"><Svg id="chevron-right"/></i>
                                <h5 className="head">{ i18n_language }</h5>
                            </li>
                            {
                                languages.map(lang =>
                                    <li key={lang.code} className="opt" role="menuitem">
                                        <a href="#top" onClick={this.createOnClick(lang.code)}>
                                            <i className={`flag ${lang.code.toLowerCase()}`}></i>
                                            <h6>{lang.name}</h6>
                                            {lang.code===this.props.locale &&
                                               <i className="check"><Svg id="ic-checkmark"/></i>    
                                            }
                                        </a>
                                    </li>
                                )
                            }
                        </ul>
                    </li>
                </ul>
                { tunnel &&
                    <div className="location">
                        <div className="near">{ selectedTunnel ? i18n_location : i18n_near_you  }</div>
                        <div className="wrap">
                            <i className="icon"><Svg id="ic-pin"/></i>
                            <WpLink className="city" path={tunnel.slug}>{`${tunnel.city}, ${stateCode}`}</WpLink>
                            <a className="phone tel" href={CommonUtil.telUrl(tunnel.phone)} onClick={this.onClickTel}>{tunnel.phone}</a>
                        </div>
                    </div>
                }
            </li>
        )
    }

    static propTypes = {
        isOpen: PropTypes.bool.isRequired,
        nearbyTunnels: PropTypes.array,
        selectedTunnel: PropTypes.string,
        onMenuClick: PropTypes.func.isRequired,
        onMenuMouseEnter: PropTypes.func.isRequired,
        onMenuMouseLeave: PropTypes.func.isRequired
    }
}

export default localize(HeaderNavMenuLanguage);