import { all, call, put, takeLatest } from 'redux-saga/effects'
import { createModule } from '@ifly/redux-modules'
import MediaApi from 'api/MediaApi'

const Module = createModule({
    name: 'sale',

    initialState: {
        sale: null,
        error: false,
        transactionError: null,
        isLoading: false
    },

    actions: {
        resetSale: { reducer: false, dispatch: true },   // reduced by saga
        processSale: { reducer: false, dispatch: true }, // reduced by saga

        loadingSale: {},
        receiveSale: {},
        failureSale: {}
    },

    selector: {
        sale: state => state.sale.sale
    }
});

export default Module;

export class SaleSaga {
    static *watcher() {
        yield all([
            takeLatest(Module.constants.resetSale, SaleSaga.initSale),
            takeLatest(Module.constants.processSale, SaleSaga.processSale)
        ]);
    }

    /**
     * Resets the state for a new sale.
     * @param {Object} action The action being dispatched
     */
    static *initSale(action) {
        yield put(Module.actions.loadingSale({ ...Module.initialState }));
    }
    
    /**
     * Processes a sale.
     * @param {Object} action The action being dispatched
     */
    static *processSale(action) {
        let { type, ...payload } = action,
            isLoading = true,
            sale = null,
            error = false,           // standard server error
            transactionError = null; // transaction-related error
        
        yield put(Module.actions.loadingSale({ sale, error, transactionError, isLoading }));

        let response = yield call(MediaApi.post, '/api/Order/Sale', payload);
        
        isLoading = false;
        
        if (response) {
            sale = response.data || {};
            if (sale.status === 'SERVER_ERROR') {
                error = true;
                yield put(Module.actions.failureSale({ error, isLoading }));
            } else {
                if (sale.status === 'ERR') {
                    transactionError = sale.message;
                    yield put(Module.actions.failureSale({ sale, transactionError, isLoading }));
                } else {
                    yield put(Module.actions.receiveSale({ sale, isLoading }));
                }
            }
        } else {
            // server error
            error = true;
            yield put(Module.actions.failureSale({ error, isLoading }));
        }
    }
}