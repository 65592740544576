import { shoppingCart } from "cart";
import React, { PureComponent } from 'react';
import { Link } from '@reach/router'
import PropTypes from 'prop-types'
import MediaPropTypes from 'util/MediaPropTypes'
import { mediaSettings } from 'util/MediaContext'
import MediaUtil from 'util/MediaUtil'
import I18n, { localize } from 'locale/I18n'
import Svg from 'components/common/Svg';
import QrCode from 'qrcode.react';
import Cookies from 'js-cookie';

const COOKIE_OPTS = { path: '/', domain: '.iflyworld.com' };

class ConvergenceOrderDone extends PureComponent {

    componentDidMount() {
        let { clearCart, isKiosk } = this.props;
        let orderType = Cookies.get("orderType").toLowerCase();
        let orderEmail = (orderType === "creditcard") ? Cookies.get("orderEmail") : null;
        let pendingPaymentLink = (orderType === "creditcard") ? Cookies.get("pendingPaymentLink") : null;
        let orderDonePath = window.location.pathname;

        MediaUtil.scrollViewportTop();
        clearCart();
        this.orderType = orderType;
        this.orderEmail = orderEmail;
        this.pendingPaymentLink = pendingPaymentLink;
        
        if (isKiosk) {
            let secondsBeforeRedirect = (orderType === "creditcard") ? 45 : 15;

            setTimeout(() => {
                let currentPath = window.location.pathname;
                
                if (currentPath === orderDonePath) {
                    this.props.navigate('../../../start');
                }
            }, secondsBeforeRedirect * 1000);
        }

        Cookies.remove("orderType", COOKIE_OPTS);
        Cookies.remove("orderEmail", COOKIE_OPTS);
        Cookies.remove("pendingPaymentLink", COOKIE_OPTS);
    }

    render() {
        let { sessionTunnel, locale, isKiosk, lookupCode } = this.props,
            { mediaConfig } = sessionTunnel || {},
            { thankYouMessage } = mediaConfig || {};
        let orderType = this.orderType;
        let pendingPaymentLink = this.pendingPaymentLink;
        
        return sessionTunnel &&
            <div className="convergence-order-done">
                {/** Voucher Order Types **/}
                {orderType === "voucher" && isKiosk &&
                    <div>
                        <div className="heading"><I18n $="order.complete.thank_you" /></div>
                        <div className="message"><I18n $="order.complete.media_ready_soon" /></div>
                        <div className="brand"><Svg id="ifly-guy" /></div>
                        {thankYouMessage &&
                            <div className={`tunnel-message ${locale}`}
                                dangerouslySetInnerHTML={{ __html: thankYouMessage }} />
                        }
                    </div>
                }

                {orderType === "voucher" && !isKiosk &&
                    <div>
                        <div className="heading"><I18n $="order.complete.thank_you" /></div>
                        <div className="message"><span><Link to={`/flyer/${lookupCode}`}>Click here</Link> to view your media items!</span></div>
                        <div className="brand"><Svg id="ifly-guy" /></div>
                        <div className="empty-cart">
                            <Link to="/"><I18n $="order.browse_media" /></Link>{' '}
                            to purchase more items.
                        </div>
                    </div>
                }

                {/** Credit Card Order Types **/}
                {orderType === "creditcard" && isKiosk &&
                    <div>
                        <div className="heading"><I18n $="order.pending.heading" /></div>
                        <div className="message">You will receive an email to the provided email address with a link to provide your credit card information in private and complete your order.</div>
                        <div className="brand"><Svg id="ifly-guy" /></div>

                        <div className="qrcode-message"><I18n $="order.pending.qr_code_alternative" /></div>
                        <div className="item qrcode">
                            <div className="qrcode-action"><I18n $="kiosk.action.qrcode" /></div>
                            <QrCode size={192} renderAs="svg" value={pendingPaymentLink} />
                        </div>
                    </div>
                }
                
                {/** This should never happen, because cc payment + !isKiosk = redirect to convergence's cart **/}
                {orderType === "creditcard" && !isKiosk &&
                    <div>
                        <div className="heading"><I18n $="order.pending.oops_heading" /></div>
                        <div className="message"><I18n $="order.pending.oops_message" /></div>
                        <div className="brand"><Svg id="ifly-guy" /></div>
                    </div>
                }
            </div>
    }

    static propTypes = {
        // mediaSettings
        sessionTunnel: MediaPropTypes.sessionTunnel,

        // localize
        locale: PropTypes.string.isRequired
    }
}

export default localize(mediaSettings(shoppingCart(ConvergenceOrderDone)));