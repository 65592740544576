import React, { Component } from 'react'
import Environment from '@ifly/common-env'
import { i18n, localize } from 'locale/I18n'
import WebSection from './WebSection'
import TunnelDropdown from './TunnelDropdown'
import QueryString from 'query-string'
import Cookies from 'js-cookie'
import Url from 'url-parse'
import Path from 'util/PathUtil'

class LegacyView extends Component {
    
    shouldComponentUpdate(nextProps, nextState) {
        let { locale, tunnels, tunnelCode } = this.props;
        return locale!==nextProps.locale 
            || tunnels!==nextProps.tunnels 
            || tunnelCode!==nextProps.tunnelCode;
    }
    
    iframeUrl = () => {
        let { locale, tunnelCode } = this.props;
        let qs = QueryString.parse(window.location.search);

        // The iframe is always passed the query string of the page; however,
        // the parameters: tunnels, domain, language, and voucher_number are
        // explicitly set if not already in the query string.
        //
        let voucher = Cookies.get('vouchers');
        if (!qs.voucher_number && voucher) qs.voucher_number = voucher;
        if (!qs.tunnel) qs.tunnel = tunnelCode;
        if (!qs.domain) qs.domain = (new Url(Environment.wordPressServer())).hostname;
        if (!qs.language) qs.language = locale; // TODO: is pt-BR accurate for legacy? 

        return `${Path.REDIRECT}?${QueryString.stringify(qs)}`;
    }
    
    render() {
        let { tunnels, tunnelCode='', onChangeLocation } = this.props;
        if (!tunnels) return null;

        return (
            <WebSection className="legacy-view">
                <TunnelDropdown value={tunnelCode} tunnels={tunnels} onChange={onChangeLocation} />
                <iframe src={this.iframeUrl()} allowFullScreen title={i18n('media.application_name')}></iframe>
            </WebSection>
        )
    }
}

export default localize(LegacyView);