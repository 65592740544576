import React, { PureComponent } from 'react'
import TunnelContext from 'util/TunnelContext'
import WebSection from './WebSection'
import Storefront from 'components/Storefront'

export default class WebView extends PureComponent {
    static contextType = TunnelContext
    
    syncContextTunnel = () => {
        let { tunnelCode } = this.props;

        if (tunnelCode !== this.context.tunnel) {
            this.context.setTunnel(tunnelCode);
        }
    }

    componentDidMount() {
        this.syncContextTunnel();
    }

    componentDidUpdate(prevProps) {
        if (this.props.tunnelCode!==prevProps.tunnelCode) {
            this.syncContextTunnel();
        }
    }
    
    render() {
        if (!this.context.tunnel) return null;

        return (
            <WebSection className="web-view">
                <Storefront className="web-body" tunnel={this.context.tunnel} isKiosk="false" />
                { this.props.children }
            </WebSection>
        )
    }
}