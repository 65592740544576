import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { shoppingCart } from 'cart'
import KioskKeyboard from '@ifly/react-kiosk-keyboard'
import I18n from 'locale/I18n'
import sale from 'redux/modules/sale'
import scanner from 'redux/modules/scanner'
import { createSelector } from 'reselect'
import MediaPropTypes from 'util/MediaPropTypes'
import MediaUtil from 'util/MediaUtil'
import Path from 'util/PathUtil'
import CheckoutForm from './CheckoutForm'
import Loading from 'components/misc/Loading'
import Alert from 'components/misc/Prompt'
import OrderSummary from 'components/cart/OrderSummary'

const FORM_ID = 'kiosk-checkout';

const DEV_USE_TEST_VALUES = false;
const DEV_TEST_VALUES = {
    firstName: 'Dev01',
    lastName: 'DevName',
    email: 'dev01@iflytest.com',
    phone: '+15125551212',
    address1: '123 Main ST',
    city: 'Allston',
    state: 'MA',
    postalCode: '02134',
    cardNumber: '5454545454545454',
    expirationMonth: '01',
    expirationYear: '2022',
    cvv: '123'
};

class KioskCheckout extends PureComponent {
    state = {
        showServerError: false,
        showTransactionError: false,
        formValues: DEV_USE_TEST_VALUES ? DEV_TEST_VALUES : {}
    }

    enterVoucher = () => {
        let {navigate, kioskTunnelCode} = this.props,
            voucherUrl = `/${Path.KIOSK}/${kioskTunnelCode}/${Path.VOUCHER}`;
        navigate(voucherUrl);
    }

    hideServerError = () => this.setState({ showServerError:false })
    hideTransactionError = () => this.setState({ showTransactionError:false })

    setFormikRef = formik => this.form = formik
    onSubmitForm = evt => this.form.submitForm()

    processSale = (values, form) => {
        this.props.actions.processSale(values);
        this.setState(prevState => ({
            ...prevState,
            formValues: values
        }));
    }

    componentDidUpdate(prevProps) {
        let { error, transactionError, isLoading, navigate, sale, wristband } = this.props,
            { formValues } = this.state;

        if (sale && !isLoading && !error) {
            if (sale.status === 'OK') {
                // sale result succeeded, move to confirmation page
                navigate(`../${Path.DONE}`);
            }
        }

        if (isLoading && !prevProps.isLoading) {
            this.props.setHeaderButton(null);
        } else if (!isLoading && prevProps.isLoading) {
            this.props.setHeaderButton(
                <button onClick={this.onSubmitForm}>
                    <I18n $="button.place_order" iFlyUpperCase/>
                </button>
            );
        }
        
        if (wristband && wristband !== prevProps.wristband) {
            // customer waited until checkout to scan wristband
            this.form.setValues({ ...this.form.values, ...wristband });
        }
        
        // Check formValues so that we only display any errors if the user has submitted the form.
        // Otherwise, a residual error may exist from a prior submission, triggering an alert.

        if (formValues && error && !prevProps.error) {
            this.setState({ showServerError:true });
        }

        if (formValues && transactionError && !prevProps.transactionError) {
            this.setState({ showTransactionError:true });
        }

    }

    componentWillUnmount() {
        // restore the header button to default state
        this.props.setHeaderButton();
    }

    componentDidMount() {
        this.props.actions.resetSale();
        MediaUtil.scrollViewportTop(true);
        this.props.setHeaderButton(
            <button onClick={this.onSubmitForm}>
                <I18n $="button.place_order" iFlyUpperCase/>
            </button>
        );
    }

    render() {
        let { isLoading, transactionError, wristband={}, vouchers } = this.props,
            { showServerError, showTransactionError, formValues } = this.state;

        return <>
            {
                showServerError &&
                <Alert onClose={this.hideServerError}><I18n $="error.server"/></Alert>
            }
            {
                showTransactionError &&
                <Alert onClose={this.hideTransactionError}>{transactionError}</Alert>
            }

            {isLoading && <Loading/>}

            <div className="checkout">
                <div className="view-head">
                    <I18n $="order.complete_your_order"/>
                </div>
                <div className="view-body">
                    <CheckoutForm formId={FORM_ID}
                                  formRef={this.setFormikRef}
                                  onSubmit={this.processSale}
                                  values={{...wristband, ...formValues}}/>

                    <OrderSummary button={<>
                        <button onClick={this.onSubmitForm} className="btn-primary">
                            <I18n $="button.place_order" iFlyUpperCase/>
                        </button>
                        {
                            !vouchers?.length &&
                            <button onClick={this.enterVoucher} className="btn-secondary">
                                <I18n $="button.apply_voucher" iFlyUpperCase/>
                            </button>
                        }
                    </>}
                    />
                </div>

                <KioskKeyboard formId={FORM_ID}/>
            </div>
        </>
    }

    static propTypes = {
        // shoppingCart
        cartItems: MediaPropTypes.media,

        // redux -- sale.connect()
        error: PropTypes.bool,
        isLoading: PropTypes.bool.isRequired,
        sale: PropTypes.object,
        transactionError: PropTypes.string,

        actions: PropTypes.shape({
            processSale: PropTypes.func.isRequired
        }).isRequired,

        // redux -- createSelector :: scanner.vouchers
        vouchers: MediaPropTypes.vouchers,

        // redux -- createSelector :: scanner.wristband.flyer
        wristband: MediaPropTypes.flyer,

        // reach router
        navigate: PropTypes.func
    };
}

const selector = createSelector(
    sale.selector.default,
    scanner.selector.vouchers,
    scanner.selector.wristbandFlyer,
    (sale = {}, vouchers, wristband) => ({...sale, vouchers, wristband})
);

export default shoppingCart(sale.connect(KioskCheckout, selector));