import React, { Component } from 'react'
import PropTypes from 'prop-types'

import I18n from 'locale/I18n'
import Svg from 'components/common/Svg'
import TunnelUtil from 'util/TunnelUtil'

import WpLink from 'components/link/WpLink'

const i18n_usa = <I18n $="country.usa" />
const i18n_international = <I18n $="country.international" />

/**
 * This locations view is hosted within a PopupMenu which passes down a
 * `closePopup` property used to close the popup programmatically below.
 */
export default class LocationsPopup extends Component {

    /**
     * Constructor establishes element references so the list is scrolled
     * automatically to the selected tunnel.
     * 
     * @param {Object} props The component props.
     */
    constructor(props) {
        super(props);
        this.selRef = React.createRef(); 
        this.listRef = React.createRef(); 
    }

    /// Returns a click handler to change location.
    /// @param tunnel The tunnel.
    /// @return An event handler function.
    createOnClick = tunnel => evt => {
        evt.preventDefault(); // do not navigate the anchor
        this.props.onChangeTunnel(tunnel);
        this.props.closePopup();
    }
    
    /**
     * Renders the <li> elements for the state/country and its tunnels.
     * @param group The tunnel group (e.g. usa or international).
     * @param selected The currently selected tunnel.
     */
    renderLocations = (group, selected) => group.regions.map(state =>
        <React.Fragment key={state}>
            <li className="grp"><h6>{ state }</h6></li>
            {
                group.tunnels[state].map(tunnel => {
                    let code = tunnel.tunnel || tunnel.code; // support old and new model name for tunnel code 
                    let isSelected = code === (selected.tunnel || selected.code); // support old and new model name for tunnel code
                    return (
                        <li key={tunnel.name}
                            ref={isSelected ? this.selRef : null}    
                            className={'opt' + (isSelected ? ' sel' : '')}>
                            <WpLink path={tunnel.slug} onClick={this.createOnClick(code)}>
                                { tunnel.display_name || tunnel.displayName /* support snake and camel case */ }
                                { isSelected && <i className="check"><Svg id="ic-checkmark"/></i> }
                            </WpLink>
                        </li>
                    )
                })
            }
        </React.Fragment>
    )

    /**
     * Scrolls the selected tunnel into view in the list.
     */
    scrollSelectionIntoView = () => {
        let li = this.selRef.current,
            ul = this.listRef.current;

        if (li && ul) {
            ul.scrollTop = li.offsetTop - 50;
        }
    }

    /**
     * Scrolls the selected tunnel into view in the list when the component 
     * first mounts.
     */
    componentDidMount() {
        this.scrollSelectionIntoView();
    }

    /**
     * Scrolls the selected tunnel into view in the list when the component
     * updates.
     */
    componentDidUpdate(prevProps) {
        let { nearbyTunnels } = this.props;
        if (nearbyTunnels!==prevProps.nearbyTunnels) {
            this.scrollSelectionIntoView();
        }
    }
    
    render() {
        let { nearbyTunnels } = this.props; 
        
        let selected = nearbyTunnels?.length ? nearbyTunnels[0] : {};
        
        let tunnels = this.props.tunnels
            ? TunnelUtil.groupTunnelsByRegion(this.props.tunnels)
            : null;
        
        return (
            <ul ref={this.listRef} className="body" role="menu">
                <li><h4>{ i18n_usa }</h4></li>
                { tunnels && this.renderLocations(tunnels.usa, selected) }

                <li><h4>{ i18n_international }</h4></li>
                { tunnels && this.renderLocations(tunnels.international, selected) }
            </ul>
        )
    }
    
    static propTypes = {
        tunnels: PropTypes.array,
        nearbyTunnels: PropTypes.array,
        onChangeTunnel: PropTypes.func.isRequired
    }
}