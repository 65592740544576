import React from 'react'
import PropTypes from 'prop-types'
import { Router } from '@reach/router'
import FlyerSearch from 'components/search/FlyerSearch'
import GroupView from 'components/browse/GroupView'
import GuestView from 'components/browse/GuestView'
import Path from 'util/PathUtil'

const MediaFinder = React.memo(function MediaFinder(props) {
    let { '*':wildcard, dateSlug } = props;

    if (!dateSlug) return null;

    return (
        <Router primary={false}>
            <FlyerSearch default wildcard={wildcard} dateSlug={dateSlug} />
            <GroupView path={`${Path.SESSION}/:sessionId/*`} />
            <GuestView path={`${Path.GUEST}/:guestId`} />
        </Router>
    )
});

MediaFinder.propTypes = {
    // reach-router
    '*': PropTypes.string,
    dateSlug: PropTypes.string
};

export default MediaFinder;