import React, { PureComponent } from 'react';
import PropTypes from 'prop-types'
import MediaPropTypes from 'util/MediaPropTypes'
import { Router, navigate } from '@reach/router'
import Header from 'components/common/Header';
import Footer from 'components/common/Footer';
import { shoppingCart } from 'cart'

import tunnels from 'redux/modules/tunnels'
import WebView from './WebView'
import LocationView from './LocationView'

import Path from 'util/PathUtil'
import TunnelContext from 'util/TunnelContext'

import MediaRedirect from 'components/MediaRedirect'
import DownloadView from './DownloadView';
import LegacyView from './LegacyView'

const cleanMediaUrl = url =>
    Path.DATE_URL.test(url) ? url : `${Path.cleanTunnelUrl(url)}/${Path.datePath()}`

class WebLayout extends PureComponent {
    static contextType = TunnelContext
    
    legacyNavigation = code => {
        let { tunnels } = this.props;
        if (!code || !tunnels) return;

        let tunnel = tunnels.find(t => t.code===code);
        if (!tunnel) return;
        
        if (tunnel.isNewMedia) {
            if (code !== this.context.tunnel || 
                !Path.TUNNEL_URL.test(window.location.pathname)) {
                // only navigate to the tunnel root if the tunnel
                // changes or if we are not in a tunnel deep-link
                navigate(`${Path.TUNNEL}/${code}`);
            }
        } else {
            navigate(`${Path.LEGACY}/${code}${window.location.search}`);
        }
    }
    
    setLocation = code => {
        let path = window.location.pathname;
        
        // These paths should ignore tunnel location change events:
        if (Path.FLYER_URL.test(path) ||
            Path.CHOOSE_TUNNEL_URL.test(path)) return;

        this.legacyNavigation(code);
    }
    
    componentDidMount() {
        let { tunnels, isLoading } = this.props;
        if (!tunnels && !isLoading) {
            this.props.actions.requestTunnels();
        }
    }
    
    render() {
        let { tunnels, isLoading } = this.props;

        return <>
            <Header tunnels={tunnels} onChangeLocation={this.setLocation}/>

            <main className="web clear-nav">
                <Router primary={false}>
                    <LocationView default path={Path.CHOOSE_TUNNEL}
                                  tunnels={tunnels} 
                                  isLoading={isLoading} 
                                  onChangeLocation={this.legacyNavigation} />
                    <DownloadView path={`${Path.FLYER}/:lookupCode`} />
                    <WebView path={`${Path.TUNNEL}/:tunnelCode`}>
                        <MediaRedirect path="/" fn={cleanMediaUrl} note="WebLayout redirect(cleanMediaUrl) to Storefront"/>
                    </WebView>
                    <LegacyView path={`${Path.LEGACY}/:tunnelCode`} 
                                tunnels={tunnels}
                                onChangeLocation={this.legacyNavigation} />
                </Router>
            </main>
            
            <Footer />
        </>
    }
    
    static propTypes = {
        // shoppingCart
        addToCart: PropTypes.func.isRequired,
        removeFromCart: PropTypes.func.isRequired,
        cartItems: MediaPropTypes.media,

        // redux -- tunnels.connect()
        tunnels: MediaPropTypes.tunnels,
        isLoading: PropTypes.bool.isRequired,
        actions: PropTypes.shape({
            requestTunnels: PropTypes.func.isRequired
        }).isRequired,
    }
}

export default shoppingCart(tunnels.connect(WebLayout));