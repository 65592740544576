import React, { PureComponent, isValidElement } from 'react'
import PropTypes from 'prop-types'
import MediaPropTypes from 'util/MediaPropTypes'
import I18n from 'locale/I18n'
import { mediaSettings } from 'util/MediaContext'
import MediaUtil from 'util/MediaUtil'
import { Modal } from 'components/common/Modal'
import MediaDetail from './MediaDetail'
import Thumbnail from './Thumbnail'

/// Renders thumbnails for videos and photos.
///
/// EXAMPLE
///
///     <MediaGrid media={media}
///                mediaDetailActions={item =>
///                    <div className="purchase-actions">
///                        <a className="purchased-btn" href="."><i className="icon download"></i></a>
///                        <a className="purchased-btn" href="."><i className="icon facebook"></i></a>
///                        <a className="purchased-btn" href="."><i className="icon instagram"></i></a>
///                        <a className="purchased-btn" href="."><i className="icon tiktok"></i></a>
///                    </div>
///                } />
///
/// EXAMPLE
///
///      <MediaGrid videos={videos}
///                 photos={photos}
///                 mediaDetailActions={this.renderMediaDetailAction} />
///
/// See proptypes for the fields in the media item passed in the `mediaDetailActions`.
///
class MediaGrid extends PureComponent {

    state = {
        selectedId: null
    }

    showPreview = selectedId => {
        this.setState({ selectedId });
    }

    getMediaLabel = (label, price) =>
        this.props.showPrice && price > 0
            ?
            <>
                { label }{': '}
                <span className="price"><I18n $="order.currency" amount={price} /></span>
            </>
            :
            label;
    
    getVideoLabel = () => this.getMediaLabel(
        <I18n $="media.videos" />, this.props.sessionTunnel?.products?.video?.price)
    
    getPhotoLabel = () => this.getMediaLabel(
        <I18n $="media.photos" />, this.props.sessionTunnel?.products?.photo?.price)

    hidePreview = () => { this.setState({selectedId: null}) }

    render() {
        let { media, photos, videos, mediaDetailActions, isLoading } = this.props,
            { selectedId } = this.state;
        
        if (photos && videos) {
            // passed separately, need to aggregate the photos and videos for the slider
            // but be sure to filter out any non-media items (e.g. elements)
            media = [
                ...MediaUtil.filterVideos(videos),
                ...MediaUtil.filterPhotos(photos)
            ];
        } else if (media) {
            // passed together, order the videos before the photos
            videos = MediaUtil.filterVideos(media);
            photos = MediaUtil.filterPhotos(media);
            media = [ ...videos, ...photos ];
        }
        return (

            <div className="media-grid">
                {
                    selectedId!==null &&
                    <Modal onClose={this.hidePreview}>
                        <MediaDetail media={media}
                                     initialId={selectedId}
                                     renderItemActions={mediaDetailActions} />
                    </Modal>
                }
                {
                    videos.filter(item => !isValidElement(item)).length > 0 && 
                    <div className="video group">
                        <div className="section-heading">{ this.getVideoLabel() }</div>
                        <div className="thumbnails">
                            {
                                videos.map(item => isValidElement(item) ? item :
                                    <Thumbnail key={item.id}
                                               id={item.id}
                                               url={item.thumbnailUrl}
                                               selected={item.selected}
                                               isHighFlight={item.isHighFlight}
                                               onClick={this.showPreview} 
                                               onRemove={this.props.checkoutMode ? this.props.onRemove : null} 
                                               isLoading={isLoading}/>
                                )
                            }
                        </div>
                    </div>
                }
                {
                    photos.filter(item => !isValidElement(item)).length > 0 && 
                    <div className="photo group">
                        <div className="section-heading">{ this.getPhotoLabel() }</div>
                        <div className="thumbnails">
                            {
                                photos.map(item => isValidElement(item) ? item :
                                    <Thumbnail key={item.id}
                                               id={item.id}
                                               url={item.thumbnailUrl}
                                               selected={item.selected}
                                               onClick={this.showPreview}
                                               onRemove={this.props.checkoutMode ? this.props.onRemove : null}
                                               rankValue={item.rankValue || -1} 
                                               isLoading={isLoading}/>
                                )
                            }
                        </div>
                    </div>
                }
            </div>
        )
    }

    static defaultProps = {
        mediaDetailActions: media => null,
        showPrice: false,
        onRemove: null,
        checkoutMode: false,
    }

    static propTypes = {
        showPrice: PropTypes.bool,
        mediaDetailActions: PropTypes.func.isRequired,
        onRemove: PropTypes.func,
        checkoutMode: PropTypes.bool,
        isLoading: PropTypes.bool,

        // pass either a combined array `media` prop
        media: PropTypes.arrayOf(MediaPropTypes.selectableMedia),

        // or individual array props `photos` and `videos`
        photos: PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.element,
            MediaPropTypes.selectablePhoto
        ])),
        
        videos: PropTypes.arrayOf(PropTypes.oneOfType([
            PropTypes.element,
            MediaPropTypes.selectableVideo
        ]))
    }
}

export default mediaSettings(MediaGrid);