import React from 'react'

/**
 * This context holds data shared across the application.
 *
 * NOTE: Initially this export was created in App.js (not the default export)
 * but an ES6 module import circular dependency left the context empty in all
 * consumers.  The simple fix was to put this create call in its own file.
 *
 * To access this information from your own class import the context below
 * then define a static contextType property in your class and set it equal
 * to the imported context.  The values will be accessible from a `context`
 * property on your class.
 *
 * The static `contextType` approach is an alternative to this syntax:
 *
 *     <AppContext.Consumer>
 *         { value => <SomeThingToRender ... /> }
 *     </AppContext.Consumer>
 *
 */

const TunnelContext = React.createContext({
    tunnel: undefined,
	venueId: undefined,
    setTunnel: () => {},
	setVenueId: () => {}
});

export default TunnelContext;