import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import MediaPropTypes from 'util/MediaPropTypes'
import I18n from 'locale/I18n'
import Svg from 'components/common/Svg';
import { mediaSettings } from 'util/MediaContext'

const LOADING = 'loading', // video state
      PLAY = 'play',       // video state/event
      PAUSE = 'pause',     // video state/event
      ENDED = 'ended',     // video state/event 
      TIMEUPDATE = 'timeupdate'; // video event

// Prevent timeline events from reaching the carousel. 
function stopPropagation(evt) {
    evt.stopPropagation();
}

// Converts seconds into `M:SS` format. 
function fmtVideoDuration(seconds) {
    let sec = seconds < 0 || isNaN(seconds) ? 0 : seconds, 
        m = parseInt(sec / 60),
        s = parseInt(sec % 60);
    return `${m}:${ s<10 ? `0${s}` : s }`;
}

class KioskVideo extends PureComponent {

    state = {
        end: null,
        seek: null,
        start: null,
        played: false,
        duration: null,
        trackLeft: null,
        videoState: LOADING,
        canPauseVideo: false
    }
    
    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
        this.handleRef = React.createRef();
    }
    
    /// The timeline is either paused between 0 and 100% or is animating to
    /// 100% with a css width transition the same duration as the remaining
    /// playback time.
    ///
    /// Pass no params to stop the timeline at its current location. Pass a
    /// single param to move the timeline to the given position (in percent)
    /// or pass both params to begin the timeline animation.  
    setTimelineHandle = (width, remainingPlaybackTime) => {
        let handle = this.handleRef.current;
        if (!handle) return;
        
        if (width===undefined) {
            let max = handle.parentElement.offsetWidth;
            width = max ? `${ handle.offsetWidth/max*100 }%` : 0;
        }
        
        handle.style.width = width;
        handle.style.transition = remainingPlaybackTime
            ? `width ${remainingPlaybackTime}s linear 0s` : '';
    }
    
    /// Pauses the video (if currently playing). 
    maybePauseVideo = () => {
        let { canPauseVideo, videoState } = this.state,
            video = videoState===PLAY ? this.videoRef.current : null;
        
        if (!video || !canPauseVideo) return false;
        video.pause();
        return true;
    }
    
    playVideo = () => {
        let video = this.videoRef.current;
        if (!video) return;
        
        // The canPauseVideo state flag and the promise below are part of:
        // https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
        
        if (this.state.canPauseVideo) {
            this.setState({ canPauseVideo: false });
        }
        
        let promise = video.play();
        
        if (promise) promise
            .then(() => {
                this.setState({ canPauseVideo: true });
            })
            .catch(error => {
                this.setState({ canPauseVideo: false });
                console.info('playVideo Promise error: ', error);
                // TODO: maybe need to call this.onPause() ?
            });
    }
    
    /// Video overlay click handler to start/stop playback. 
    togglePlayback = evt => {
        evt.stopPropagation();
        
        if (this.maybePauseVideo()) return;

        let video = this.videoRef.current;
        if (!video) return;

        // keep playback constrained between start/end time
        let { start, end } = this.state;
        let time = video.currentTime;
        
        if (time >= end || time <= start) {
            // move the video back to start
            this.setTimelineHandle('0%');
            video.currentTime = start;
        }
        this.playVideo();
        if (!this.state.played) this.setState({ played:true });
    }

    /// Video player event fired when playback may begin.
    onCanPlayThrough = () => {
        let video = this.videoRef.current;
        
        if (video && this.state.videoState===LOADING) {
            video.addEventListener(PLAY, this.onPlay);
            video.addEventListener(PAUSE, this.onPause);
            video.addEventListener(ENDED, this.onPause);

            // apply tunnel's media kiosk application video preview configuration
            let { mediaConfig } = this.props.sessionTunnel,
                pStop = parseInt(mediaConfig.videoPreviewStop, 10),
                pStart = parseInt(mediaConfig.videoPreviewStart, 10);
            if (isNaN(pStart) || isNaN(pStop) || pStop <= pStart) {
                /// default preview start/end times (in seconds)
                pStop = 30;
                pStart = 10;
            }

            let pDuration = pStop - pStart,
                duration = video.duration,
                start, end;

            if (duration < pDuration) {
                end = duration;
                start = 0;
            } else if (duration < pStop) {
                end = duration;
                start = end - pDuration
            } else {
                start = pStart;
                end = start + pDuration;
            }

            this.setState({ start, end, duration, videoState: PAUSE });
        }
    }
    
    /// Video player event fired when playback begins.
    onPlay = () => {
        let video = this.videoRef.current;
        if (!video) return;

        this.setTimelineHandle('100%', this.state.end - video.currentTime);
        
        video.addEventListener(TIMEUPDATE, this.onTimeUpdate);
        this.setState({ videoState: PLAY });
    }

    /// Video player event fired when playback is paused.
    onPause = () => {
        let video = this.videoRef.current;
        if (!video) return;

        video.removeEventListener(TIMEUPDATE, this.onTimeUpdate);

        // freeze jog handle at current position unless the video was
        // paused because the user is interacting with the video timeline
        if (!this.state.seek) this.setTimelineHandle();

        // limit video playback
        if (video.currentTime >= this.state.end) {
            video.currentTime = this.state.end; 
        }

        this.setState({ videoState: PAUSE });
    }

    /// Video player event fired when during playback.
    onTimeUpdate = evt => {
        let video = this.videoRef.current;

        // limit video playback
        if (video?.currentTime >= this.state.end) {
            video.pause();
        }
    }

    /// Removes added event listeners before component unmount.
    componentWillUnload() {
        let video = this.videoRef.current;
        if (video) {
            video.removeEventListener(PLAY, this.onPlay);
            video.removeEventListener(PAUSE, this.onPause);
            video.removeEventListener(ENDED, this.onPause);
            video.removeEventListener(TIMEUPDATE, this.onTimeUpdate);
        }
    }
    
    /// Sets the video play position and timeline to the new position.
    setTimelinePosition = (clientX, trackLeft, trackWidth) => {
        let video = this.videoRef.current;
        if (!video) return;

        let { start, end } = this.state,
            position = (clientX - trackLeft) / trackWidth,
            duration = end - start;
    
        // update timeline and video time (this pauses the video)
        this.setTimelineHandle(`${position*100}%`);
        video.currentTime = start + (duration) * position;
    }
    
    /// Timeline handler saves the video state when the change begins.
    onPointerDown = evt => {
        // don't let the event reach the carousel
        evt.stopPropagation();

        let handle = this.handleRef.current;
        let el = handle ? handle.closest('.timeline') : null;
        if (!el) return;
        
        let seek = {
            trackLeft: el.getBoundingClientRect().left,
            trackWidth: el.offsetWidth,
            videoState: this.state.videoState
        };
        
        this.setTimelinePosition(evt.clientX, seek.trackLeft, seek.trackWidth);
        this.setState({ seek });
    }

    /// Timeline handler updates the timeline and video play position.
    onPointerMove = evt => {
        // don't let the event reach the carousel
        evt.stopPropagation();

        let { seek } = this.state;
        if (seek) {
            this.setTimelinePosition(evt.clientX, seek.trackLeft, seek.trackWidth);
        }
    }

    /// Timeline handler maybe resumes playback after the change ends. 
    onPointerUp = evt => {
        let video = this.videoRef.current;
        let { seek } = this.state;

        // resume playback (if playing on pointerDown)
        if (video && seek && PLAY===seek.videoState) this.playVideo();

        this.setState({ seek: null });
    }
    
    render() {
        let { video, preload } = this.props;
        let { videoState, seek, played, duration } = this.state;
        
        return (
            <div className="kiosk video-wrap">
                
                <div className={`video-ct ${videoState}${seek ? ` ${seek.videoState}` : ''}`}>
                    {
                        duration &&
                        <div className="video-duration">
                            { fmtVideoDuration(duration) }
                        </div>
                    }
                    {
                        played &&
                        <div className="kiosk-watermark">
                            <div className="watermark-el">
                                <i className="logo"><Svg id="ifly-logo"/></i>
                                <div className="label"><I18n $="media.video_preview"/></div>
                            </div>
                        </div>
                    }

                    <video muted playsInline
                           ref={this.videoRef}
                           poster={video.thumbnailUrl}
                           preload={preload ? 'auto' : 'none'}
                           onWaiting={this.maybePauseVideo}
                           onStalled={this.maybePauseVideo}
                           onCanPlayThrough={this.onCanPlayThrough}>
                        <source src={video.mediaUrl} type="video/mp4"/>
                    </video>
                    
                    <div className="loading-ring">
                        <div className="icon big-bg-disc"></div>
                    </div>
                    
                    <button className="play-toggle" onClick={this.togglePlayback}>
                        <div className="big-bg-disc">
                            <i className="icon"><Svg id="ic-arrow-down-small"/></i>
                        </div>
                    </button>
                </div>
                <div className="video-disclaimer">
                    <I18n $="media.sample_video_disclaimer" />
                    <br/>
                    <I18n $="media.purchased_video_info" />
                </div>

                {
                    <div className={`timeline${ played ? ' visible' : '' }`}
                         onPointerUp={this.onPointerUp}
                         onPointerMove={this.onPointerMove}
                         onPointerDown={this.onPointerDown}
                         onMouseDown={stopPropagation}
                         onTouchStart={stopPropagation}>
                        <div className="track">
                            <div className="handle" ref={this.handleRef}></div>
                        </div>
                    </div>
                }
            </div>
        )
    }
    
    static propTypes = {
        // mediaSettings
        sessionTunnel: MediaPropTypes.sessionTunnel.isRequired,

        // passed props
        preload: PropTypes.bool.isRequired,
        video: MediaPropTypes.video.isRequired
    }
}

export default mediaSettings(KioskVideo);