import React from 'react'
import PropTypes from 'prop-types'
import I18n from 'locale/I18n'

/// Receipt line item
const LineItem = React.memo(function LineItem(props) {
    let { value, className } = props;

    if (value===null) value = '-';

    return (
        <div className={className ? `entry ${className}` : 'entry'}>
            <div className="label">{ props.children }</div>
            {
                isNaN(value)
                    ?
                    <div className="value">{ value }</div>
                    :
                    <div className={`value${ value < 0 ? ' neg' : '' }`}>
                        <I18n $="order.currency" amount={ Math.abs(value).toFixed(2) }/>
                    </div>
            }
        </div>
    )
});

LineItem.propTypes = {
    value: PropTypes.number,
    className: PropTypes.string
}

export default LineItem;