import { all, call, put, takeLatest } from 'redux-saga/effects'
import { createModule } from '@ifly/redux-modules'
import MediaApi from 'api/MediaApi'

const Module = createModule({
    name: 'timesearch',
    
    initialState: {
        mediaSessions: null,
        isLoading: false
    },
    
    actions: {
        requestMediaSessions: { args:['date'], reducer:false, dispatch:true }, // reduced by saga
        loadingMediaSessions: { },
        receiveMediaSessions: { },
        failureMediaSessions: { }
    }
});

export default Module;

export class TimeSearchSaga {
    static* watcher() {
        yield all([
            takeLatest(Module.constants.requestMediaSessions, TimeSearchSaga.fetchMediaSessions)
        ]);
    }

    /**
     * Fetch flight sessions from the local API for the given date.
     * @param {Object} action The action being dispatched
     */
    static* fetchMediaSessions(action) {
        let { date } = action;
        
        let isLoading = true;
        yield put(Module.actions.loadingMediaSessions({ isLoading }));
        
        let response = yield call(MediaApi.get, `/api/MediaSessions/${date}`),
            mediaSessions = null;

        isLoading = false;
        
        if (response) {
            mediaSessions = response.data;
            if (!Array.isArray(mediaSessions)) {
                if (false===mediaSessions?.success) {
                    console.warn('Error fetching media sessions: ', mediaSessions.message);
                } else {
                    console.warn('Unknown error fetching media sessions.', mediaSessions);
                }
                mediaSessions = [];
            }
            yield put(Module.actions.receiveMediaSessions({ isLoading, mediaSessions }));
        } else {
            // server error
            yield put(Module.actions.failureMediaSessions({ isLoading, mediaSessions }));
        }
    }
}