import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { i18n, localize } from 'locale/I18n'
import MediaContext from 'util/MediaContext'
import Svg from 'components/common/Svg';

class Thumbnail extends PureComponent {
    static contextType = MediaContext
    
    onClick = evt => {
        evt.preventDefault();
        evt.stopPropagation();
        this.props.onClick(this.props.id);
    }
    
    render() {
        let { url, selected, rankValue, isHighFlight, isLoading } = this.props;

        return (
            <div data-rank={rankValue} className="thumb" onClick={this.onClick} title={i18n('media.show_fullsize')}>
                <figure style={{ backgroundImage: `url(${url})` }}></figure>
                {
                    selected &&
                    <i className="selected">
                        <Svg id="ic-checkmark" title={i18n('media.selected')}/>
                    </i>
                }
                {
                    isHighFlight &&
                    <i className="highflight"></i>
                }
                {
                    !isLoading && this.props.onRemove &&
                    <button className="removeCartX" onClick={this.props.onRemove} data-id={this.props.id}><Svg id="ic-close"/></button>
                }
            </div>
        )
    }
    
    static propTypes = {
        id: PropTypes.number.isRequired,
        url: PropTypes.string.isRequired,
        isHighFlight: PropTypes.bool,
        selected: PropTypes.bool,
        onRemove: PropTypes.func,
        isLoading: PropTypes.bool,
    }
}

export default localize(Thumbnail);