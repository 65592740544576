import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import I18n, { localize, i18n } from 'locale/I18n'
import { Field, Form, Formik } from 'formik'
import order from 'redux/modules/order'
import { scrollToError } from 'util/DomUtil'
import StringUtil from 'util/StringUtil'
import FieldError from '../fields/FieldError'


class ConvergenceCheckoutForm extends PureComponent {

    validateForm = values => {
        let errors = {};

        if (StringUtil.isBlank(values.firstName)) errors.firstName = i18n('checkout.error.first_name');
        if (StringUtil.isBlank(values.lastName)) errors.lastName = i18n('checkout.error.last_name');
        if (!emailIsValid(values.email)) errors.email = i18n('checkout.error.email');

        setTimeout(scrollToError, 0);
        return errors;
    }

    formRenderer = formik => {
        let { formId, formRef } = this.props;
        if (formRef) formRef(formik);

        //const { values } = formik;

        return (
            <Form id={formId} className="convergence-checkout-form">
                <div className="col">
                    <div className="row">
                        <div className="field">
                            <label>
                                <b><I18n $="checkout.first_name" /></b>
                                <Field name="firstName" type="text" autoFocus data-kiosk-auto-capitalize />
                            </label>
                            <FieldError fieldName="firstName" {...formik} />
                        </div>
                        <div className="field">
                            <label>
                                <b><I18n $="checkout.last_name" /></b>
                                <Field name="lastName" type="text" data-kiosk-auto-capitalize />
                            </label>
                            <FieldError fieldName="lastName" {...formik} />
                        </div>
                    </div>
                    <div className="field">
                        <label>
                            <b><I18n $="checkout.email" /></b>
                            <Field name="email" type="text" />
                        </label>
                        <FieldError fieldName="email" {...formik} />
                    </div>
                    
                </div>
            </Form>
        )
    }

    render() {
        return <Formik onSubmit={this.props.onSubmit}
            validate={this.validateForm}
            initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                ...this.props.values
            }}>{this.formRenderer}</Formik>
    }

    static propTypes = {
        formId: PropTypes.string.isRequired, // the HTML ID to assign the form
        formRef: PropTypes.func, // a reference to the Formik form will be passed to this function
        onSubmit: PropTypes.func.isRequired, // the function to call when the form is submitted
        values: PropTypes.object // any form values that are already known
    }
}

export default localize(connect(order.selector.default)(ConvergenceCheckoutForm));

/**
 * Slightly naive but 99.9% useful email validator
 * @param {string} email
 */
function emailIsValid(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

