/// Brazilian Portuguese Language Bundle
export default {
    $: {
        code: "pt-BR",
        name: "Portuguese (Brazil)"
    },
    button: {
        add_to_cart: 'Adicionar ao carrinho',
        added_to_cart: 'Adicionado ao carrinho',
        all_guests: 'Todos os Hóspedes',
        check_out: 'Verificação de saída',
        deselect_all_photos: 'Desmarcar todas as fotos',
        deselect_all_videos: 'Desmarcar todos os vídeos',
        next: 'Próximo',
        previous: 'Anterior',
        remove_from_cart: 'Remover do carrinho',
        select_all_photos: 'Selecionar todas as fotos',
        select_all_videos: 'Selecionar todos os vídeos',
        search_by_name: 'Procura por Nome',
        search_by_time: 'Pesquisa por Hora',
        start_over: 'Recomeçar',
        view_cart: 'View Cart'
    },
    calendar: {
        su: 'Do',
        mo: 'Se',
        tu: 'Te',
        we: 'Qu',
        th: 'Qu',
        fr: 'Se',
        sa: 'Sá',

        sunday: 'Domingo',
        monday: 'Segunda-feira',
        tuesday: 'Terça-feira',
        wednesday: 'Quarta-feira',
        thursday: 'Quinta-feira',
        friday: 'Sexta-feira',
        saturday: 'Sábado',
        
        january: 'Janeiro',
        february: 'Fevereiro',
        march: 'Março',
        april: 'Abril',
        may: 'Maio',
        june: 'Junho',
        july: 'Julho',
        august: 'Agosto',
        september: 'Setembro',
        october: 'Outubro',
        november: 'Novembro',
        december: 'Dezembro',

        previous_month: 'Anterior mês',
        next_month: 'Próximo mês'
    },
    checkout: {
        firstName: 'Primeiro nome',
        lastName: 'Último nome',
        email: 'Email',
        phone: 'Número de telefone',
        address1: 'Endereço',
        postalCode: 'Código postal',
        cardNumber: 'Número do cartão',
        expirationDate: 'Data de validade',
        cvv: 'CVV',

        card_expired: 'Este cartão expirou.',
        invalid_date: 'Por favor, insira uma data válida.',
        invalid_email: 'Por favor digite um email válido.',
        invalid_phone: 'Por favor insira um número de telefone válido.',
        value_required: 'Por favor, preencha este campo.'
    },
    error: {
        server: 'Sorry, but we could not complete your request at this time. Please try again later.'
    },
    kiosk: {
        title: 'Receba Suas Fotos E Videos'
    },
    media: {
        morning: 'Manhã',
        afternoon: 'Tarde',
        evening: 'Tarde',
        guests: 'Convidados',
        search_placeholder: 'Procurar',
        no_guests_yet: 'Ainda não existem convidados.',
        empty_search: 'Nenhum nome de convidado correspondente.',
        show_fullsize: 'Mostrar tamanho real',
        application_name: 'iFLY Centro de Gerenciamento de Mídia',
        selected: 'Selecionado',
        unassigned: 'Não atribuído',
        sample_video_disclaimer: 'Nota: os videoclipes de amostra são encurtados e de baixa resolução.',
        purchased_video_info: 'Os vídeos comprados têm a duração total do voo em qualidade HD.',
        cart_full: 'O carrinho está cheio.',
        cannot_add_more_items: 'Não podem ser adicionados mais itens.',
        video_preview: 'Visualização de vídeo',
        alert: {
            empty_cart: 'O carrinho de compras está vazio.<br>Adicione mídia e visualize o carrinho.',
            cart_limit: 'O carrinho de compras está cheio.<br>Não podem ser adicionados mais itens.'
        }
    },
    order: {
        checkout: 'Verificação de saída'
    },
    download: {
        heading: 'Visualize e baixe todas as suas fotos e vídeos do seu dia no iFLY abaixo!',
        www_link: 'Clique aqui para reservar outro voo.',
        no_media: 'Suas fotos e vídeos ainda estão sendo processados. Verifique novamente em breve!'
    },
    prompt: {
        select_location: 'Selecione sua localização.',
        select_session_date_and_time: 'Selecione uma data e hora da sessão vôo.',
        select_session_date_and_guest: 'Selecione uma data e um convidado da sessão de vôo.',
        select_photos_and_videos: 'Selecione fotos e vídeos.'
    },
    checkout_path: '/pt-br/confira/'
};