import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { i18n, localize } from 'locale/I18n'

class TunnelDropdown extends PureComponent {
    onChange = evt => this.props.onChange(evt.target.value)
    
    render() {
        return (
            <select className="tunnel-dropdown" value={this.props.value || ''} onChange={this.onChange}>
                <option value="" disabled>{ i18n('prompt.select_location') }</option>
                {
                    this.props.tunnels.map(t =>
                        <option key={t.code} value={t.code}>{ t.name }</option>
                    )
                }
            </select>
        )
    }
    
    static propTypes = {
        value: PropTypes.string,
        tunnels: PropTypes.arrayOf(PropTypes.shape({
            code: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired
        })),
    }
}

export default localize(TunnelDropdown);