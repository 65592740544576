import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { createModule } from '@ifly/redux-modules'
import sessionModule from './session'
import Cookies from 'js-cookie'
import MediaApi from 'api/MediaApi'

const COOKIE_OPTS = { expires: 30, domain: 'iflyworld.com', secure: true, samesite: 'strict' };
const MEDIA_CART_COOKIE = 'ifly_media_cart';
const VOUCHERS_COOKIE = 'vouchers';
const DEBUG_KIOSK_CART = false; // set true to persist kiosk cart into cookie

const Module = createModule({
    name: 'cart',

    initialState: {
        cartItems: [],
        isLoading: false
    },
    
    actions: {
        shoppingCartAdd: { dispatch:true, reducer:false, args:['cartItems', 'itemsAdded'] }, // reduced by saga
        shoppingCartRemove: { dispatch:true, reducer:false, args:['cartItems', 'itemsRemoved'] }, // reduced by saga

        shoppingCartClear: { dispatch:true, reducer:false }, // reduced by saga
        shoppingCartRestore: { reducer:false, dispatch:true }, // reduced by saga
        
        loadingCart: {},
        receiveCart: {},
        failureCart: {}
    }
});

export default Module;

export class CartSaga {

    static* watcher() {
        yield all([
            takeLatest(Module.constants.shoppingCartAdd, CartSaga.updateCart),
            takeLatest(Module.constants.shoppingCartClear, CartSaga.clearCart),
            takeLatest(Module.constants.shoppingCartRemove, CartSaga.updateCart),
            takeLatest(Module.constants.shoppingCartRestore, CartSaga.restoreCart)
        ]);
    }
    
    /**
     * Restores the shopping cart from cookies.
     */
    static* restoreCart() {
        let cartItems = [],
            session = yield select(sessionModule.selector.default),
            tunnel = session.tunnel,
            isLoading = true,
            errors = false;

        // The cart structure below groups selected media by the api media path from which
        // they were originally requested:
        // 
        //    {
        //        tunnel:'AUS',
        //        items:{
        //            '2020-01-08/Session/70041635': [71182190, 71182196, 71182141],
        //            '2020-01-04/Guest/1417952000005': [71183031, 71183033, 71183047]
        //        }
        //    }
        // 
        // Full cart items are restored by re-issuing the requests at each api media path.
        
        if ((DEBUG_KIOSK_CART || false===session.isKiosk) && tunnel?.code) {
            const mediaCart = Cookies.getJSON(MEDIA_CART_COOKIE);
            
            if (!mediaCart) {
                // no-op ... nothing to restore
                
            } else if (mediaCart.tunnel===tunnel.code) {
                const slugs = Object.keys(mediaCart.items);
                
                if (slugs.length) {
                    let ajaxRequests = [];
                    
                    // each slug requires a request to fetch its items 
                    
                    for (let apiMediaSlug of slugs) {
                        ajaxRequests.push(call(MediaApi.get, `/api/Media/${apiMediaSlug}`));
                    }

                    yield put(Module.actions.loadingCart({ isLoading }));
                    let ajaxResponses = yield all(ajaxRequests);
                    
                    // gather the selected items from each response 
                    
                    for (let [ index, response ] of ajaxResponses.entries()) {
                        if (response) {
                            let apiMediaSlug = slugs[index],
                                ids = mediaCart.items[apiMediaSlug],
                                data = response.data || [];
                            
                            data.forEach(item => {
                                if (ids.includes(item.id)) {
                                    item.apiMediaSlug = apiMediaSlug; // restore media slug
                                    cartItems.push(item);
                                }
                            });
                        } else {
                            errors = true;
                            break;
                        }
                    }
                }
                
            } else {
                console.info(`Cart tunnel [${mediaCart.tunnel}] different from session tunnel [${tunnel.code}].`);
            }
        }

        isLoading = false;

        if (errors) {
            yield put(Module.actions.failureCart({ cartItems, isLoading }));
        } else {
            yield put(Module.actions.receiveCart({ cartItems, isLoading }));
        }
    }

    /**
     * Clears all items from the shopping cart.
     */
    static * clearCart() {
        let response = yield call(MediaApi.post, '/api/Order/ClearOrder');

        Cookies.remove(MEDIA_CART_COOKIE, COOKIE_OPTS);
        Cookies.remove(VOUCHERS_COOKIE, COOKIE_OPTS);
        Cookies.remove("orderType", COOKIE_OPTS);
        Cookies.remove("orderEmail", COOKIE_OPTS);
        Cookies.remove("pendingPaymentLink", COOKIE_OPTS);

        console.log('clearOrder response', response);

        yield call(CartSaga.updateCart, { cartItems: [] });
    }
    
    /**
     * Updates the items in the shopping cart.
     */
    static* updateCart({ cartItems }) {
        let session = yield select(sessionModule.selector.default);

        if ((DEBUG_KIOSK_CART || false===session.isKiosk) && session.tunnel) {

            let items = {},
                tunnel = session.tunnel.code,
                opts = { domain:'iflyworld.com', secure:true, samesite:'strict' };
            
            // NOTE: Saving the JSON for the maximum number of cart items (currently 20) 
            //       into a cookie exceeds the 4KB cookie limit.
            // 
            // The condensed cart structure below groups selected media by the api media
            // path from which they were originally requested:
            // 
            //    {
            //        tunnel:'AUS',
            //        items:{
            //            '2020-01-08/Session/70041635': [71182190, 71182196, 71182141],
            //            '2020-01-04/Guest/1417952000005': [71183031, 71183033, 71183047]
            //        }
            //    }
            // 
            // This allows full cart items to be restored by re-issuing the requests at each
            // api media path. For 99% of customers there will be only one api media path,
            // but this does support gathering media across different sessions and/or guests.
            //

            for (let { id, apiMediaSlug:slug } of cartItems) {
                items[slug] ? items[slug].push(id) : items[slug] = [id]; 
            }
            
            Cookies.set(MEDIA_CART_COOKIE, { tunnel, items }, opts);
        }

        yield put(Module.actions.receiveCart({ cartItems }));
    }
}