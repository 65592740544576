/// English Language Bundle
export default {
    $: {
        code: 'en',
        name: 'English'
    },
    button: {
        add_to_cart: 'Add to Cart',
        added_to_cart: 'Added to Cart',
        all_guests: 'All Guests',
        apply: 'Apply',
        apply_more_vouchers: 'Apply More Vouchers',
        apply_voucher: 'Apply Voucher',
        cancel: 'Cancel',
        check_out: 'Check Out',
        deselect_all_photos: 'Deselect All Photos',
        deselect_all_videos: 'Deselect All Videos',
        next: 'Next',
        no: 'No',
        ok: 'Ok',
        place_order: 'Place Order',
        previous: 'Previous',
        remove_from_cart: 'Remove from Cart',
        select_all_photos: 'Select All Photos',
        select_all_videos: 'Select All Videos',
        search_by_name: 'Search by Name',
        search_by_time: 'Search by Time',
        start_over: 'Start Over',
        view_cart: 'View Cart',
        yes: 'Yes'
    },
    calendar: {
        su: 'Su',
        mo: 'Mo',
        tu: 'Tu',
        we: 'We',
        th: 'Th',
        fr: 'Fr',
        sa: 'Sa',

        sunday: 'Sunday',
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',

        january: 'January',
        february: 'February',
        march: 'March',
        april: 'April',
        may: 'May',
        june: 'June',
        july: 'July',
        august: 'August',
        september: 'September',
        october: 'October',
        november: 'November',
        december: 'December',

        previous_month: 'Previous Month',
        next_month: 'Next Month'
    },
    checkout: {
        first_name: 'First Name',
        last_name: 'Last Name',
        email: 'Email',
        phone: 'Phone Number',
        address1: 'Street Address',
        postal_code: 'Postal Code',
        card_number: 'Card Number',
        country: 'Country',
        city: 'City',
        state: 'State',
        expiration_date: 'Expiration Date',
        cvv: 'CVV',
        
        error: {
            first_name: 'Please enter your first name.',
            last_name: 'Please enter your last name.',
            email: 'Please enter a valid email.',
            phone: 'Please enter a valid phone number.',
            address1: 'Please enter a street address.',
            city: 'Please enter a city.',
            state: 'Please enter a state.',
            postal_code: 'Please enter a postal code.',

            card_number: 'Please enter a valid card number.',
            expiration_month: 'Expiration month is required.',
            expiration_year: 'Expiration year is required.',
            cvv: 'CVV required',

            card_expired: 'This card has expired.',
            invalid_date: 'Pleaase enter a valid date.'
        }
        
    },
    error: {
        server: 'Sorry, but we could not complete your request at this time. Try refreshing your page or Start Over.'
    },
    kiosk: {
        scan_voucher: 'Scan Voucher',
        title: 'Get Your Photos & Videos',
        prompt: {
            choose_an_action: 'Choose an Option Below',
            are_you_there: 'Are you still there?',
            session_about_to_expire: 'Your session is about to expire.',
            tap_screen_to_continue: 'Tap the screen to continue.'
        },
        action: {
            qrcode: 'Scan QR Code To Complete on Mobile',
            wristband: 'Scan Your Wristband or Voucher',
            voucher: 'Touch Here to Enter Your Voucher Code',
            search: 'Touch Here to Search by Name or Time'
        }
    },
    media: {
        morning: 'Morning',
        afternoon: 'Afternoon',
        evening: 'Evening',
        guests: 'Guests',
        search_placeholder: 'Search',
        no_guests_yet: 'No guests yet.',
        no_sessions_yet: 'No sessions yet.',
        still_processing_media: 'Photos and videos are processing.',
        wait_until_media_ready: 'Please wait a few moments before your photos and videos are ready.',
        empty_search: 'No matching guest names.',
        show_fullsize: 'Show Fullsize',
        checking_wristband: 'Checking Wristband',
        session_found: 'Session Found',
        application_name: 'iFLY Media Center',
        selected: 'Selected',
        sessions: 'Sessions',
        unassigned: 'Unassigned',
        sample_video_disclaimer: 'Note: Sample video clips are shortened and low resolution.',
        purchased_video_info: 'Purchased videos are the full flight length in HD quality.',
        cart_full: 'The cart is full.',
        cannot_add_more_items: 'No more items may be added.',
        video_preview: 'Video Preview',
        redeeming_voucher: 'Redeeming Voucher',
        duplicate_voucher: 'That voucher is already applied.',
        multiple_flyers: 'Multiple Flyers',
        photos: 'Photos',
        videos: 'Videos',
        voucher_code: 'Voucher Code',
        alert: {
            empty_cart: 'The shopping cart is empty.<br>Add media then view the cart.',
            cart_limit: 'The shopping cart is now full.<br>No more items may be added.',
            clear_cart_confirmation: 'Do you want to remove all items from your cart?',
            wristband_lookup_failed: 'Unable to look you up from your wristband.',
            try_manual_session_lookup: 'Try finding your session manually.'
        },
        share: {
            default_quote: 'Check out my flight on iFly!',
            hashtag: 'ifly'
        }
    },
    order: {
        currency: '$%{amount}',
        order_summary: 'Order Summary',
        shopping_cart: 'Shopping Cart',
        order_total: 'Order total',

        complete_your_order: 'Complete Your Order',

        one_photo: '1 Photo',
        n_photos: '%{qty} Photos',
        photos_each: '%{qty} Photos ($%{amount} each)',

        one_video: '1 Video',
        n_videos: '%{qty} Videos',
        videos_each: '%{qty} Videos ($%{amount} each)',

        subtotal: 'Subtotal',
        discount: 'Discount',
        applied_voucher: 'Applied voucher',
        applied_vouchers: 'Applied vouchers',
        tax: 'Tax',
        
        empty_cart: 'The shopping cart is empty.',
        browse_media: 'Browse media',
        to_begin_shopping: 'to begin shopping.',

        pending: {
            heading: 'Your order is almost complete!',
            qr_code_alternative: 'You can also scan the QR Code below to go directly to that link',
            oops_heading: 'Oops! Something went wrong.',
            oops_message: 'There was an error while checking out. Please try submitting your order again.'
        },
        
        complete: {
            thank_you: 'Thank you!',
            media_ready_soon: 'Your media will be in your inbox soon.',
        }
    },
    download: {
        heading: 'Preview and download all your photos and videos from your day at iFLY below!',
        www_link: 'Click here to book another flight.',
        no_media: 'Your photos and videos are still being processed. Please check again soon!'
    },
    prompt: {
        scan_or_enter_a_voucher_code: 'Scan or enter your voucher code.',
        select_location: 'Please select your location.',
        select_session_date_and_time: 'Select a flight session date and time.',
        select_session_date_and_guest: 'Select a flight session date and guest.',
        select_photos_and_videos: 'Select photos and videos.',
        no_options: 'No options.'
    },
    checkout_path: '/checkout/'
};