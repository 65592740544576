import React from 'react';

/**
 * Checks if the errors has contains an error for that field, and if so, emit an error message.
 * If `touched` property exists, it will also check to see if the field was touched.
 */
export default ({
    dirty, // indicates whether the form has been modified
    submitCount, // indicates how many times the form has been submitted
    errors = {},
    fieldName,
    fieldNames = [],
    touched
}) => {
    let errorMessage;

    // don't show anything if the form hasn't been touched or submitted
    if (dirty !== false || submitCount > 0) {
        // normalize fieldNames
        fieldNames = [fieldName, ...fieldNames];

        let myErrors = {};

        // filter the list of errors for the ones that concern my field(s)
        for (let key of Object.keys(errors)) {
            if (fieldNames.includes(key)) {
                myErrors[key] = errors[key];
            }
        }

        // check if a `touched` object was sent that contains all the touched fields,
        // if so check if any given field is both touched and has an error
        let myTouched = {};
        if (touched && typeof touched === 'object') {
            for (let key of Object.keys(touched)) {
                if (fieldNames.includes(key) && errors[key]) {
                    myTouched[key] = touched[key];
                    break;
                }
            }
        }

        if (
            (touched === undefined || touched === true || Object.keys(myTouched).length) &&
            Object.keys(myErrors).length
        ) {
            errorMessage = Object.entries(myErrors)[0][1];
        }
    }

    return <div className="field-error">{errorMessage}</div>;
};
