import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

export default class GuestButton extends PureComponent {
    onClick = evt => {
        let { id, onClick } = this.props;
        if (id!==undefined && onClick) onClick(id);
        evt.preventDefault();
    }
    
    render() {
        let sel = this.props.selected;
        let cls = `guest btn-primary${ sel ? ' selected' : ''}`;
        
        return (
            <a href="." onClick={this.onClick} className={cls}>
                <span className="btn-label">{ this.props.children }</span>
            </a>
        )
    }
}

GuestButton.propTypes = {
    id: PropTypes.number,
    onClick: PropTypes.func,
    selected: PropTypes.bool.isRequired,
}