import React from 'react'
import PropTypes from 'prop-types'

const WebSection = React.memo(function WebSection(props) {
    let { className } = props;
    let sectionProps = className ? { className } : {};

    return (
        <section { ...sectionProps }>
            <div className="max-ct">{ props.children }</div>
        </section>
    );
});

WebSection.propTypes = {
    className: PropTypes.string
}

export default WebSection;