import { shoppingCart } from "cart";
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types'
import MediaPropTypes from 'util/MediaPropTypes'
import { mediaSettings } from 'util/MediaContext'
import I18n, { localize } from 'locale/I18n'
import Svg from 'components/common/Svg';

const SECONDS_BEFORE_REDIRECT = 15;

class OrderDone extends PureComponent {
    
    componentDidMount() {
        let { clearCart } = this.props;

        // since the purchase was successful, we should clear the cart now.
        clearCart();

        setTimeout(() => this.props.navigate('../start'), SECONDS_BEFORE_REDIRECT * 1000);
    }
    
    render() {
        let { sessionTunnel, locale } = this.props,
            { mediaConfig } = sessionTunnel || {},
            { thankYouMessage } = mediaConfig || {};
        
        return sessionTunnel &&
            <div className="order-done">
                <div className="heading"><I18n $="order.complete.thank_you" /></div>
                <div className="message"><I18n $="order.complete.media_ready_soon" /></div>
                <div className="brand"><Svg id="ifly-guy"/></div>
                {
                    thankYouMessage &&
                    <div className={`tunnel-message ${locale}`}
                         dangerouslySetInnerHTML={{__html: thankYouMessage}}/>
                }
            </div>
    }

    static propTypes = {
        // mediaSettings
        sessionTunnel: MediaPropTypes.sessionTunnel,
        
        // localize
        locale: PropTypes.string.isRequired 
    }
}

export default localize(mediaSettings(shoppingCart(OrderDone)));