import React, {Component} from 'react'
import PropTypes from 'prop-types'
import PopupMenu from 'components/common/PopupMenu'
import LanguagesPopup from 'components/header/popup/LanguagesPopup'
import LanguagesToggle from 'components/header/popup/LanguagesToggle'
import LocationsPopup from 'components/header/popup/LocationsPopup'
import LocationsToggle from 'components/header/popup/LocationsToggle'

export default class HeaderTopBar extends Component {

    /**
     * Force the telephone link to navigate normally rather than triggering
     * the parent HeaderPopup menu.
     * @param evt The browser event.
     */
    onClickTel = evt => evt.stopPropagation()

    render() {
        let { selectedTunnel, nearbyTunnels } = this.props,
            [tunnel] = nearbyTunnels || [];

        const locations = <LocationsToggle tunnel={tunnel} selectedTunnel={selectedTunnel} />

        return (
            <div className="top-bar">
                <ul className="_on L LD XL" role="menubar" aria-label="locale">
                    <li className="location">
                        <PopupMenu toggle={locations}>
                            <LocationsPopup tunnels={this.props.tunnels}
                                            nearbyTunnels={nearbyTunnels}
                                            onChangeTunnel={this.props.onChangeTunnel}
                            />
                        </PopupMenu>
                    </li>
                    <li className="locale" role="menuitem" aria-haspopup={true}>
                        <PopupMenu toggle={<LanguagesToggle/>}>
                            <LanguagesPopup />
                        </PopupMenu>
                    </li>
                </ul>
            </div>
        );
    }

    static propTypes = {
        tunnels: PropTypes.array,
        nearbyTunnels: PropTypes.array,
        selectedTunnel: PropTypes.string,
        onChangeTunnel: PropTypes.func.isRequired
    }
}