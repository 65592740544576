import { applyMiddleware, createStore as _createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import thunk from 'redux-thunk'
import reducer from './reducers'
import saga from './sagas'

/**
 * Create the redux store, along with all necessary middleware
 */
export default function createStore() {
    const sagaMiddleware = createSagaMiddleware();
    const middleware = [thunk, sagaMiddleware];

    const finalCreateStore = applyMiddleware(...middleware)(_createStore);
    const store = finalCreateStore(
        reducer, 
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__({trace: true})
    );
    
    sagaMiddleware.run(saga);

    return store;
}
