import React, { Component } from 'react'
import PropTypes from 'prop-types'

/**
 * This class renders an anchor tag using the server `root` property prepended
 * to the given `path` property. An optional `label` property may be supplied
 * in lieu of using `props.children` as the anchor label.
 *
 * This class is not indended to be used directly. Specialized components must 
 * wrap this component and supply the sub-domain specific root property.
 *
 * Example Specialized Component
 *
 *     export default React.memo(function WpLink(props) {
 *         return <ExternalLink root={Environment.wordPressServer()} {...props} />
 *     });
 *
 */
export default class ExternalLink extends Component {
    
    onClick = evt => {
        evt.preventDefault();
        this.props.onClick(evt);
    }
    
    render() {
        let {root, path, label, children, onClick, ...anchorProps} = this.props;

        if(!path) path = '';
        
        if (onClick) {
            anchorProps.href = '#';
            anchorProps.onClick = this.onClick;
        } else {
            // typical external url
            anchorProps.href = path.match(/^http/) ? path : root + path;
        }

        return <a {...anchorProps}>{ label || children }</a>
    }
    
    static propTypes = {
        root: PropTypes.string.isRequired,
        onClick: PropTypes.func,
        path: PropTypes.string.isRequired,
        label: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node
        ])
        // all other attributes are passed down to the anchor
    }
}