import { all } from 'redux-saga/effects'
import { CartSaga } from './modules/cart'
import { GroupMediaSaga } from './modules/groupmedia'
import { MediaLookupSaga } from './modules/medialookup'
import { GuestMediaSaga } from './modules/guestmedia'
import { GuestSearchSaga } from './modules/guestsearch'
import { OrderSaga } from './modules/order'
import { SaleSaga } from './modules/sale'
import { ConvergenceSaleSaga } from './modules/convergencesale'
import { TimeSearchSaga } from './modules/timesearch'
import { TunnelsSaga } from './modules/tunnels'
import { ScannerSaga } from './modules/scanner'
import { SessionSaga } from './modules/session'

/// We use Redux-Saga to assist in maintaining app state.
/// https://redux-saga.js.org/
///
/// The root saga consists of all sagas watched in all our various modules.
export default function* saga() {
    yield all([
        CartSaga.watcher(),
        GroupMediaSaga.watcher(),
        MediaLookupSaga.watcher(),
        GuestMediaSaga.watcher(),
        GuestSearchSaga.watcher(),
        OrderSaga.watcher(),
        SaleSaga.watcher(),
        ConvergenceSaleSaga.watcher(),
        TimeSearchSaga.watcher(),
        TunnelsSaga.watcher(),
        ScannerSaga.watcher(),
        SessionSaga.watcher(),
    ]);
}