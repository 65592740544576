import React from 'react'

/**
 * Localized loading component displays an 80x80 animation.
 */
const HTML =
    <div className="lds-roller"><div/><div/><div/><div/><div/><div/><div/><div/></div>

const LoadingRoller = React.memo(function LoadingRoller({ className }) {
    let cls = className ? `lds-ct ${className}` : 'lds-ct';
    return <div className={cls}>{ HTML }</div>
});

export default LoadingRoller;