const ADDTHIS_PUBID = "ra-5e8cbbb210d1c134";
const script = document.createElement('script');
script.type = 'text/javascript';
script.async = true;
script.onload = function(){};
script.src = `//s7.addthis.com/js/300/addthis_widget.js#pubid=${ADDTHIS_PUBID}`;

export default class AddThis{
    static init(){
        if(window['addthis']) return;
        document.getElementById('root').appendChild(script);
    }
}