import { combineReducers } from 'redux'
import cart from './modules/cart'
import groupmedia from './modules/groupmedia'
import medialookup from './modules/medialookup'
import guestmedia from './modules/guestmedia'
import guestsearch from './modules/guestsearch'
import order from './modules/order'
import sale from './modules/sale'
import timesearch from './modules/timesearch'
import tunnels from './modules/tunnels'
import scanner from './modules/scanner'
import session from './modules/session'
import convergencesale from './modules/convergencesale'

export default combineReducers({
    [cart.name]: cart.reducer,
    [groupmedia.name]: groupmedia.reducer,
    [medialookup.name]: medialookup.reducer,
    [guestmedia.name]: guestmedia.reducer,
    [guestsearch.name]: guestsearch.reducer,
    [order.name]: order.reducer,
    [sale.name]: sale.reducer,
    [timesearch.name]: timesearch.reducer,
    [tunnels.name]: tunnels.reducer,
    [scanner.name]: scanner.reducer,
    [session.name]: session.reducer,
    [convergencesale.name]: convergencesale.reducer
});