import Cookies from 'js-cookie'
import WordpressApi from 'api/WordpressApi'

// cookie options
const COOKIE_OPTS = { expires:30, domain:'iflyworld.com', secure:true, samesite:'strict' };

// cookie holding the name of the selected tunnel
const TUNNEL_COOKIE = 'tunnel';

// cookie holding the name of the selected tunnel
const GEOLOCATION_COOKIE = 'geolocation';

// source used to determine geolocation (tunnel means an explicit selection)
export const GEOSOURCE_ERROR = 'error';
export const GEOSOURCE_TUNNEL = 'tunnel';
export const GEOSOURCE_IPINFO = 'ipinfo';
export const GEOSOURCE_DEVICE = 'device';

/**
 * The haversine formula determines the great-circle distance between two
 * points on a sphere given their longitudes and latitudes.
 *
 * @param lat1 Point 1 latitude.
 * @param lon1 Point 1 longitude.
 * @param lat2 Point 2 latitude.
 * @param lon2 Point 2 longitude.
 * @returns {number} The distance between the two points.
 */
function getDistance(lat1, lon1, lat2, lon2) {
    // https://rosettacode.org/wiki/Haversine_formula
    let p = 0.017453292519943295,
        c = Math.cos,
        a = 0.5 - c((lat2-lat1)*p) / 2 + c(lat1*p) * c(lat2*p) * (1 - c((lon2-lon1)*p)) / 2;
    return 12742 * Math.asin(Math.sqrt(a));
}

/**
 * Static class contains a collection of useful tunnel utility methods.
 */
export default class TunnelUtil {
    /**
     * Given a list of tunnels and geolocation of the visitor returns the list
     * ordered by each tunnel's distance from the visitor.
     *
     * @param {Object} geolocation The lat/lon of the visitor.
     * @param {Object[]} tunnels The list of active tunnels.
     * @returns {Object[]} The tunnels ordered by distance from the visitor.
     */
    static getClosestTunnels = (geolocation, tunnels) => {
        if (!geolocation || !tunnels) return null;

        let closest = [...tunnels],
            code, lat, lon;
        
        if (typeof geolocation === 'string' || geolocation.code) {
            // passed tunnel, use lat/lon of tunnel
            code = geolocation.code || geolocation;
            let tunnel = closest.find(t => 
                code===(t.tunnel||t.code)); // support old and new model name for tunnel code
            if (tunnel) {
                lat = tunnel.latitude;
                lon = tunnel.longitude;
            }
            
        } else if (geolocation.coords) {
            // use lat/lon of geolocation coords
            lat = geolocation.coords.lat; 
            lon = geolocation.coords.lon;
        }    
        
        if (lat && lon) {
            closest.sort((t1, t2) => getDistance(lat, lon, t1.latitude, t1.longitude) -
                getDistance(lat, lon, t2.latitude, t2.longitude)
            );
        }    
        
        return closest;
    }
    
    /**
     * Sorts the tunnels into USA/International lists and then groups them by
     * region (state for USA or country for International).
     *
     * Returns:
     *
     *  {
     *      usa: {
     *          regions: ['Arizona', 'California', ..., 'Washington'],
     *          tunnels: [{...}, ..., {...}]
     *      },
     *      international: {
     *          regions: ['Alberta', 'Australia', ..., 'United Kingdom'],
     *          tunnels: [{...}, ..., {...}]
     *      }
     *  }
     *
     * @param {Object[]} tunnels Flat array of tunnels.
     * @return {Object} Tunnels grouped by usa/intl and then by state/country.
     */
    static groupTunnelsByRegion = tunnels => {
        let usaTunnels = {}, usaRegions = [],
            intlTunnels = {}, intlRegions = [];

        tunnels.sort((o1, o2) => {
            // order by state
            if (o1.state < o2.state) return -1;
            if (o1.state > o2.state) return 1;

            let o1DisplayName = (o1.display_name || o1.displayName); // support snake and camel case
            let o2DisplayName = (o2.display_name || o2.displayName); // support snake and camel case
            
            // order by display name
            if (o1DisplayName < o2DisplayName) return -1;
            if (o1DisplayName > o2DisplayName) return 1;

            return 0;
        });

        // add each tunnel to its state group in the respective usa or international list
        tunnels.forEach(tunnel => {
            let list = ('US' === tunnel.country ? usaTunnels : intlTunnels),
                regions = ('US' === tunnel.country ? usaRegions : intlRegions),
                group = list[tunnel.state];

            if (!group) {
                regions.push(tunnel.state);
                group = list[tunnel.state] = [];
            }

            group.push(tunnel);
        });

        return {
            usa: {tunnels: usaTunnels, regions: usaRegions},
            international: {tunnels: intlTunnels, regions: intlRegions}
        };
    }
    
    static saveGeolocation = geolocation => Cookies.set(GEOLOCATION_COOKIE, geolocation, COOKIE_OPTS)
    
    static saveSelectedTunnel = tunnel => Cookies.set(TUNNEL_COOKIE, tunnel, COOKIE_OPTS)
    
    static geolocateVisitor = callback => {
        // skip geolocation if the user has explicitly chosen a tunnel
        let code = Cookies.get(TUNNEL_COOKIE);

        if (code) {
            return callback({ source:GEOSOURCE_TUNNEL, code });
        }
        
        // skip geolocation if coords have been previously saved
        let geolocation = Cookies.get(GEOLOCATION_COOKIE);
        geolocation = geolocation ? JSON.parse(geolocation) : null;

        if (geolocation?.source && geolocation?.coords) {
            return callback({ ...geolocation });
        }
        
        // less accurate method (by ip address)
        WordpressApi.geolocateByIp()
            .then(response => {
                if (!response.bogon && response.loc) {
                    let [ lat, lon ] = response.loc.split(/,/);
                    return callback({ source:GEOSOURCE_IPINFO, coords:{ lat, lon } });
                }
            })
            .catch(error => {
                return callback({ source:GEOSOURCE_ERROR, error });
            });
        
        // most accurate (by device location)
        
        if (!navigator || !navigator.geolocation) {
            return callback({ source:GEOSOURCE_ERROR, error: 'window.navigator is null' });
            // browser doesn't support geolocation
        }
        
        let agent = navigator.userAgent || navigator.vendor || window.opera;
        if (!agent || agent.match(/FBAN|FBAV|Instagram/)) {
            return callback({ source:GEOSOURCE_ERROR, error: 'Request from non-standard user agent.' });
            // browser is non-standard
        }
        
        // setTimeout is for safari (?)
        setTimeout(() => {
            navigator.geolocation.getCurrentPosition(
                // success
                pos => callback({
                    source: GEOSOURCE_DEVICE,
                    coords: {
                        lat: pos.coords.latitude,
                        lon: pos.coords.longitude
                    }
                }),
                // failure
                error => callback({ source:GEOSOURCE_ERROR, error })
            )
        });
    }
}