import { PureComponent } from 'react'
import PropTypes from 'prop-types'

/// This non-rendering component listens for data from an attached barcode
/// scanner to match product discount voucher codes and customer wristband
/// codes emitted by the scanner as a fast series of keystrokes terminated
/// by a return (\n).
///
/// Wristband codes follow the prefix `%AXX`. For example, a scanned value
/// of `%AXX.8VLBBW` reports a wristband code of `.8VLBBW`. 
/// 
/// Voucher codes either begin with `561` followed by the rest of the code
/// (16 digits total) or they follow the prefix `%AAXX`. A scanned value
/// of `5610123456781011` reports a voucher code of the same value while a
/// scanned value of `%AAXX1OVONF` reports a voucher code of `1OVONF`.
/// 

// start scan char codes
const PERCENT = 37;
const FIVE = 53;

// end scan char code
const RETURN = 13;

// regexes to match and capture the scan code
const WRISTBAND = /%AXX(.+)/;
const VOUCHER1 = /%AAXX(.+)/;
const VOUCHER2 = /(561\d{13})/;

const initialState = { scan: '' };

export default class BarcodeScanner extends PureComponent {
    state = initialState

    finishScan = () => {
        let { scan } = this.state;
        if (!scan) return;
        
        if (WRISTBAND.test(scan)) {
            this.props.onScanWristband(RegExp.$1);
            // matched wristband
            
        } else if (VOUCHER1.test(scan) || VOUCHER2.test(scan)) {
            this.props.onScanVoucher(RegExp.$1);
            // matched voucher
        }

        this.setState({ ...initialState });
    }
    
    onKeypress = evt => {
        let { scan } = this.state,
            code = evt.keyCode;

        if (scan) {
            if (code===RETURN) {
                this.finishScan(); 
                // end scan
            } else {
                scan += String.fromCharCode(code);
                this.setState({ scan }); 
                // continue scan
            }
            
        } else if (code===PERCENT || code===FIVE) {
            scan = String.fromCharCode(code);
            this.setState({ scan }); 
            // begin scan
            
            // Force the end of the scan after 1 second. Sometimes the scanner
            // will beep but nothing seems to happen.  After additional scans
            // all scan codes appear run together (end return chars not sent?)
            setTimeout(() => this.finishScan(), 1000);
        }
        
        if (scan) {
            // swallow scan chars
            evt.preventDefault();
        }
    }

    componentDidMount() {
        window.addEventListener('keypress', this.onKeypress);
    }
    
    componentWillUnmount() {
        window.removeEventListener('keypress', this.onKeypress);
    }
    
    render() { return null; }
    
    static propTypes = {
        onScanVoucher: PropTypes.func.isRequired,
        onScanWristband: PropTypes.func.isRequired
    }
}