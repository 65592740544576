import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { i18n, localize } from 'locale/I18n'
import DayPicker from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import CalendarNavbar from './CalendarNavbar'

// we keep about three months of media
const ARCHIVED_DAYS = 91;

// Default English first day of the week is 0, Sunday
const FIRST_DAY_OF_WEEK = {
    'pt-BR': 1
}

class Calendar extends PureComponent {
    onDayClick = (day, {selected}) => {
        this.props.onChangeDay(day)
    }

    render() {
        let { today, locale, selectedDay } = this.props;
        
        const oldest = new Date();
        oldest.setDate(today.getDate() - ARCHIVED_DAYS);
        
        return (
            <DayPicker
                showOutsideDays
                
                onDayClick={this.onDayClick}
                navbarElement={<CalendarNavbar />}
                
                initialMonth={selectedDay}
                selectedDays={selectedDay}
                fromMonth={oldest}
                toMonth={today}
                disabledDays={{ after:today }}
                
                locale={locale}
                firstDayOfWeek={FIRST_DAY_OF_WEEK[locale]}
                
                labels={{
                    nextMonth: i18n('calendar.next_month'),
                    previousMonth: i18n('calendar.previous_month')
                }}

                weekdaysShort={[
                    i18n('calendar.su'),
                    i18n('calendar.mo'),
                    i18n('calendar.tu'),
                    i18n('calendar.we'),
                    i18n('calendar.th'),
                    i18n('calendar.fr'),
                    i18n('calendar.sa')
                ]}

                weekdaysLong={[
                    i18n('calendar.sunday'),
                    i18n('calendar.monday'),
                    i18n('calendar.tuesday'),
                    i18n('calendar.wednesday'),
                    i18n('calendar.thursday'),
                    i18n('calendar.friday'),
                    i18n('calendar.saturday')
                ]}

                months={[
                    i18n('calendar.january'),
                    i18n('calendar.february'),
                    i18n('calendar.march'),
                    i18n('calendar.april'),
                    i18n('calendar.may'),
                    i18n('calendar.june'),
                    i18n('calendar.july'),
                    i18n('calendar.august'),
                    i18n('calendar.september'),
                    i18n('calendar.october'),
                    i18n('calendar.november'),
                    i18n('calendar.december')
                ]}

            />
        )
    }
    
    static propTypes = {
        today: PropTypes.instanceOf(Date).isRequired,
        selectedDay: PropTypes.instanceOf(Date).isRequired,
        onChangeDay: PropTypes.func.isRequired
    }
}

export default localize(Calendar)
