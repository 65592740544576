import { Component } from 'react'
import PropTypes from 'prop-types'

/// Must be a child of a < Router > component.

export default class MediaRedirect extends Component {
    shouldComponentUpdate(prevProps) {
        return this.props.uri !== prevProps.uri;
    }

    render() {
        let { fn, to, uri, note, navigate } = this.props;
        let url = to || fn(uri);
        
        if (uri!==url) {
            console.log(`MediaRedirect ${note} ... from=${uri} to=${url}`);
            setTimeout(() => navigate(url, { replace:true }), 0);
        }
        
        return null;
    }
    
    static propTypes = {
        fn: PropTypes.func,
        to: PropTypes.string,
        note: PropTypes.string
    }
}