import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { createModule } from '@ifly/redux-modules'
import { navigate } from '@reach/router'
import MediaApi from 'api/MediaApi'
import Recaptcha from 'api/Recaptcha'
import Cookies from 'js-cookie'
import orderModule from './order'
import cartModule from './cart'
import Path from 'util/PathUtil'

const COOKIE_OPTS = { expires: 30, domain: 'iflyworld.com', secure: true, samesite: 'strict' };
const VOUCHERS_COOKIE = 'vouchers';
const MEDIA_CART_COOKIE = 'ifly_media_cart';

const Module = createModule({
    name: 'convergencesale',

    initialState: {
        sale: null,
        voucher: null,
        vouchers: null,
        voucherCode: null,
        error: false,
        transactionError: null,
        isLoading: false,
        isLoadingVoucher: false,
        isDuplicateVoucher: false
    },

    actions: {
        resetSale: { reducer: false, dispatch: true },   // reduced by saga
        processSale: { reducer: false, dispatch: true }, // reduced by saga
        applyVoucher: { reducer: false, dispatch: true }, //reduced by saga
        resetVoucher: { reducer: false, dispatch: true },
        clearAllVouchers: {reducer: false, dispatch: true },

        clearCart: { reducer: false, dispatch: true },

        loadingConvergencesale: {},
        loadingVoucher: {},

        receiveConvergencesale: {},
        receiveVoucher: {},

        failureConvergencesale: {},
        failureVoucher: {}
    },

    selector: {
        sale: state => state.sale.sale,
        vouchers: state => state.sale.vouchers
    }
});

export default Module;

export class ConvergenceSaleSaga {
    static *watcher() {
        yield all([
            takeLatest(Module.constants.clearCart, ConvergenceSaleSaga.clearCart),
            takeLatest(Module.constants.resetSale, ConvergenceSaleSaga.initSale),
            takeLatest(Module.constants.processSale, ConvergenceSaleSaga.processSale),
            takeLatest(Module.constants.applyVoucher, ConvergenceSaleSaga.redeemVoucher),
            takeLatest(Module.constants.resetVoucher, ConvergenceSaleSaga.clearVoucher),
            takeLatest(Module.constants.clearAllVouchers, ConvergenceSaleSaga.clearAllVouchers)
        ]);
    }

    /**
     * Resets the state for a new sale.
     * @param {Object} action The action being dispatched
     */
    static *initSale(action) {
        yield put(Module.actions.loadingConvergencesale({ ...Module.initialState }));
    }

    static * clearCart(action) {
        yield put(cartModule.actions.shoppingCartClear());
    }

    /**
     * Processes a convergence sale.
     * @param {Object} action The action being dispatched
     */
    static *processSale(action) {
        let { type, isKiosk, ...payload } = action,
            isLoading = true,
            sale = null,
            error = false,           // standard server error
            transactionError = null; // transaction-related error

        console.log('action', action);

        yield put(Module.actions.loadingConvergencesale({ sale, error, transactionError, isLoading }));
        let response = yield call(MediaApi.post, '/api/Order/Sale', payload);
        isLoading = false;

        if (response) {
            sale = response.data || {};

            if (sale.status === 'SERVER_ERROR') {
                error = true;
                yield put(Module.actions.failureConvergencesale({ error, isLoading }));
            } else {
                if (sale.status === 'ERR') {
                    transactionError = sale.message;
                    yield put(Module.actions.failureConvergencesale({ sale, transactionError, isLoading }));
                } else {
                    let pendingPaymentLink = sale.data.pendingPaymentLink;
                    let isPendingOrder = false;

                    if (pendingPaymentLink) isPendingOrder = true;
                    
                    Cookies.set(MEDIA_CART_COOKIE, {}, COOKIE_OPTS);
                    Cookies.set(VOUCHERS_COOKIE, '', COOKIE_OPTS);
                    yield put(cartModule.actions.shoppingCartClear());

                    // Set the order type and pending payment link so it is available on the ConvergenceOrderDone page
                    let orderType = (isPendingOrder) ? 'creditcard' : 'voucher';
                    Cookies.set("orderType", orderType, COOKIE_OPTS);
                    console.log('Setting orderType', orderType);
                    if (isPendingOrder) {
                        Cookies.set("orderEmail", payload.email, COOKIE_OPTS);
                        Cookies.set("pendingPaymentLink", pendingPaymentLink, COOKIE_OPTS);
                    }

                    // If this is a voucher sale, then send the user to the thank you page
                    // Otherwise this is a credit card sale, so send the user directly to the pendingPaymentLink via redirect
                    // if we're on kiosk AND this is a voucher sale => go to done page (no qr code - we're just done)
                    // if we're on kiosk AND this is a cc sale => go to done page with QR code
                    // if we're on web AND this is a voucher sale => go to done page (no qr code - we're just done)
                    // if we're on web AND this is a cc sale => redirect to convergence cart/checkout
                    if (isKiosk || !isPendingOrder) {
                        navigate(`${Path.cleanConvergenceOrderDoneUrl()}/${sale.data.lookupCode}`);
                    } else {
                        window.location = sale.data.pendingPaymentLink;
                    }
                }
            }
        } else {
            // server error
            error = true;
            yield put(Module.actions.failureConvergencesale({ error, isLoading }));
        }
    }


    static * clearAllVouchers() {
        let isLoading = true;
        let error = false,
            transactionError = null;

        console.log('clearing all vouchers start.');
        let sale = yield select(Module.selector.default);

        yield put(Module.actions.loadingConvergencesale({ sale, error, transactionError, isLoading }));
        let response;

        response = yield call(MediaApi.post, '/api/Order/ClearVouchers');
        isLoading = false;
        
        if (response && response.data.status !== 'ERR') {
            yield put(Module.actions.receiveVoucher({ transactionError, isLoading }));
            
            Cookies.set(VOUCHERS_COOKIE, '', COOKIE_OPTS);
        } else {
            yield put(Module.actions.failureVoucher({ isLoading }));
        }

        yield put(orderModule.actions.updateOrder({ isLoading }));
    }

    /**
     * Processes the scanned voucher.
     * @param {Object} action The action being dispatched
     */
    static * redeemVoucher(action) {
        let { voucherCode } = action;
        let isLoading = true;
        let error = false,           // standard server error
            transactionError = null; // transaction-related error

        let sale = yield select(Module.selector.default),
            vouchers = sale.vouchers ? [...sale.vouchers] : [];

        yield put(Module.actions.loadingConvergencesale({ sale, error, transactionError, isLoading }));

        let recaptcha = yield call(Recaptcha.getToken);
        let data = { voucherCode, recaptcha };
        let response;

        response = yield call(MediaApi.post, '/api/Order/Voucher', data);
        isLoading = false;

        if (!response) {
            voucherCode = null;
            yield put(Module.actions.failureVoucher({ voucherCode, isLoading }));
            return;
        }
        
        if (response.data.qty === 0) {
            if (response.data.message) {
                transactionError = response.data.message;
            } else {
                transactionError = "The code '" + voucherCode + "' is not valid.";
            }
            
            yield put(Module.actions.failureVoucher({ voucherCode, transactionError, isLoading }));
            return;
        }

        if (response) {
            const voucher = response.data;

            if (voucher?.success) {
                // save the voucher information
                let { mediaType, qty } = voucher;

                if (qty > 0) {
                    vouchers.push({ voucherCode, mediaType, qty });

                    let currentVouchers = Cookies.get(VOUCHERS_COOKIE);
                    let voucherList = currentVouchers ? currentVouchers.split(/,/) : [];

                    if (!voucherList.includes(voucherCode)) {
                        voucherList.push(voucherCode);
                    }

                    let voucherCookie = voucherList.join();

                    yield put(Module.actions.receiveVoucher({ voucherCode, transactionError, isLoading }));

                    Cookies.set(VOUCHERS_COOKIE, voucherCookie, COOKIE_OPTS);
                } else {
                    if (response.data.message) {
                        transactionError = response.data.message;
                    } else {
                        transactionError = "The code '" + voucherCode + "' is not valid.";
                    }
                    yield put(Module.actions.failureVoucher({ voucherCode, transactionError, isLoading }));
                }

            }

            yield put(orderModule.actions.updateOrder({ isLoading }));
        }

    }

    static * clearVoucher(action) {
        yield put(Module.actions.receiveVoucher({
            voucher: null,
            voucherCode: null,
            isLoadingVoucher: false,
            isDuplicateVoucher: false
        }));
    }

}