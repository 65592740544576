import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import I18n from 'locale/I18n'
import TunnelContext from 'util/TunnelContext'
import Path from 'util/PathUtil'

import Loading from 'components/misc/Loading'
import TunnelDropdown from './TunnelDropdown'
import WebSection from './WebSection'

export default class LocationView extends PureComponent {
    static contextType = TunnelContext
    
    render() {
        let { isLoading, tunnels, onChangeLocation, location, navigate } = this.props,
            path = location.pathname;
        
        if (path!=='/' && !Path.CHOOSE_TUNNEL_URL.test(path)) {
            // redirect to a clean url if necessary
            setTimeout(() => navigate('/', { replace:true }), 0);
        }
        
        if (isLoading) return <Loading />
        
        return (
            <WebSection className="location-view">
                <div className="view-head">
                    <I18n $="media.application_name" />
                </div>
                {
                    tunnels && <TunnelDropdown tunnels={tunnels} onChange={onChangeLocation} />
                }
            </WebSection>
        )
    }

    static propTypes = {
        tunnels: PropTypes.array,
        isLoading: PropTypes.bool.isRequired
    };
}