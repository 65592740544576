/**
 * English language bundle module.
 *
 * @module module:locale/languages/en
 * @see module:locale/languages/bundle
 * 
 */

/** The language bundle. */
export default {
    $: {
        code: 'en',
        name: 'English'
    },
    
    auth: {
        greeting: 'Hello, %{name}'
    },
    
    button: {
        account: 'Account',
        all_locations: 'All Locations',
        back: 'Back',
        book_a_flight: 'Book a Flight',
        cart: 'Cart',
        close: 'Close',
        login: 'Login',
        logout: 'Logout',
        menu: 'Menu',
        my_profile: 'My Profile',
        nearby: 'Nearby',
        privacy_policy: 'Privacy Policy',
        search: 'Search',
        shopping_cart: 'Shopping Cart',
        selected: 'Selected',
        sign_up: 'Sign Up',
        terms_of_use: 'Terms of Use',
        toggle: 'Toggle',
        toggle_close: 'Toggle Close',
        toggle_open: 'Toggle Open',
        upcoming_flights: 'Upcoming Flights'
    },

    common: {
        copyright: '© 2019 iFLY Holdings, LLC. All Rights Reserved'
    },
    
    country: {
        usa: 'United States',
        international: 'International' 
    },
    
    footer: {
        safety_standards: 'Maintaining the highest safety standards, training and competitions in accordance with the',
        site_map: 'Site Map'
    },
    
    header: {
        authenticating: 'Authenticating',
        greeting: 'Welcome back!',
        hi: 'Hi,',
        language: 'Language',
        location: 'Location',
        near_you: 'Near You',
        others_nearby: 'Others Nearby',
        select_language: 'Select Language'
    },

    languages: {
        english: 'English',
        portuguese: 'Portuguese'
    },

    loginform: {
        title: 'Your Account for Everything iFLY',
        forgot_password: 'Forgot password?',
        log_in: 'Log In',
        join_now: 'Not a member? Join now',
        agreement: {
            preamble: "By logging in you agree to iFLY's",
            and: 'and'
        },
        error: {
            credential: 'Your email or password was entered incorrectly.',
            unknown: 'An unknown authentication error has occurred.'
        }
    },
    
    placeholder: {
        email: 'Email',
        password: 'Password'
    }
};