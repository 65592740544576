import { all, call, put, takeLatest } from 'redux-saga/effects'
import { createModule } from '@ifly/redux-modules'
import MediaApi from 'api/MediaApi'

const Module = createModule({
    name: 'guestmedia',

    initialState: {
        media: null,
        isLoading: false
    },

    actions: {
        requestGuestSession: { args:['guestId'], reducer:false, dispatch:true }, // reduced by saga
        loadingGuestSession: { },
        receiveGuestSession: { },
        failureGuestSession: { }
    }
});

export default Module;

export class GuestMediaSaga {
    static* watcher() {
        yield all([
            takeLatest(Module.constants.requestGuestSession, GuestMediaSaga.fetchGuestSession)
        ]);
    }

    /**
     * Fetch guest media session from the local API for the given session id.
     * @param {Object} action The action being dispatched
     */
    static* fetchGuestSession(action) {
        let isLoading = true;
        yield put(Module.actions.loadingGuestSession({ isLoading }));

        let { guestId } = action,
            media = null,
            apiMediaSlug = `Guest/${guestId}`, // used to restore shopping cart (see cart.js)
            response = yield call(MediaApi.get, `/api/Media/${apiMediaSlug}`);

        isLoading = false;

        if (response) {
            media = response.data;
            if (Array.isArray(media)) {
                media.forEach(item => item.apiMediaSlug = apiMediaSlug);
            }
            yield put(Module.actions.receiveGuestSession({ media, isLoading }));
        } else {
            // server error
            yield put(Module.actions.failureGuestSession({ media, isLoading }));
        }
    }
}