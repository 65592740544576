/// Path/Route Utilities 

const CART = 'cart';
const CHECKOUT = 'checkout';
const CONVERGENCE_CHECKOUT = 'convergence/checkout';
const CONVERGENCE_VOUCHER = 'convergence/voucher';
const CONVERGENCE_DONE = 'convergence/done';
const CHOOSE_TUNNEL = 'choose-tunnel';
const DATE = 'date';
const DONE = 'done';
const DOWNLOAD = 'download';
const FLYER = 'flyer';
const GUEST = 'guest';
const KIOSK = 'kiosk';
const LEGACY = 'legacy';
const REDIRECT = '/api/Redirect/Legacy/';
const SEARCH = 'search';
const SESSION = 'session';
const START = 'start';
const TUNNEL = 'tunnel';
const VOUCHER = 'voucher';

const CART_URL = new RegExp(`(/${CART})([/#?].*)?`);
const CHECKOUT_URL = new RegExp(`(/${CHECKOUT})([/#?].*)?`);
const CONVERGENCE_CHECKOUT_URL = new RegExp(`(/${CONVERGENCE_CHECKOUT})([/#?].*)?`);
const CONVERGENCE_VOUCHER_URL = new RegExp(`(/${KIOSK})/[A-Z]{2,}/${CONVERGENCE_VOUCHER}`);
const CONVERGENCE_DONE_URL = new RegExp(`(/${CONVERGENCE_DONE})([/#?].*)?`);
const CHOOSE_TUNNEL_URL = new RegExp(`(/${CHOOSE_TUNNEL})([/#?].*)?`);
const DATE_URL = new RegExp(`(/${DATE}/[0-9]{4}-[0-9]{2}-[0-9]{2})(/.*)?`);
const DOWNLOAD_URL = new RegExp(`(https?:)?//[^/]+/(.+)`);
const FLYER_URL = new RegExp(`(/${FLYER})(/.*)?`);
const KIOSK_START_URL = new RegExp(`(/${KIOSK})/[A-Z]{2,}/${START}`);
const KIOSK_VOUCHER_URL = new RegExp(`(/${KIOSK})/[A-Z]{2,}/${VOUCHER}`);
const GUEST_URL = new RegExp(`(/${GUEST}/[0-9]+)(/.*)?`);
const SESSION_URL = new RegExp(`(/${SESSION}/[0-9]+)(/.*)?`);
const TUNNEL_URL = new RegExp(`(/${TUNNEL}/[A-Z]{2,})(/.*)?`);
const TUNNEL_OR_KIOSK_URL = new RegExp(`(/(${TUNNEL}|${KIOSK})/[A-Z]{2,})(/.*)?`);

function parseDate(slug) {
    let [ year, month, day ] = slug.split(/-/);
    return new Date(year, month-1, day);
}

function fmtDate(date) {
    let year = date.getFullYear(),
        month = `${ 1 + date.getMonth() }`.padStart(2, '0'),
        day = `${ date.getDate() }`.padStart(2, '0');

    return `${year}-${month}-${day}`;
}

function datePath(date=new Date()) {
    return `${DATE}/${fmtDate(date)}`;
}

function getDownloadUrl(mediaUrl) {
    return mediaUrl.replace(DOWNLOAD_URL, `/${DOWNLOAD}/$2`);
}

function cleanCartUrl(url=window.location.pathname) {
    return url.replace(TUNNEL_OR_KIOSK_URL, `$1/${CART}`)
}

function cleanCheckoutUrl(url=window.location.pathname) {
    return url.replace(TUNNEL_OR_KIOSK_URL, `$1/${CHECKOUT}`)
}

function cleanConvergenceCheckoutUrl(url = window.location.pathname) {
    return url.replace(TUNNEL_OR_KIOSK_URL, `$1/${CONVERGENCE_CHECKOUT}`);
}

function cleanConvergenceVoucherUrl(url = window.location.pathname) {
    return url.replace(TUNNEL_OR_KIOSK_URL, `$1/${CONVERGENCE_VOUCHER}`);
}

function cleanConvergenceOrderDoneUrl(url = window.location.pathname) {
    return url.replace(TUNNEL_OR_KIOSK_URL, `$1/${CONVERGENCE_DONE}`);
}

function cleanDateUrl(url=window.location.pathname) {
    return url.replace(DATE_URL, '$1');
}

function cleanTunnelUrl(url=window.location.pathname) {
    return url.replace(TUNNEL_URL, '$1');
}

function cleanGuestUrl(url = window.location.pathname) {
    return url.replace(GUEST_URL, '$1');
}

function cleanSessionUrl(url=window.location.pathname) {
    return url.replace(SESSION_URL, '$1');
}

function replaceDateUrl(url, date) {
    return url.replace(DATE_URL, `/${ datePath(date) }$2`);
}

export default {
    /// path literals
    CART,
    CHECKOUT,
    CONVERGENCE_CHECKOUT,
    CONVERGENCE_VOUCHER,
    CONVERGENCE_DONE,
    CHOOSE_TUNNEL,
    DATE,
    DONE,
    FLYER,
    GUEST,
    KIOSK,
    LEGACY,
    REDIRECT,
    SEARCH,
    SESSION,
    START,
    TUNNEL,

    /// path regexes
    CART_URL,
    CHECKOUT_URL,
    CONVERGENCE_CHECKOUT_URL,
    CONVERGENCE_VOUCHER_URL,
    CONVERGENCE_DONE_URL,
    CHOOSE_TUNNEL_URL,
    DATE_URL,
    FLYER_URL,
    KIOSK_START_URL,
    KIOSK_VOUCHER_URL,
    GUEST_URL,
    SESSION_URL,
    TUNNEL_URL,
    VOUCHER,

    /// functions
    fmtDate,
    parseDate,
    datePath,
    cleanCartUrl,
    cleanCheckoutUrl,
    cleanConvergenceCheckoutUrl,
    cleanConvergenceVoucherUrl,
    cleanConvergenceOrderDoneUrl,
    cleanDateUrl,
    cleanGuestUrl,
    cleanSessionUrl,
    cleanTunnelUrl,
    getDownloadUrl,
    replaceDateUrl
}